import { api } from '../../api'

export const getSurveyData = (surveyId: string, attendeeId: string, reviewerId: string) =>
  api.get(`spec/survey/${surveyId}/${attendeeId}/${reviewerId}`)

export const submitSurvey = (surveyId: string, attendeeId: string, reviewerId: string, answers: any[]) =>
  api.put(`spec/survey`, {
    survey: surveyId,
    attendee: attendeeId,
    reviewer: reviewerId,
    answers
  })