import React, { useEffect, useState } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles'

import { BespokeSurvey } from './components/survey'
import { OverviewProgrammes } from './components/ProgrammesListPage/overview'
import { CompletedProgrammes } from './components/ProgrammesListPage/completed'
import SettingsPage from './components/SettingsPage'
import { ProgrammeDetailsPage } from './components/ProgrammeDetailsPage'
import { PageNotFound } from './PageNotFound'
import { UserContext } from './components/user/context'
import { check } from './api/user'
import { Login } from './components/user/Login'
import { Reset } from './components/user/Reset'
import { opensideTheme } from './theme'
import { useStyles } from './theme/styles'
import { User } from './types'

const styles : React.CSSProperties = {
  backgroundColor: "#FFFFFF",
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
};

const App: React.FC = () => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const check_auth = () => {
    check()
      .then(current_user => {
        setUser(user => user === undefined || user.id !== current_user.id ? current_user : user)//complicated way of not replacing the user object when it doesnt need to be
        setTimeout(check_auth, 60000)
      })
      .catch(() => {
        setUser(undefined)
      })
  }
  useEffect(check_auth, [user])

  const classes = useStyles()

  const PrivateRoute = ({ component, ...routeProps }: any) => {
    return (<Route
      {...routeProps}
      render={renderProps =>
        user === undefined ? (
          <Redirect to={{ pathname: "/login", state: { referer: renderProps.location } }} />
        ) : (
            <Route component={component} {...routeProps} />
          )
      }
    />
    )
  }

  return (
    <ThemeProvider theme={opensideTheme}>
      <BrowserRouter>
        <UserContext.Provider value={{ user, setUser }}>
          <div className={classes.window}>
            <div
              className={`harper_wrapper_screen`}
              style={{
                display: "grid"
              }}
            >
              <div
                style={{
                  gridColumn: "1 / span 1",
                  gridRow: "1 / span 1"
                }}
              >
      <div style={styles}>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/reset/:one_time_token" component={Reset} />
                  <Route path="/survey/:survey_id/:attendee_id/:reviewer_id" component={BespokeSurvey} />
                  <PrivateRoute path="/programmes/overview" component={OverviewProgrammes} />
                  <PrivateRoute path="/programmes/completed" component={CompletedProgrammes} />
                  <PrivateRoute path="/settings" component={SettingsPage} />
                  <PrivateRoute path="/settings/:tab" component={SettingsPage} />
                  <PrivateRoute path="/programme/:programme_id" component={ProgrammeDetailsPage} />
                  <Redirect from='/' to='/programmes/overview' />
                  <Route component={PageNotFound} />
                </Switch>
              </div>
              </div>
            </div>
          </div>
        </UserContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
