import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal';
import { saveTutor, deleteTutorById, createTutor as createNewTutor } from '../../../../../api/tutor';
import { getAllProgrammes } from '../../../../../api/programme';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Icon from '../../../../Common/Icon';

import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';


import { Tutor } from '../../../../../types';


const customStyles = {
  content: {
    width: '30%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const useStyles = makeStyles(() => ({

  input: {
    width: '100%'
  },
  form_button: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
    marginRight: 10,
    textAlign: 'center',
    width: 100,
    cursor: 'pointer'
  },
  delete_button: {
    background: 'red',
  },
}));

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  onDataChange: () => void;
  tutor: Tutor;
}

const EditTutor = ({ isModalOpen, closeModal, tutor, onDataChange }: Props) => {
  const history = useHistory();
  const isValid = (tutor: any) => {
    if (tutor === undefined || tutor.name === undefined || tutor.name == "") {
      return false;
    }

    return true;
  }
  const [updatedTutor, setUpdatedTutor] = useState<Tutor>(tutor)

  useEffect(() => {
    setUpdatedTutor(tutor);

  }, [tutor])
  const classes = useStyles();
  function afterOpenModal() {
    //noop
  }

  const save = () => {
    if (!isValid(updatedTutor)) {
      return;
    }

    if (tutor.id && tutor.id !== "") {
      return saveTutor(updatedTutor)
        .then(() => onDataChange())
        .then(() => {
          return closeModal();
        })
    } else {
      return createNewTutor(updatedTutor)
        .then(() => onDataChange())
        .then(() => {
          return closeModal();
        })
    }
  }

  const deleteTutor = () => {
    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this tutor?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true
    }).then((choice: any) => {
      if (choice.isDismissed) {
        //noop
      } else if (choice.isConfirmed) {
        getAllProgrammes()
          .then(programmes => {
            if (programmes.filter((p: any) => p.tutors.filter((t: string) => t === tutor.id).length > 0).length > 0) {
              Swal.fire({
                title: 'Validation Notice',
                text: "Cannot delete tutor that's associated with a programme",
                icon: 'info',
                confirmButtonText: 'OK',
                showCancelButton: false
              })
              return;
            } else {
              deleteTutorById(tutor.id)
                .then(() => onDataChange())
                .then(() => {
                  return closeModal();
                })
            }
          })

      }
    })

  }


  return (

    <Modal
      isOpen={isModalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Manage Tutor"
    >

      <h2>Tutor</h2>
      <Grid container
        direction="column"
        justify="center"
      >
        <Grid item xs={9}>
          <TextField
            label="name"
            value={updatedTutor.name}
            className={classes.input}
            onChange={(e) => {
              e.persist();
              if (updatedTutor) {
                setUpdatedTutor({ ...updatedTutor, name: e.target.value })
              }
            }
            }
          />
        </Grid>


      </Grid>
      <Grid container
        direction="row"
      >

        <div className={`${classes.form_button}`} onClick={save}>
          Save {isValid(updatedTutor) ? <Icon icon="edit" /> : <Icon icon="exclamation" />}
        </div>
        {updatedTutor.id && (
          <div className={`${classes.form_button} ${classes.delete_button}`} onClick={deleteTutor}>
            Delete <Icon icon="trash-alt" />
          </div>
        )}

        <div className={classes.form_button} onClick={() => {
          closeModal();
        }}>
          Cancel
            </div>
      </Grid>

    </Modal>

  )
};

export default EditTutor;
