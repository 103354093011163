import React from 'react';

import { useStyles } from '../../../theme/styles'
import Icon from '../../Common/Icon';

interface ActionButtonProps {
  icon: string;
  text: string;
  onClick?: () =>  void;
}

const ActionButton = ({ icon, text, onClick }: ActionButtonProps) => {
  const classes = useStyles();

  return (
    <div className={classes.actionButton} onClick={onClick}>
      <span className={classes.icon}>
        <Icon icon={icon} />
      </span>
      <div>
        {text}
      </div>
    </div>
  );
};

export default ActionButton;
