import React, { useState, FC, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { list_email_schema } from '../../../api/email_schema'

import { useStyles } from '../../../theme/styles';

interface Props {
    close: () => void
    disabled: boolean,
    open: boolean,
    withSchema: (schema: any) => void
}

export const SelectEmailTemplate: FC<Props> = (props: Props) => {
    const { close } = props
    const [emailSchemas, setEmailSchemas] = useState<any[]>([])


    const disabled = props.disabled
    const classes = useStyles()

    useEffect(() => {
        list_email_schema().then(res => {
            setEmailSchemas(res)
        })
    }, [])


    const cancel = () => {
        close()
    };

    return (
        <Dialog
            aria-labelledby="email-template-modal"
            open={props.open}
            fullWidth={false}
            maxWidth={'lg'}>
            <DialogTitle id="email-template-modal">{'New email - select template'}</DialogTitle>
            <DialogContent>
                <DialogContentText>Please select teplate for new email content. You can edit it on the next screen.</DialogContentText>

                <Grid item md={6} xs={12}>
                    <TextField
                        label='Select schema template'
                        select
                        value={''}
                        className={classes.input}
                        disabled={disabled}
                        onChange={() => { }}
                    >
                        <MenuItem key="None" value="none" onClick={() => props.withSchema(undefined)}>
                            None
                            </MenuItem>
                        {emailSchemas.map(schema => (
                            <MenuItem key={schema._id} value={schema._id} onClick={() => props.withSchema(schema)}>
                                {schema.email_alias}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}
