import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSurveyData, submitSurvey } from './api'
import SurveySubmitted from './SurveySubmitted'
import SurveyPreviouslySubmitted from './SurveyPreviouslySubmitted'
import { SurveyBriefing } from './SurveyBriefing'
import SurveyLoading from './SurveyLoading'
import SurveyError from './SurveyError'
import Survey from './Survey'

interface Props {
  screen_coordinate: string
  location: {
    pathname: string
  }
}

export const BespokeSurvey: FC<Props> = (props: Props) => {
  const { survey_id, attendee_id, reviewer_id } = useParams()

  const [state_loading, set_state_loading] = useState<boolean>(true)
  const [state_submitted, set_state_submitted] = useState<boolean>(false)
  const [state_skip_briefing, set_state_skip_briefing] = useState<boolean>(false)
  const [state_front_pages, set_state_front_pages] = useState<{
        front_page_attendee:string,
        front_page_reviewer:string,
  }|undefined>()
  const [state_questions, set_state_questions] = useState<any[]>([])
  const [state_attendee, set_state_attendee] = useState<string>('')
  const [state_error, set_state_error] = useState<string>('')

  const get_survey = () => {
    set_state_loading(true)
    getSurveyData(survey_id, attendee_id, reviewer_id)
      .then((response: any) => {
        set_state_front_pages(response.data.front_pages)
        set_state_questions(response.data.questions)
        set_state_attendee(response.data.attendeeName)
        set_state_loading(false)
      })
      .catch((err: any) => {
        const message = err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message
        set_state_error(message)
        set_state_loading(false)
      })
  }
  useEffect(get_survey, [survey_id, attendee_id, reviewer_id])

  const handleSubmit = () => {
    submitSurvey(
      survey_id,
      attendee_id,
      reviewer_id,
      state_questions
        .filter(qa => qa.value !== undefined)
        .map(qa => ({
          question: qa.question.id,
          value: qa.value,
          text: qa.answerText,
          timeXopportunity: qa.timeXopportunity,
          evidence: qa.evidence
        }))
    )
      .then(() => {
        set_state_submitted(true)
      })
      .catch((err: any) => {
        set_state_error(err.message)
      })
  }

  const handleResponse = (questionId: string) => (value: any) => {
    set_state_questions(state_questions.map((q: any) => {
      return q.question.id === questionId
        ? {
          ...q,
          ...value
        }
        : q
    }))
  }

  const handleValueChange = (questionId: string) => (value: number) => {
    return handleResponse(questionId)({
      value: value,
      timeXopportunity: undefined
    })
  }

  const handleAnswerTimeXOpportunity = (questionId: string) => (event: any, selectedItem: any) => {
    return handleResponse(questionId)({
      timeXopportunity: selectedItem
    })
  }

  const handleAnswerTextChange = (questionId: string) => (event: any) => {
    return handleResponse(questionId)({
      answerText: event.target.value
    })
  }

  const handleAddEvidence = (questionId: string) => (evidenceData: any) => {
    set_state_questions(state_questions.map((q: any) => {
      return q.question.id === questionId
        ? {
          ...q,
          evidence: q.evidence === undefined ? [evidenceData] : [...q.evidence, evidenceData]
        }
        : q
    }))
  }

  const handleRemoveEvidence = (questionId: string) => (evidenceData: any) => {
    set_state_questions(state_questions.map((q: any) => {
      return q.question.id === questionId
        ? {
          ...q,
          evidence: q.evidence.filter((e: any) => e != evidenceData)
        }
        : q
    }))
  }

  if (state_error === 'You have already responded to this survey') {
    return (<SurveyPreviouslySubmitted />)
  }
  if (state_error !== '') {
    return (<SurveyError errorMessage={state_error} />)
  }
  if (state_submitted) {
    return (<SurveySubmitted />)
  }
  if (state_loading) {
    return (<SurveyLoading />)
  }
  if (!state_skip_briefing && state_front_pages !== undefined) {
    return (<SurveyBriefing
      selfReviewing={state_attendee === 'YOURSELF'}
      dismiss={() => set_state_skip_briefing(true)}
      front_pages={state_front_pages}
    />)
  }
  return (<Survey
    survey={survey_id}
    attendee={attendee_id}
    reviewer={reviewer_id}
    selfReviewing={state_attendee === 'YOURSELF'}
    attendeeName={state_attendee}
    questions={state_questions}
    handleValueChange={handleValueChange}
    handleAnswerTimeXOpportunity={handleAnswerTimeXOpportunity}
    handleAnswerTextChange={handleAnswerTextChange}
    handleAddEvidence={handleAddEvidence}
    handleRemoveEvidence={handleRemoveEvidence}
    errorMessage={state_error}
    handleSubmit={handleSubmit}
  />)
}