import { api } from '.';
import { Survey } from '../types';
import { query_params } from '../helpers/api';

export const getSurveyListByProgramme = (programmeId: string) =>
  api.get(`/survey/${query_params({programme:programmeId})}`)
  .then((response : any) => response.data.data.sort((e1:Survey,e2:Survey) => {
    if(e1.date_sent && e2.date_sent && e1.date_sent!==e2.date_sent){
      return e1.date_sent > e2.date_sent ? 1 : -1    
    }
    if(e1.programme_date_offset_days !== undefined && e1.programme_date_offset_days !== null && 
      e2.programme_date_offset_days !== undefined && e2.programme_date_offset_days !== null && 
      e1.programme_date_offset_days !== e2.programme_date_offset_days){
      return e1.programme_date_offset_days > e2.programme_date_offset_days ? 1 : -1    
    }
    return e1.id > e2.id ? 1 : -1 
  }))

export const getSurveyById = (surveyId: string) =>
  api.get(`/survey/${surveyId}`).then(response => response.data)

export const createNew = (survey: Survey) =>
  api.post(`/survey`, survey).then(response => response.data);

export const update = (survey: Survey) =>
  api.put(`/survey`, survey).then(response => response.data);

export const deleteSurveyById = (surveyId: string) =>
  api.delete(`/survey/${surveyId}`).then(response => response.data)
