import React, { useEffect, useState } from 'react';
import { Tag } from '../../../../types';
import { listTags } from '../../../../api/tag';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Title from '../../../Common/Title';
import ActionButton from 'src/components/Common/ActionButton';
import EditTagModal from './EditTagModal';
import theme from '../../../../theme';
import { remove } from 'lodash'

const useStyles = makeStyles(() => ({
  grid: {
    cursor: 'default'
  },
  grid_item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEE',
    },
  },
  tag: {
    color: theme.colours.gray,
    fontSize: 12
  },
  editButton: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    textAlign: 'center',
    width: 140,
    cursor: 'pointer'
  },
  actionButtonContainer: {
    float: 'right',
    maxWidth: 250,
    display: 'flex'
  }
}));

const Tags = () => {
	const classes = useStyles();

	const [tags, setTags] = useState<Tag[]>([])
	const [editingTag, setEditingTag] = useState<Tag|undefined>()

	useEffect(() => {
		void (async () => {
			const response = await listTags()
			if (response) {
				setTags(response.data)
			}
		})()
	},[])

	const onTagDeleted = (tag: Tag) => {
		setTags(remove(tags, ({ id }) => id !== tag.id))
	}

	const onTagUpdated = (tag: Tag) => {
		const prevTag = tags.find(({ id }) => tag.id === id)
		
		if (prevTag) {
			tags.splice(tags.indexOf(prevTag), 1, tag)
		} else {
			setTags((prevVal) => [...prevVal, tag])
		}			

	}

	return (
		<>
			<Grid item xs={12}>
        	<Title>Tags</Title>
      		</Grid>
			
			<Grid item xs={4}>
				<div className={classes.actionButtonContainer}>
					<ActionButton icon="plus" text="Create new tag" onClick={() => setEditingTag({ id: "", name: "" })} />
					<EditTagModal
						isOpen={!!editingTag}
						closeModal={() => setEditingTag(undefined)}
						tag={editingTag ?? { id: "", name: ""}}
						onDataChange={onTagUpdated}
						onDelete={onTagDeleted}
					/>
				</div>
			</Grid>

			{tags.map((tag) => (
				<Grid container className={classes.grid} key={tag.id}>
					<Grid item xs={3} className={classes.grid_item} container direction="column" onClick={() => setEditingTag(tag)}>
						{tag.name ?? '-'}
						<div className={classes.tag}>Tag Name</div>
					</Grid>
				</Grid>
			))}

		</>
	)
}

export default Tags