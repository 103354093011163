import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getShowProgramme } from 'src/api/programme'
import { Attendee, Programme, Survey } from 'src/types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LowestRatedCompentencies from './LowestRatedCompetencies'
import LeastImprovedCompetencies from './LeastImprovedCompetencies'
import { getSurveyListByProgramme } from 'src/api/survey'
import SurveySelector from './SurveySelector/SurveySelector'
import AverageAssessmentByCompetencyChart from './AverageAssessmentByCompetencyChart'
import SurveyCompetencyScoreAvgRangeTable from './SurveyCompetencyScoreAvgRangeTable'
import AveragePerformanceImprovement from './AveragePerformanceImprovement'
import NoTimeOrOpportunityChart from './NoTimeOrOpportunityChart'
import { Checkbox } from '@material-ui/core'
import { getSurveyCompetencyScoreAvgRange, getSurveyCompetencyScoreAvgRangeForAttendee, getSurveyCompetencyScoreAvgRangeIncludeClient } from 'src/api/dashboard'
import ReactToPrint from 'react-to-print';
import { findAttendeesByProgrammeId } from 'src/api/attendee'
import moment from 'moment'

const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
		height: '100%',
		justifyContent: 'space-between'
	},
	column: {
		padding: '0.5em',
		height: '100%'
	}
}))
export type CompetenceData = {
    minLevel: number,
	countLevel: number,
    maxLevel: number,
    avgLevel: number,
    statement: string,
    surveyName: string,
}
export type DashboardData = {
	attendees: CompetenceData[]
	reviewers: CompetenceData[],
	all: CompetenceData[],
	selectedAttendeeData: CompetenceData[],
}


export const calculatePercentageDifference = (firstSurvey: CompetenceData, lastSurvey: CompetenceData) => {
        const difference = lastSurvey.avgLevel - firstSurvey.avgLevel
        const percentageDiff = (difference / firstSurvey.avgLevel) * 100
        return Math.round(percentageDiff * 100) / 100
    }

const Dashboard = () => {
	const printRef = useRef(null);
	const { programme_id } = useParams()
	const classes = useStyles()
	
 	const [loading, setLoading] = useState<boolean>(true)
	const [programme, setProgramme] = useState<Programme>()
	const [clientDashboard, setClientDashboard] = useState<boolean>(false)
	
	const [surveys, setSurveys] = useState<Survey[]>([])
	const [fullyCompletedOnly, setFullyCompletedOnly] = useState(true)
	const [data, setData] = useState<DashboardData>()
	const [referenceSurvey, setReferenceSurvey] = useState<Survey>()
	const [comparisonSurvey, setComparisonSurvey] = useState<Survey>()
	const [attendees, setAttendees] = useState<Attendee[]>([])
    const [selectedAttendee, setSelectedAttendee] = useState<Attendee>()

	useEffect(() => {
		void (async () => {
			const foundProgramme = await getShowProgramme(programme_id)
			setProgramme(foundProgramme)
			const foundSurveys = await getSurveyListByProgramme(programme_id)
			setSurveys(foundSurveys)
			setComparisonSurvey(foundSurveys.length>0 ? foundSurveys[0] : undefined)
			setReferenceSurvey(foundSurveys.length>1 ? foundSurveys[1] : undefined)
			findAttendeesByProgrammeId(programme_id).then(setAttendees)
		})()
	},[programme_id])
	const formatData = (data:any):CompetenceData[] => {
		const formatted =  data.flatMap((survey: any) => (
                survey.statements.flatMap((d: any) => ({
                    ...d,
					statement:d.statement.name,
                    surveyName: survey.survey.name,
                }))
            ))
		console.log('formatted', formatted)
		return formatted
	}
	useEffect(() => {
		if(!programme) return;
        void (async () => {
			setLoading(true)
			const clientId = (clientDashboard && clientDashboard===true) ? programme?.client : undefined
			console.log('Client Dashboard', clientId, clientDashboard, programme)
			const programmesStartedAfter = moment(programme.start).add('-1', 'year').toISOString()
            const attendeeDataCurrentProgrammeOnly = await getSurveyCompetencyScoreAvgRangeIncludeClient({ programmeId:programme_id, table: 'attendee', fullyCompletedOnly })
			const surveysToInclude = attendeeDataCurrentProgrammeOnly?.map((s:any)=>s.survey.name)
			console.log('survey data:', surveysToInclude)
			const attendeeData = await getSurveyCompetencyScoreAvgRangeIncludeClient({ programmeId:programme_id, table: 'attendee', fullyCompletedOnly, clientId,programmesStartedAfter })
			const reviewerData = await getSurveyCompetencyScoreAvgRangeIncludeClient({ programmeId:programme_id, table: 'reviewer', fullyCompletedOnly, clientId,programmesStartedAfter })
			const allData = await getSurveyCompetencyScoreAvgRangeIncludeClient({ programmeId:programme_id, table: 'all', fullyCompletedOnly, clientId,programmesStartedAfter})
            const selectedAttendeeData = selectedAttendee ? await getSurveyCompetencyScoreAvgRangeForAttendee({ programmeId:programme_id, table: 'all', fullyCompletedOnly:false, attendeeId: selectedAttendee.id}) : []
            
            setData({
				attendees: formatData(attendeeData?.filter((s:any)=>surveysToInclude.includes(s.survey.name))),
				reviewers: formatData(reviewerData?.filter((s:any)=>surveysToInclude.includes(s.survey.name))),
				all: formatData(allData?.filter((s:any)=>surveysToInclude.includes(s.survey.name))),
				selectedAttendeeData: formatData(selectedAttendeeData)
			})
			setLoading(false)
        })()
    },[programme_id, programme, fullyCompletedOnly, selectedAttendee, clientDashboard])   
	if (!programme) {
		return (
			<p>Loading...</p>
		)
	}
	return (
	<>	
	<div>
	<ReactToPrint
        trigger={() => <button>Print (Save as PDF)</button>}
        content={() => printRef.current}
		pageStyle={".divider { break-after: always; }"}
      />
</div>
		<Grid container className={classes.container}  style={{maxWidth:1200}} ref={printRef}>
			<style>
				{`@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@media print {
					@page {
						size: 300mm 550mm;
					}
				}`}
  			</style>
			<Grid container xs={12} item> 
				<Grid container xs={6} direction="column" item>
						<h1>{programme.title}</h1>
						<div onClick={(e)=>{setFullyCompletedOnly(!fullyCompletedOnly)}}>
							<Checkbox
								checked={fullyCompletedOnly}
								color={'primary'}
								disabled={!programme}
								
							/>
							<span>Only include fully completed surveys</span>
						</div>
						
						<div onClick={(e)=>{setClientDashboard(!clientDashboard)}}>
							<Checkbox
								checked={clientDashboard}
								color={'primary'}
								disabled={!programme}
								
							/>
							<span>Show average assessment for all cohorts on this programme</span>
						</div>
						<div>
						<select
							value={selectedAttendee?.id}
							onChange={(e) => setSelectedAttendee(attendees.find(a=> a.id === e.currentTarget.value))}
							size={1}
						>
						<option
								key={undefined}
								value={undefined}>
								{"Select Attendee"}
						</option>
						{attendees.map(s => (
							<option
								key={s.id}
								value={s.id}>
								{s.name}
							</option>
						))}
						</select>
						</div>
				</Grid>
				<Grid container xs={6} direction="row" item>
						<Grid container xs={6} direction="column" item> 
							<p>Survey to compare against:</p>
							<SurveySelector
								surveys={surveys.filter((s) => !referenceSurvey || s.programme_date_offset_days < referenceSurvey.programme_date_offset_days )}
								surveySelectedCallback={(id) => setComparisonSurvey(surveys.filter((s)=> s.id===id)[0])}
								selectedSurvey={comparisonSurvey}
							/>
						</Grid>
						<Grid container xs={6} direction="column" item> 
							<p>Reference Survey:</p>
							<SurveySelector
								surveys={surveys.filter((s) => !comparisonSurvey || s.programme_date_offset_days > comparisonSurvey.programme_date_offset_days )}
								surveySelectedCallback={(id) => setReferenceSurvey(surveys.filter((s)=> s.id===id)[0])}
								selectedSurvey={referenceSurvey}
							/>
						</Grid>
					</Grid>
			</Grid>


			<Grid container>
				<Grid container direction="column" xs={6} className={classes.column} item>
					{!loading && <AverageAssessmentByCompetencyChart inputData={data?.all} selectedAttendee={selectedAttendee} selectedAttendeeData={data?.selectedAttendeeData} fullyCompletedSurveysOnly={fullyCompletedOnly} />}
					{loading && <>Loading...</>}
				</Grid>
				
				
				<Grid container direction="column" xs={6} className={classes.column} item>
					<Grid item>
						<AveragePerformanceImprovement 
							surveyOne={comparisonSurvey}
							surveyTwo={referenceSurvey}
							inputDataAttendees={data?.attendees}
							inputDataReviewers={data?.reviewers}
						/>
						
						<LowestRatedCompentencies 
							programmeId={programme_id}
							survey={surveys.find((survey) => survey.name === comparisonSurvey?.name)}
							inputData={data?.all}
						/>
						<LowestRatedCompentencies 
							programmeId={programme_id}
							survey={surveys.find((survey) => survey.name === referenceSurvey?.name)}
							inputData={data?.all}
						/>
						<LeastImprovedCompetencies
							surveyOneName={comparisonSurvey?.name}
							surveyTwoName={referenceSurvey?.name}
							inputData={data?.all}
						/>
						
					</Grid>
				</Grid>
			</Grid>
				<Grid container xs={12}>
					
				<Grid className={classes.column} item xs={6} >
					<NoTimeOrOpportunityChart programmeId={programme_id} filter={'attendee'} fullyCompletedOnly={fullyCompletedOnly} clientId={(clientDashboard && clientDashboard===true) ? programme?.client : undefined}/>
				</Grid>
				<Grid className={classes.column} item xs={6} >
					<NoTimeOrOpportunityChart programmeId={programme_id} filter={'reviewer'} fullyCompletedOnly={fullyCompletedOnly} clientId={(clientDashboard && clientDashboard===true) ? programme?.client : undefined} />
				</Grid>
			</Grid>
			<Grid container xs={12} item>
				<SurveyCompetencyScoreAvgRangeTable inputData={data?.attendees} type="attendee" key={'att-table'}/>
				<SurveyCompetencyScoreAvgRangeTable inputData={data?.reviewers} type="reviewer" key={'rev-table'}/>
			</Grid>


		</Grid>
</>

	)
}

export default Dashboard


