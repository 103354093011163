import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  navBar: {
    justifyContent: 'start',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr',
    backgroundColor: '#26377B',
    alignItems: 'stretch',
    justifyItems: 'stretch',
    width: '100%',
    display: 'grid'
  },
  navItem: {
    backgroundColor: '#E9E9E9',
    color: '#000000',
    fontSize: '17px',
    wordWrap: 'break-word',
    border: '1px solid black',
    textAlign: 'center',
    padding: '14px 0',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  navItemSelected: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '17px',
    wordWrap: 'break-word',
    textAlign: 'center',
    border: '1px solid white',
    padding: '14px 0',
    cursor: 'pointer',
    textDecoration: 'none'
  }
}));

export const NavBar = () => {
  const classes = useStyles();
  const { url } = useRouteMatch()

  return (
    <div className={classes.navBar}>
      <NavLink
        to={`${url}/competencies`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Competencies
      </NavLink>
      <NavLink
        to={`${url}/schedule`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Schedule
      </NavLink>
      <NavLink
        to={`${url}/clients`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Clients
      </NavLink>
      <NavLink
        to={`${url}/tutors`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Tutors
      </NavLink>
      <NavLink
        to={`${url}/config`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Config
      </NavLink>
      <NavLink
        to={`${url}/users`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Users
      </NavLink>
      <NavLink
        to={`${url}/tags`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
       Tags
      </NavLink>
  </div>
  );
};
