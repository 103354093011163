import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import OpensideTheme from './theme/index';

const styles = ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  submitSurveyButton: {
    margin: 10,
    backgroundColor: "#26377B"
  },
  footer: {
    marginTop: 200,
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "LightGray",
    color: "white",
  },
  progress: {
    margin: 0
  },
  //TODO: apply sitewide using themes/index.js or disable global override to 14px.
  h1: { fontSize: '2em' },
  h2: { fontSize: '1.5em' },
  h3: { fontSize: '1.17em' },
  h4: { fontSize: '1.02em' },
  navbar: {
    "& button": {
      display: "none"
    }
  }
});

interface Props {
  selfReviewing: boolean,
  dismiss: (value: React.SetStateAction<boolean>) => void,
  front_pages: {
    front_page_attendee: string,
    front_page_reviewer: string,
  }
}

export const SurveyBriefing: FC<Props> = ({ selfReviewing, dismiss, front_pages }: Props) => {
  return (
    <MuiThemeProvider theme={OpensideTheme}>
      <div style={styles.root}>
        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: '30px' }}>
          <Grid xs={11} sm={7} md={7} lg={4} item={true}>
            <Grid>
                <Typography component='div'>
                  {selfReviewing ?
                    (<ReactMarkdown>{front_pages.front_page_attendee || '### Error: no front page found'}</ReactMarkdown>)
                    :
                    (<ReactMarkdown>{front_pages.front_page_reviewer || '### Error: no front page found'}</ReactMarkdown>)
                  }
                </Typography>
                <div style={{ "height": 30 }} />
            </Grid>
            <Button
              style={styles.submitSurveyButton}
              variant="contained"
              type="submit"
              color="primary"
              onClick={dismiss as unknown as React.MouseEventHandler<HTMLButtonElement>}
            >
              I understand
            </Button>
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  )
}
