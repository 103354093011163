import React from 'react';
import TextField from '@material-ui/core/TextField';

class ReasonBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isRatingJustification, answerText } = this.props
    const label = isRatingJustification
      ? 'Explanation'
      : 'Reason'
    const long_label = isRatingJustification
      ? "Please provide an explanation for the assesment above"
      : "Please provide further details"

    return (
      <TextField
        value={answerText ? answerText : ''}
        onChange={this.props.handleAnswerTextChange}
        margin="dense"
        inputProps={{ maxLength: 300 }}
        multiline
        label={label}
        fullWidth
        helperText={long_label}
      />)
  }
}


export default ReasonBox