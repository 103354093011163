
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

  export default function TriggersTooltips(props) {
    return(
      <Tooltip disableFocusListener title={props.title}>
          <p>{props.text}</p>
      </Tooltip>
    )
  }




