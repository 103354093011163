import { createContext } from 'react'
import { User } from '../../types'

interface Context {
  user: User | undefined
  setUser: (user: User | undefined) => void
}

export const UserContext = createContext<Context>({
  user: undefined,
  setUser(user: User | undefined) {}
})

export const isPlatformAdmin = (user:User|undefined) => {
    return user?.roles?.includes('tc_admin') && !user?.roles?.includes('org_admin')
}
export const isOrgAdmin = (user: User|undefined, org: string|undefined) => {
    return user?.roles?.includes('org_admin') && org !== undefined && user?.org !== undefined && user?.org === org
}