import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OpensideTheme from './theme'

const styles = theme => ({
  button: {
    marginTop: 20,
    float: "right"
  },
  h1: { fontSize: '2em' },
  h2: { fontSize: '1.5em' },
  navbar: {
    "& button": {
      display: "none"
    }
  }
});

class SurveyPreviouslySubmitted extends React.Component {

  render() {
    const {classes} = this.props;
    return (
      <MuiThemeProvider theme={OpensideTheme}>
        <Grid container direction="row" justify="center" alignItems="center" style={{"height": "100vh"}} className="{classes.root}">
          <Grid xs={11} sm={7} md={7} lg={4} item>

            <Typography component="h1" className={classes.h1}>Sorry!</Typography>
            <Typography component="h2" className={classes.h2}>You have already responded to this survey.</Typography>

          </Grid>
        </Grid>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(SurveyPreviouslySubmitted);