import React, { useEffect, useState, FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { Option, Statement } from '../../../../types'
import { getOptionByQuestion } from '../../../../api/option'
import { useStyles } from '../../../../theme/styles'
import YesNoSlider from '../../../survey/components/YesNoSlider'
import CustomTooltip from '../../../survey/components/CustomTooltip'
import NullableSlider from '../../../survey/components/NullableSlider'
import Icon from '../../../Common/Icon';

interface Props {
  statement: Statement
  status: string
  openStatement: () => void
  refresh: number
}

export const StatementPreview: FC<Props> = ({ statement, openStatement, status, refresh }: Props) => {
  const classes = useStyles()
  const [busy, setBusy] = useState<boolean>(false)
  const [options, setOptions] = useState<Option[]>([])
  const reload = () => {
    if (statement.id !== 'new') {
      setBusy(true)
      getOptionByQuestion(statement.id)
        .then(result => {
          setBusy(false)
          setOptions(result)
        })
        .catch(() => setBusy(false))
    }
  }
  useEffect(() => { reload() }, [statement.id, refresh])

  return (
    <Accordion
      className={classes.survey_statement}
    >
      <AccordionSummary
        className={classes.survey_statement_header}
        expandIcon={<ExpandMoreIcon className={classes.survey_statement_header} />}
        aria-label="Expand"
        aria-controls={`${statement.id}_content`}
        id={`${statement.id}_header`}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
        >
          <Typography
            component="h2"
            className={classes.h3}
          >
            {statement.self_review_text}
          </Typography>
          {status === 'Draft' &&
            <div
              onClick={(event: any) => {
                event.stopPropagation()
                openStatement()
              }}
            >
              <Icon icon="pencil" />
            </div>
          }
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={classes.survey_statement_body}
      >
        <Typography component="h3" className={classes.h3}
          style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          Are you able to assess your skill level for this topic?
                </Typography>
        <YesNoSlider
          value={0}
          handleValueChange={() => { }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: "100%"
          }}
        >
          <div
            style={{
              width: "2em",
              height: "2em",
              padding: "1em",
              transform: "rotate(-45deg)"
            }}
          >
            <CustomTooltip
              title={"Yes"}
              text={"Yes"}
            />
          </div>
          <div
            style={{
              width: "2em",
              height: "2em",
              padding: "1em",
              transform: "rotate(-45deg)"
            }}
          >
            <CustomTooltip
              title={'No'}
              text={'No'}
            />
          </div>
        </div >
        <div style={{ "marginTop": "40px", "marginBottom": "55px" }}>
          <Grid container justify="flex-end" spacing={1} direction="row">
            <Grid item xs={12}>
              <Typography component="h3" className={classes.h3}>
                Please assess your current level compared to your reviewer group
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ fontSize: "12px", wordBreak: "break-word" }}>
              Enter assessment
            </Grid>
            <Grid item xs={8}>
              <NullableSlider
                disabled={false}
                value={63}
                handleValueChange={() => { }}
              />
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: "100%"
                }}
              >
                {
                  options
                    .map(option =>
                      <div key={option.id}
                        style={{
                          width: "2em",
                          height: "2em",
                          padding: "1em",
                          transform: "rotate(-45deg)"
                        }}
                      >
                        {option.statement.trim().search(" ") !== -1 ?
                          <CustomTooltip
                            title={option.statement.trim()}
                            text={
                              `${option.statement.split(" ")[0]}...`
                            }
                          /> :
                          option.statement.trim()}
                      </div>
                    )
                }
              </div>
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
