import React, { useState, FC } from 'react';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MDEditor from '@uiw/react-md-editor';

import { SurveySchema } from '../../../../types'
import {create_survey_schema, update_survey_schema, destroy_survey_schema} from '../../../../api/survey_schema'
import { useStyles } from '../../../../theme/styles'

interface Props {
  survey: SurveySchema
  close: () => void
}

export const SurveySchemaDialog: FC<Props> = ({ survey, close }: Props) => {
  const [survey_name, setName] = useState<string>(survey.survey_name)
  const [offset, setOffset] = useState<number>(survey.programme_date_offset_days)
  const [expires_after_days, setExpiresAfterDays] = useState<number>(survey.expires_after_days)
  const [state_front_page_template_attendee, set_state_front_page_template_attendee] = useState<string | undefined>(survey.front_page_template_attendee)
  const [state_front_page_template_reviewer, set_state_front_page_template_reviewer] = useState<string | undefined>(survey.front_page_template_reviewer)
  const changed = survey_name !== survey.survey_name ||
    offset !== survey.programme_date_offset_days ||
    expires_after_days !== survey.expires_after_days

  const [busy, setBusy] = useState<boolean>(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false)

  const classes = useStyles()

  const cancel = () => {
    close()
  };

  const save = () => {
    setBusy(true)
    const action = survey.id === 'new'
      ? create_survey_schema
      : update_survey_schema
    action({
      ...survey,
      survey_name,
      programme_date_offset_days: offset,
      expires_after_days,
      front_page_template_attendee: state_front_page_template_attendee ? state_front_page_template_attendee : '', 
      front_page_template_reviewer: state_front_page_template_reviewer ? state_front_page_template_reviewer : ''
    })
    .then(success => {
      setBusy(false)
      close()
    })
    .catch(error => {
      setBusy(false)
    })
  }

  const destroy = () => {
    setBusy(true)
    destroy_survey_schema(survey)
    .then(success => {
      setBusy(false)
      close()
    })
    .catch(error => {
      setBusy(false)
    })
  }

  return (
    <Dialog
      aria-labelledby="survey-schema-modal"
      open={true}
      fullWidth={true}
      maxWidth={'lg'}>
      <DialogTitle id="survey-schema-modal">{survey.id === 'new' ? 'New survey' : 'Edit survey'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='Alias'
              value={survey_name}
              className={classes.input}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Days after start of programme'
              type='number'
              value={offset}
              className={classes.input}
              required
              onChange={(e) => setOffset(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Days to complete survey'
              type='number'
              value={expires_after_days}
              className={classes.input}
              required
              onChange={(e) => setExpiresAfterDays(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Survey briefing for attendees</Typography>
            <MDEditor
              value={state_front_page_template_attendee}
              onChange={set_state_front_page_template_attendee}
            />
            <MDEditor.Markdown source={state_front_page_template_attendee} style={{ whiteSpace: 'pre-wrap' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography>Survey briefing for reviewers</Typography>
            <MDEditor
              value={state_front_page_template_reviewer}
              onChange={set_state_front_page_template_reviewer}
            />
            <MDEditor.Markdown source={state_front_page_template_reviewer} style={{ whiteSpace: 'pre-wrap' }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {survey.id !== 'new' &&
          <Button
            onClick={() => setOpenConfirmDelete(true)}
            disabled={busy}
          >Delete</Button>
        }
        <Button
          onClick={() =>
            changed
              ? setOpenConfirmCancel(true)
              : cancel()
          }
          disabled={busy}
        >Cancel</Button>
        <Button
          onClick={save}
          disabled={busy || !changed}
        >Save</Button>
      </DialogActions>
      <Dialog
        aria-labelledby="survey-schema-delete-modal"
        open={openConfirmDelete}
      >
        <DialogTitle id="survey-schema-delete-modal">Confirm survey delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
          }}>Cancel</Button>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
            destroy()
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="survey-schema-cancel-modal"
        open={openConfirmCancel}
      >
        <DialogTitle id="survey-schema-delete-modal">You have unsaved changes!</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
          }}>Continue editing</Button>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
            cancel()
          }}>Discard changes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
