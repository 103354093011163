import React from 'react'
import Tooltip from "@material-ui/core/Tooltip"

const reviewer_name_cell = (reviewerName, attendeeName, x, y, isShaded) => [
    <Tooltip title={`Reviewing ${attendeeName}`}>
        <div
            key={"name_" + reviewerName + "_" + x + "_" + y}
            style={{
                gridRowStart: x,
                gridColumnStart: y,
                border: '1px solid #dfdfdf',
                ...isShaded ? {backgroundColor: 'lightgray'} : {},
            }}
        >
          {reviewerName}
        </div>
    </Tooltip>
]

const attendee_name_cell_row = (attendeeName, x, y, isShaded, isBold) => [
     <Tooltip title={`Attendee`}>
     <div
        key={"name_" + attendeeName + "_" + x + "_" + y}
        style={{
            gridRowStart: x,
            gridColumnStart: y,
            border: '1px solid #dfdfdf',
            borderTop: '1px solid #000',
            ...isShaded ? {backgroundColor: 'lightgray'} : {},
            ...isBold ? {fontWeight: 'bolder'} : {},
        }}
    >
        {attendeeName}
    </div>
    </Tooltip>
]

// Print attendee - reviewer pairs
export const printAttendeeRows = (reviewers, display) => 
    reviewers.map((r,i) => {
        const isShaded = i%2 === 0
        if (display === "Attendees") {
          return [attendee_name_cell_row(r.attendee.name, i + 7, 2, isShaded, true)]
        }
        else if (display === "Reviewers"){
          return r.self ? [] : [reviewer_name_cell(r.name, r.attendee.name, i + 7, 2, isShaded)]
        } else {
            return r.self ? [attendee_name_cell_row(r.attendee.name, i + 7, 2, isShaded, true)] : [reviewer_name_cell(r.name, r.attendee.name, i + 7, 2, isShaded)]
        }
    }
)