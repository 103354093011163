import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useRouteMatch } from 'react-router-dom'
import { UserContext, isPlatformAdmin } from 'src/components/user/context';

const useStyles = makeStyles(() => ({
  navBar: {
    justifyContent: 'start',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 4fr',
    backgroundColor: '#26377B',
    alignItems: 'stretch',
    justifyItems: 'stretch',
    width: '100%',
    display: 'grid'
  },
  navItem: {
    backgroundColor: '#E9E9E9',
    color: '#000000',
    fontSize: '17px',
    wordWrap: 'break-word',
    border: '1px solid black',
    textAlign: 'center',
    padding: '14px 0',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  navItemSelected: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '17px',
    wordWrap: 'break-word',
    textAlign: 'center',
    border: '1px solid white',
    padding: '14px 0',
    cursor: 'pointer',
    textDecoration: 'none'
  }
}));

export const NavBar = () => {
  const userContext = useContext(UserContext)

  const classes = useStyles()
  const { url } = useRouteMatch()

  return (
    <div className={classes.navBar}>

      <NavLink
        to={`${url}/details`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
        Programme Details
      </NavLink>

      <NavLink
        to={`${url}/responses`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
        Responses
      </NavLink>

      {isPlatformAdmin(userContext.user) && <NavLink
        to={`${url}/metrics`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
        Metrics
      </NavLink>}

      <NavLink
        to={`${url}/survey/urls`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
        Survey URLs
      </NavLink>

      {isPlatformAdmin(userContext.user) && <NavLink
        to={`${url}/dashboard`}
        activeClassName={classes.navItemSelected}
        className={classes.navItem}
      >
        Dashboard
      </NavLink>}
    </div>
  );
};
