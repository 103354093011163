import React from 'react';
import Typography from '@material-ui/core/Typography';
import CustomTooltip from './CustomTooltip';
import NullableSlider from './NullableSlider';
import Previdence from './Previdence'
import YesNoButton from './YesNoButton'
import ReasonBox from './ReasonBox';
import TimeXOpportunity from './TimeXOpportunity';
import EvidenceAdd from './EvidenceAdd';
import EvidenceList from './EvidenceList'
import Grid from '@material-ui/core/Grid'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NotificationSnackbar from './NotificationSnackbar';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const ExpansionPanel = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

class SurveyQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userWantsToAnswer: null,
      userWantsToAnswerValue: 50,
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = () => (value) => {
    var userWantsToAnswer = value === 0 ?
      true :
      value === 100 ?
        false :
        null
    this.setState({
      userWantsToAnswer,
      userWantsToAnswerValue: value
    }, () => {
      if (userWantsToAnswer == null) {
        this.props.handleValueChange(undefined);
      } else if (userWantsToAnswer == 0) {
        this.props.handleValueChange(null);
      }
    })


  };

  slider_labels = (options) =>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: "100%",
        height: '5em',
      }}
    >
      {
        options
          .sort((a, b) =>
            a.order !== undefined && b.order !== undefined
              ? a.order - b.order
              : new Date(a.updatedAt) - new Date(b.updatedAt))
          .map(option =>
            <div key={option.createdAt}
              style={{
                width: '0em',
                direction: 'rtl',
                transform: "rotate(-45deg)"
              }}
            >
              {option.statement.trim().search(" ") !== -1 ?
                <CustomTooltip
                  title={option.statement.trim()}
                  text={
                    `${option.statement.split(" ")[0]}...`
                  }
                /> :
                option.statement.trim()}
            </div>
          )
      }
    </div>

  slider_control = (handleValueChange) =>
    <div>
      <NullableSlider
        disabled={!this.state.userWantsToAnswer}
        value={this.props.value}
        handleValueChange={handleValueChange}
      />
    </div>

  elementCount(input) {
    if (input == undefined) {
      return 0;
    } else {
      return input.length
    }
  }

  getEvidence() {
    const recentAnswers = this.props.selfReviewing ? this.props.recentAnswers : this.props.attendeeRecentAnswers
    if (this.elementCount(recentAnswers) === 0) {
      return {
        evidence: [],
        reason: undefined,
      }
    }
    recentAnswers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    const last_answer = recentAnswers[0]
    return {
      evidence: last_answer.evidence && last_answer.evidence.length > 0 ? last_answer.evidence : [],
      reason: last_answer.text && last_answer.text !== '' ? last_answer.text : undefined
    }
  }

  getNextButtonDisableValue = (userWantsToAnswer, timeXopportunity, value, answerText) => {
    value = value === null ? undefined : value
    timeXopportunity = timeXopportunity === null ? undefined : timeXopportunity
    answerText = answerText === '' ? undefined :
      answerText === ' ' ? undefined :
        answerText === null ? undefined :
          answerText

    return userWantsToAnswer === null ||
      (userWantsToAnswer === true && value === undefined) ||
      (userWantsToAnswer === false && (timeXopportunity === undefined || answerText === undefined))
  }

  getNextButtonTextValue = (userWantsToAnswer, timeXopportunity, value, answerText) => {
    value = value === null ? undefined : value
    timeXopportunity = timeXopportunity === null ? undefined : timeXopportunity
    answerText = answerText === '' ? undefined :
      answerText === ' ' ? undefined :
        answerText === null ? undefined :
          answerText

    return userWantsToAnswer === null ?
      'Please answer with yes or no' :
      userWantsToAnswer && value === undefined ?
        'Please enter a score' :
        !userWantsToAnswer && timeXopportunity === (undefined || null) && answerText === undefined ?
          'Please select one option from the table and provide a reason for it' :
          !userWantsToAnswer && timeXopportunity !== undefined && answerText === undefined ?
            'Please provide a reason for your selection' :
            !userWantsToAnswer && timeXopportunity === undefined && answerText !== undefined ?
              'Please select one option from the table' :
              null
  }

  render() {
    const { questionText, answerText, options, classes, value, timeXopportunity, attendeeName, selfReviewing } = this.props;
    const { userWantsToAnswer } = this.state
    const { evidence, reason } = this.getEvidence()
    const labels = this.slider_labels(options)
    const reviewerRecentAnswersCnt = this.elementCount(this.props.recentAnswers)
    const disableNext = this.getNextButtonDisableValue(userWantsToAnswer, timeXopportunity, value, answerText)
    const disabledReason = this.getNextButtonTextValue(userWantsToAnswer, timeXopportunity, value, answerText)
    const questionPhrase = reviewerRecentAnswersCnt == 0 ?
      "Are you able to assess " + (selfReviewing ? 'your' : `${attendeeName}'s`) + " skill level for this topic?"
      :
      (selfReviewing ? 'Has your' : `Has ${attendeeName}'s`) + " skill level for this topic changed since the programme?"
    return (
      <div style={{ "marginTop": "40px", "marginBottom": "55px" }}>
        <ExpansionPanel expanded={this.props.expanded} onChange={this.props.toggleExpansion} style={{ borderColor: '#26377B' }}>
          <ExpansionPanelSummary
            style={{ backgroundColor: '#26377B', color: 'white' }}
            expandIcon={<ExpandMoreIcon
              style={{ color: 'white' }} />}
            aria-controls={`${this.props.question.id}_content`}
            id={`${this.props.question.id}_header`}
          >
            <Typography component="h2" className={classes.h3}>{questionText} </Typography>
            {this.props.answered ? <DoneOutlineIcon style={{
              fill: "#00FF00", position: "absolute",
              right: 40
            }} /> : null}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            id={`${this.props.question.id}_content`}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginTop: "10px"
              }}
            >
              <Typography component="h3" className={classes.h3}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {questionPhrase}
              </Typography>
              <Typography component="p" className={classes.p}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >If you are unable to provide an assessment, please select 'no' and give a reason.</Typography>

              <YesNoButton
                value={this.state.userWantsToAnswerValue}
                handleValueChange={this.handleChange()} />

              {
                userWantsToAnswer
                  ? <div style={{ "marginTop": "40px", "marginBottom": "55px" }}>
                    <Grid container justify="flex-end" spacing={1} direction="row">
                      <Grid item xs={12}>
                        <Typography component="h3" className={classes.h3}>
                          Please assess {selfReviewing ? 'your current level compared to your' : `${attendeeName}'s skill level compared to their`} reviewer group
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Previdence
                          recentAnswers={this.props.recentAnswers}
                          attendeeRecentAnswers={this.props.attendeeRecentAnswers}
                          selfReviewing={this.props.selfReviewing}
                          attendeeName={this.props.attendeeName}
                          classes={classes}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ fontSize: "12px", wordBreak: "break-word" }}>
                        {"Enter assessment"}
                      </Grid>
                      <Grid item xs={8}>
                        {this.slider_control(this.props.handleValueChange)}
                      </Grid>
                      <Grid item xs={8}>
                        {labels}
                      </Grid>
                    </Grid>
                    {this.props.selfReviewing ?
                      <EvidenceAdd
                        handleAddEvidence={this.props.handleAddEvidence}
                        questionId={this.props.key}
                        newEvidence={this.props.newEvidence}
                        handleRemoveEvidence={this.props.handleRemoveEvidence}
                      />
                      :
                      null
                    }
                    <div style={{ "marginTop": "40px" }}>
                      <ReasonBox
                        isRatingJustification={userWantsToAnswer}
                        answerText={answerText}
                        handleAnswerTextChange={this.props.handleAnswerTextChange}
                      />
                    </div>

                  </div>
                  : userWantsToAnswer === false ?
                    <div>
                      <TimeXOpportunity
                        handleAnswerTimeXOpportunity={this.props.handleAnswerTimeXOpportunity}
                        selfReviewing={this.props.selfReviewing}
                        attendeeName={this.props.attendeeName}
                        timeXopportunity={timeXopportunity} />

                      <div style={{ "marginTop": "40px" }}>
                        <ReasonBox
                          isRatingJustification={userWantsToAnswer}
                          answerText={answerText}
                          handleAnswerTextChange={this.props.handleAnswerTextChange}
                        />
                      </div>

                    </div>
                    :
                    null
              }
              <EvidenceList
                  reason={reason}
                  evidence={evidence}
                  selfReviewing={this.props.selfReviewing}
                  attendeeName={this.props.attendeeName}
              />
              {this.props.hideNextButton ?
                null
                :
                <div style={{ "marginTop": "40px" }}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid xs={8} item={true}>

                      {this.props.errorMessage != undefined ?
                        <NotificationSnackbar message={this.props.errorMessage} />
                        :
                        <Typography component="h4" className={this.props.classes.h4} style={{
                          "marginLeft": "40px",
                          "marginRight": "20px",
                          "float": "right"
                        }}>{disabledReason}</Typography>
                      }
                    </Grid>
                    <Grid xs={4} style={{ color: "white", "float": "right" }} item={true}>
                      <Button
                        className={this.props.classes.submitSurveyButton}
                        style={{ "float": "right" }}
                        variant="contained"
                        type="submit"
                        color="inherit"
                        disabled={disableNext}
                        onClick={this.props.handleNext}
                      >
                        Next
                  </Button>
                    </Grid>
                  </Grid>
                </div>
              }
            </div>
          </ExpansionPanelDetails >
        </ExpansionPanel >
      </div >
    );
  }
}

export default SurveyQuestion;
