import React, { useState, useEffect, FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../../../../../../theme';
import { getReviewerListForAttendee } from '../../../../../../api/reviewer';
import { Attendee, Reviewer } from '../../../../../../types';
import Icon from '../../../../../Common/Icon';
import ViewOnlyField from '../../../../../Common/ViewOnlyField';
import EditReviewer from '../EditReviewer'


const useStyles = makeStyles(() => ({
  tag: {
    color: theme.colours.gray,
    fontSize: 12
  },
  row: {
    marginBottom: 15
  },

  reviewer_item: {
    border: '1px solid #EEE',
    padding: '0 5px'
  },
  addNewReviewerButton: {
    background: '#EEE',
    color: '#26377B',
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
    marginLeft: 15,
    cursor: 'pointer',
    position: 'relative',
    height: 60,
    textAlign: 'left'
  },
  attendee_block: {
    background: '#26377B',
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
    marginRight: 15,
    color: '#FFFFFF',
    cursor: 'pointer',
    height: 60
  },
  reviewer_block: {
    background: '#EEE',
    color: '#26377B',
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
    marginRight: 5,
    cursor: 'pointer',
    position: 'relative',
    height: 60
  },

}));

interface AttendeeRowProps {
  attendee: Attendee;
  disabled?: boolean;
  toggleEditAttendee: (attendee?: Attendee) => void;
};

interface AttendeeFields {
  name: string;
  email: string;
  escalation_path_emails: string;
};

const AttendeeRow: FC<AttendeeRowProps> = ({ attendee, disabled, toggleEditAttendee }: AttendeeRowProps) => {
  const classes = useStyles();

  const [attendeeReviewers, setAttendeeReviewers] = useState<Reviewer[]>([]);
  const reload = () => {
    getReviewerListForAttendee(attendee.id)
      .then(setAttendeeReviewers)
  }
  useEffect(reload, [attendee])

  const [updatedValues, setUpdatedValues] = useState<AttendeeFields>({
    name: attendee.name,
    email: attendee.email,
    escalation_path_emails: attendee.escalation_path_emails,
  });
  useEffect(() => {
    setUpdatedValues(attendee)
  }, [attendee])

  const [editingReviewer, setEditingReviewer] = useState<Reviewer | undefined>(undefined);

  const renderReviewer = (rev: Reviewer) => {

    return <React.Fragment key={rev.id}>

      <Grid item xs={3}>
        <div className={classes.reviewer_block} key={"rev_block_" + rev.id} onClick={() => setEditingReviewer(rev)}>
          <ViewOnlyField
            size="field_small"
            label="Reviewer"
            value={rev.name ?? '-'}
            hideLabelInViewMode={true}
          />
          <ViewOnlyField
            size="field_small"
            label="Reviewer"
            value={rev.email ?? '-'}
            hideLabelInViewMode={false}
          />
        </div>
      </Grid>
    </React.Fragment>
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <div className={classes.attendee_block} onClick={() => toggleEditAttendee(attendee)}>

          <ViewOnlyField
            size="field_small"
            label="Attendee"
            value={updatedValues.name ? updatedValues.name : attendee.name ?? '-'}
            hideLabelInViewMode={true}
          />

          <ViewOnlyField
            size="field_small"
            label="Attendee"
            hideLabelInViewMode={false}
            value={updatedValues.email ? updatedValues.email : attendee.email ?? '-'}
          />

        </div>
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={1}>
          {attendeeReviewers.filter(r => r.email !== attendee.email).map((rev) => (
            renderReviewer(rev)
          ))}

        </Grid>
      </Grid>

      <Grid item xs={2}>
        {!disabled && (
          <div className={classes.addNewReviewerButton} onClick={() => setEditingReviewer({ attendee: attendee.id })}>

            <Icon icon="plus" /> Reviewer

          </div>

        )}

      </Grid>
      {editingReviewer && <EditReviewer
        isModalOpen={!disabled && editingReviewer !== undefined}
        closeModal={() => {
          reload()
          setEditingReviewer(undefined);
        }}
        attendee={attendee}
        reviewer={editingReviewer}
      />}
    </Grid >

  );
};

export default AttendeeRow;
