import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#E9E9E9',
      main: '#26377B',
      dark: '#17293F',
      contrastText: '#E9E9E9'
    },
    secondary: {
      light: '#E9E9E9',
      main: '#26377B',
      dark: '#17293F',
      contrastText: '#E9E9E9'
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the Openside font stack
    // TODO: serve Europa and Century Gothic fonts for those without it installed
    fontFamily: [
      "Europa-Light",
      "Century Gothic",
      "CenturyGothic",
      "AppleGothic",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  }
});

export default theme;
