import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

class YesNoButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const value = this.props.value
    return (
      <div>
        <Button
          className="yes_no_button"
          style={{ "float": "left", "width": "10em" }}
          variant="contained"
          type="submit"
          color={value < 50 ? "primary" : "inherit"}
          onClick={() => this.props.handleValueChange(0)}
        >
          Yes
                  </Button>
        <Button
          className="yes_no_button"
          style={{ "float": "right", "width": "10em" }}

          variant="contained"
          type="submit"
          color={value && value > 50 ? "primary" : "inherit"}
          onClick={() => this.props.handleValueChange(100)}
        >
          No
                  </Button>
      </div >

    )
  }
}

export default YesNoButton;