import React from 'react';
import Grid from '@material-ui/core/Grid';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

class EvidenceList extends React.Component {
    constructor(props) {
        super(props);
    }

    renderEvidence = (evidence) => {
        return evidence.map(e =>
            <Grid key={e.url}
                container spacing={1} direction="row" alignItems="center"style={{ float: "center", marginBottom: '4px' }}>

                <Grid item xs={1}>
                    <AttachmentRoundedIcon
                        style={{
                            color: '#828282'
                        }} />
                </Grid>
                <Grid item xs={11}>
                    <div
                        style={{
                            maxHeight: '2.25em',
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            position: "relative",
                            paddingRight: '4px'
                        }}>
                        <a
                            id={e.url}
                            href={e.url}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                            style={{
                                fontSize: "0.75em",
                                padding: "4px 0",
                                display: "inline-block",
                                allign: "right",
                                marginLeft: "4px",
                            }}
                        >
                            {e.url}
                        </a>
                    </div>
                </Grid>

            </Grid>
        )
    }

    renderReason = (reason) => (
        <Grid container xs={12} direction="column" style={{ marginTop: '10px'}}>
            <Grid>Explanation:</Grid>
            <Grid>{reason}</Grid>
        </Grid>
    )

    render() {
        const evidence = this.props.evidence
        const reason = this.props.reason
        const evidenceIntroStatement = this.props.selfReviewing ? "For context, this is the evidence you provided to support your last score" :
            `${this.props.attendeeName} provided this evidence to help you review their work`
        
        return (
            <div style={{ "marginTop": "40px" }}>
                <Grid container spacing={1} direction="row" alignItems="center"  style={{ float: "center", marginBottom: '4px' }}>
                    {(evidence && evidence.length > 0) && (
                        <>
                            <Grid item xs={4} style={{ wordBreak: "break-word", padding: 4 }}>
                                {evidenceIntroStatement}
                            </Grid>
                            <Grid item xs={8}>
                                {this.renderEvidence(evidence)}
                            </Grid>
                        </>
                    )}
                    {(!evidence || evidence.length === 0) && !this.props.selfReviewing && (
                        <Grid item>
                            {`${this.props.attendeeName} did not provide any evidence to help you review their work`}
                        </Grid> 
                    )}
                    {reason && (
                        <Grid xs={12} item>
                            {reason && this.renderReason(reason)}
                        </Grid>  
                    )}
                     {!reason && !this.props.selfReviewing && (
                        <Grid item>
                            {`${this.props.attendeeName} did not provide any explanation to help you review their work`}
                        </Grid> 
                    )}
                </Grid>
            </div>
        )
    }
}

export default EvidenceList