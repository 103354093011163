import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { createNew } from '../../../api/programme'
import { Client, Programme } from '../../../types';
import { getClientList } from '../../../api/client';

const customStyles = {
  content: {
    width: '30%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const useStyles = makeStyles(() => ({

  input: {
    width: '100%'
  },
  form_button: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
    marginRight: 10,
    textAlign: 'center',
    width: '35%',
    cursor: 'pointer'
  }
}));

interface CreateNewProgrammeModalProps {
  closeModal: () => void;
  onProgrammeCreated: (id: string) => void;
}

export const CreateNewProgrammeModal = ({ closeModal, onProgrammeCreated }: CreateNewProgrammeModalProps) => {
  const [clients, setClients] = useState<Client[]>([])
  const reload_clients = () => {
    getClientList()
      .then(setClients)
  }
  useEffect(reload_clients, [])

  const emptyProgramme = {
    title: "Programme Title - change me",
    start: new Date(),
    end: new Date(),
    status: "Draft"
  }
  const [programme, setProgramme] = useState<any>(emptyProgramme)
  const [busy, setBusy] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const isValid = (programme: any) => {
    if (programme.title === undefined || programme.title == "") {
      return false;
    }
    if (!programme.start || !programme.end) {
      return false;
    }
    const start = programme.start ? new Date(programme.start).getTime() : new Date(programme.start ?? 0).getTime();
    const end = programme.end ? new Date(programme.end).getTime() : new Date(programme.end ?? 0).getTime();
    return start <= end;
  }

  const classes = useStyles();
  const createNewProgramme = () => {
    if (!isValid(programme)) {
      return;
    }

    setBusy(true)
    createNew(programme)
    .then(newProgramme => {
      setBusy(false)
      closeModal()
      onProgrammeCreated(newProgramme.id)
    })
    .catch(error => {
      setBusy(false)
      setError('Something went wrong!')
    })
  }

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Create New Programme"
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <h2>Create New Programme</h2>
          <Grid container
            direction="column"
            justify="center"
          >
            <Grid item xs={9}>
              <TextField
                label="Title"
                value={programme.title}
                className={classes.input}
                onChange={(e) => {
                  e.persist();
                  setProgramme({ ...programme, title: e.target.value })
                }
                }
              />
            </Grid>

            <Grid item xs={9}>
              <FormControl className={classes.input}>
                <InputLabel>Client</InputLabel>
                <Select
                  value={programme.client}
                  onChange={(e: any) => setProgramme({ ...programme, client: e.target.value })}
                >
                  {clients.map(client => (
                    <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={9}>
              <TextField
                label="Programme Admin Email"
                value={programme?.programme_admin_email}
                className={classes.input}
                onChange={(e) => {
                  e.persist();
                  setProgramme({ ...programme, programme_admin_email: e.target.value })
                }
                }
              />
            </Grid>

            <Grid item xs={9}>
              <KeyboardDatePicker
                label="Start Date"
                className={classes.input}
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                value={programme.start}
                onChange={(date: any) => setProgramme({ ...programme, start: date, end: date })}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
              />
            </Grid>

            <Grid item xs={9}>
              <KeyboardDatePicker
                label="End Date"
                className={classes.input}
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                value={programme.end}
                onChange={(date: any) => setProgramme({ ...programme, end: date })}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
              />
            </Grid>

          </Grid>
          {error &&
          <Grid container
            direction="row"
          >
            {error}
          </Grid>
          }
          <Grid container
            direction="row"
            spacing={4}
          >

          <Grid item>
            <Button
              onClick={createNewProgramme}
              disabled={busy || !isValid(programme)}
              color='primary'
              variant='contained'
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={closeModal}
              disabled={busy}
              color='secondary'
              variant='contained'
            >
              Cancel
            </Button>
          </Grid>

          </Grid>
        </MuiPickersUtilsProvider>
      </Modal>
    </div >
  )
};
