import { api } from '.';
import { Reviewer } from '../types';
import { query_params } from '../helpers/api';

export const getReviewerList = (filter:any={}) : Promise<Reviewer[]> =>
  api.get(`/reviewer/${query_params(filter)}`)
  .then((response : any) => response.data.data);

export const getReviewerListForAttendee = (attendeeId:string) =>
  api.get(`/reviewer/${query_params({attendee:attendeeId})}`)
  .then((response : any) => response.data.data);

export const getReviewerShow = (reviewerId: string) =>
  api.get(`/reviewer/${reviewerId}`)
  .then((response : any) => response.data);

export const deleteReviewerById = (reviewerId: string) =>
  api.delete(`/reviewer/${reviewerId}`)
  .then((response : any) => reviewerId);
  
  
export const createNew = (reviewer: any) =>
  api.post(`/reviewer`, reviewer).then(response => response.data);

export const saveReviewer = (reviewer: any) =>
  api.put(`/reviewer`, reviewer).then(response => response.data);

export const updateReviewerField = (reviewerId: string, fieldName: string, newValue: string) => {
    return getReviewerShow(reviewerId)
    .then(reviewer => {
      reviewer[fieldName] = newValue;
      return saveReviewer(reviewer);
    });
  }
  

