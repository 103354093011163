import 'rc-slider/assets/index.css'
import React from 'react';
import PreviousAnswersSlider from './PreviousAnswersSlider';
import moment from 'moment'


export const getOrderedPreviousAnswerMarks = (marks) => {
    return marks.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .map(answer => ({
            date: answer.survey.date_sent,
            colour: '#E94C4D',
            level: answer.level,
            self: true,
            createdAt: answer.createdAt
        }))
}
export const getAllMarks = (marks, attendeeMarks) => {
    return [
        ...marks.map(x => ({
            ...x,
            self: false
        })),
        ...attendeeMarks
    ]
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
}

class Previdence extends React.Component {
    constructor(props) {
        super(props);
    }

    getAttendeeSalutation = (upperCase) => {
        if (this.props.selfReviewing) {
            return upperCase ? "Your" : "your"
        } else {
            return this.props.attendeeName + "'s"
        }
    }

    elementCount(input) {
        if (input == undefined) {
            return 0;
        } else {
            return input.length
        }
    }

    render() {
        const attendeeRecentAnswersCnt = this.elementCount(this.props.attendeeRecentAnswers)
        let marks = getOrderedPreviousAnswerMarks(this.props.recentAnswers)
        if (attendeeRecentAnswersCnt > 0) {
            const attendeeMarks = getOrderedPreviousAnswerMarks(this.props.attendeeRecentAnswers)
            marks = getAllMarks(marks.reverse().slice(0, 1), attendeeMarks.slice(0, 2))
        }
        const previdenceCnt = this.elementCount(marks)
        return (
            <div>
                {
                    previdenceCnt > 0 ?
                        marks.map(answer => {
                            return <PreviousAnswersSlider
                                value={answer.level}
                                marks={marks}
                                date={moment(answer.date).format('DD/MM/YYYY')}
                                self={answer.self} />
                        })
                        :
                        null
                }
            </div>
        )
    }
}

export default Previdence