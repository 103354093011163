import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Swal from 'sweetalert2';

import Icon from '../../../../Common/Icon';
import { saveProgramme, deleteProgrammeById } from '../../../../../api/programme';
import { getClientList } from '../../../../../api/client';
import { Client, Programme } from '../../../../../types';

const customStyles = {
  content: {
    width: '30%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const useStyles = makeStyles(() => ({

  input: {
    width: '100%'
  },
  form_button: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
    marginRight: 10,
    textAlign: 'center',
    cursor: 'pointer'
  },
  delete_button: {
    background: 'red',

  },
}));

interface EditProgrammeDetailsProps {
  programme: Programme
  isModalOpen: boolean;
  closeModal: () => void;
}

const EditProgrammeDetails = ({ programme, isModalOpen, closeModal }: EditProgrammeDetailsProps) => {
  const history = useHistory();
  const isValid = (programme: any) => {
    if (programme === undefined ||
        programme.title === undefined || programme.title == "" ||
        programme.programme_admin_email === undefined || programme.programme_admin_email == ""
    ) {
      return false;
    }
    if (!programme.start || !programme.end) {
      return false;
    }
    const start = programme.start ? new Date(programme.start).getTime() : new Date(programme.start ?? 0).getTime();
    const end = programme.end ? new Date(programme.end).getTime() : new Date(programme.end ?? 0).getTime();
    return start <= end;
  }

  const [updatedProgramme, setUpdatedProgramme] = useState<Programme | undefined>(programme)
  useEffect(() => {
    setUpdatedProgramme(programme)
  }, [programme])

  const [clients, setClients] = useState<Client[]>([])
  const reload_clients = () => {
    getClientList()
      .then(setClients)
  }
  useEffect(reload_clients, [])

  const classes = useStyles();

  const save = () => {
    if (!isValid(updatedProgramme)) {
      return;
    }

    return saveProgramme(updatedProgramme!)
      .then(closeModal)
  }


  const deleteProgramme = () => {
    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this programme?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true
    }).then((choice: any) => {
      if (choice.isConfirmed && updatedProgramme?.id) {
        deleteProgrammeById(updatedProgramme.id)
          .then(() => {
            history.push({
              pathname: `/programmes/overview`
            });
          })
      }
    })

  }

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Programme Details"
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <h2>Edit Programme Details</h2>
          <Grid container
            direction="column"
            justify="center"
          >
            <Grid item xs={9}>
              <TextField
                label="Title"
                value={updatedProgramme?.title}
                className={classes.input}
                onChange={(e) => {
                  e.persist();
                  if (updatedProgramme) {
                    setUpdatedProgramme({ ...updatedProgramme, title: e.target.value })
                  }
                }
                }
              />
            </Grid>

            <Grid item xs={9}>
              <FormControl className={classes.input}>
                <InputLabel>Client</InputLabel>
                <Select
                  value={updatedProgramme?.client}
                  onChange={(e: any) => {
                    if (updatedProgramme) {
                      setUpdatedProgramme({ ...updatedProgramme, client: e.target.value })
                    }
                  }
                  }
                >
                  {clients.map((client: any) => (
                    <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={9}>
              <TextField
                label="Programme Admin Email"
                value={updatedProgramme?.programme_admin_email}
                className={classes.input}
                onChange={(e) => {
                  e.persist();
                  if (updatedProgramme) {
                    setUpdatedProgramme({ ...updatedProgramme, programme_admin_email: e.target.value })
                  }
                }
                }
              />
            </Grid>

            <Grid item xs={9}>
              <KeyboardDatePicker
                label="Start Date"
                className={classes.input}
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                value={updatedProgramme?.start}
                onChange={(date: any) => {
                  if (updatedProgramme) {
                    setUpdatedProgramme({ 
                      ...updatedProgramme, 
                      start: date,
                      ...(Date.parse(updatedProgramme.end ?? '') < Date.parse(date) && {
                        end: date
                      })
                    })
                  }
                }
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
              />
            </Grid>

            <Grid item xs={9}>
              <KeyboardDatePicker
                label="End Date"
                className={classes.input}
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                value={updatedProgramme?.end}
                onChange={(date: any) => {
                  if (updatedProgramme) {
                    setUpdatedProgramme({ ...updatedProgramme, end: date })
                  }
                }
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
              />
            </Grid>
          </Grid>

          <Grid container
            direction="row"
          >
            <Grid item xs={3}>

              <div className={classes.form_button} onClick={save}>
                Save {isValid(updatedProgramme) ? <Icon icon="edit" /> : <Icon icon="exclamation" />}
              </div>

            </Grid>
            <Grid item xs={3}>
              {updatedProgramme?.id && updatedProgramme.programme_status === 'Draft' && (
                <div className={`${classes.form_button} ${classes.delete_button}`} onClick={deleteProgramme}>
                  Delete <Icon icon="trash-alt" />
                </div>
              )}
            </Grid>
            <Grid item xs={3}>
              <div className={classes.form_button} onClick={closeModal}>
                Cancel
            </div>
            </Grid>

          </Grid>
        </MuiPickersUtilsProvider>
      </Modal >
    </div >
  )
};

export default EditProgrammeDetails;
