import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';

import theme from '../../../theme';
import Icon from '../../Common/Icon';
import logo_white from './logo_white.png';
import { logout } from '../../../api/user'
import { UserContext, isPlatformAdmin } from '../../user/context'

const useStyles = makeStyles((muiTheme) => ({
  headBar: {
    backgroundColor: theme.colours.opensideBlue,
    color: theme.colours.white,
    alignItems: 'stretch',
    justifyItems: 'stretch',
    width: '100%',
    display: 'grid'
  },
  icon: {
    fontSize: 24,
    color: theme.colours.white,
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: `${muiTheme.spacing(2)}px 0`,
    cursor: 'pointer'
  },
  backButton: {
    gridColumn: '1 / span 1',
    gridRow: '1 / span 1'
  },
  opensideLogo: {
    gridColumn: '16 / span 1',
    gridRow: '1 / span 1',
    padding: '10px'
  },
  orgSection: {
    gridColumn: '28 / span 1',
    gridRow: '1 / span 1',
    fontSize: 16,
    color: theme.colours.white,
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: `${muiTheme.spacing(3)}px 0`
  },
  homeButton: {
    gridColumn: '29 / span 1',
    gridRow: '1 / span 1'
  },
  settingsButton: {
    gridColumn: '30 / span 1',
    gridRow: '1 / span 1'
  },
  logoutButton: {
    gridColumn: '31 / span 1',
    gridRow: '1 / span 1'
  }
}));


const HeadBar : React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext)

  const navigateTo = (pathname: string, preserveUser : boolean = true) => {
    const user = history.location.state;
    const state = preserveUser ? Object.assign({},user) : {};

    history.push({
      pathname,
      state
    });
  };

  const on_logout = () => {
    logout().then( _ => {
      console.log('logged out')
      userContext.setUser(undefined)
            history.push({
              pathname: "/login"
            })
    })
  }

  return (
    <div className={classes.headBar}>
      <div
        className={clsx(classes.backButton, classes.icon)}
        onClick={() =>  history.goBack()}
      >
        <Icon icon="arrow-left" />
      </div>

      <div className={classes.opensideLogo}>
        <img src={logo_white} alt="logo" height="30px" />
      </div>

      <div
        className={clsx(classes.orgSection, classes.icon)}
      >
        {userContext?.user?.username} ({isPlatformAdmin(userContext.user) ? 'Platform Admin' : userContext.user?.orgName})
      </div>
      <div
        className={clsx(classes.homeButton, classes.icon)}
        onClick={() =>  navigateTo('/programmes/overview')}
      >
        <Icon icon="home" />
      </div>

      {isPlatformAdmin(userContext.user) && <div
        className={clsx(classes.settingsButton, classes.icon)}
        onClick={() =>  navigateTo('/settings')}
      >
        <Icon icon="cog" />
      </div>}

      <div
        className={clsx(classes.logoutButton, classes.icon)}
        onClick={on_logout}
      >
        <ExitToAppIcon/>
      </div>
    </div>
  );
};

export default HeadBar;
