import React from 'react';

interface IconProps {
  icon: string
};

const Icon = ({ icon } : IconProps) => (
  <i className={`fa fa-${icon}`} />
);

export default Icon;
