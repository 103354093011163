import React, { useContext } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import Page from '../Layout/Page';
import { NavBar } from './NavBar';
import { Clients, Competences, Tutors, Schedule, Tags } from './Tabs';
import { ConfigSettings } from './Tabs/config'
import { Users } from './Tabs/users'
import { UserContext, isPlatformAdmin } from '../user/context';

const SettingsPage = () => {
  const { path } = useRouteMatch()
  const userContext = useContext(UserContext)
  return (
  <>
    {isPlatformAdmin(userContext.user) && <Page navBar={<NavBar/>}>
      <Switch>
        <Route path={`${path}/competencies`} component={Competences}/>
        <Route path={`${path}/schedule`} component={Schedule}/>
        <Route path={`${path}/clients`} component={Clients}/>
        <Route path={`${path}/tutors`} component={Tutors}/>
        <Route path={`${path}/config`} component={ConfigSettings}/>
        <Route path={`${path}/users`} component={Users}/>
        <Route path={`${path}/tags`} component={Tags} />
        <Redirect to={`${path}/competencies`} />
      </Switch>
    </Page>}
  </>
  )
};

export default SettingsPage;
