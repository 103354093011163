import React, { ChangeEvent, FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { stringify } from "qs"
import Button from '@material-ui/core/Button';
import { DensityPlot } from './DensityPlot'
import { ReasonDots } from './ReasonDots'
import { queryCompetences } from '../../../../api/competence'
import { getShowProgramme } from '../../../../api/programme'
import { getSurveyListByProgramme } from '../../../../api/survey'
import { getCapabilityMetrics } from '../../../../api/capability_metrics'
import { api } from '../../../../api'
import { Sample } from '../../../../types'

export const MetricsPlots: FC = () => {
  const { programme_id } = useParams()
  const [state_selected_plot, set_state_selected_plot] = useState<string>('Distribution')

  const [state_selected_difference, set_state_selected_difference] = useState('None')

  const [state_competences, set_state_competences] = useState<any[]>([])
  const [state_selected_competency, set_state_selected_competency] = useState<string>('All')
  const [state_competences_loading, set_state_competences_loading] = useState<boolean>(true)
  const get_competences = () => {
    set_state_competences_loading(true)
    getShowProgramme(programme_id)
      .then(programme => queryCompetences({ id: { $in: programme.competences } }))
      .then(competences => {
        set_state_competences(competences)
        set_state_competences_loading(false)
      })
  }
  useEffect(get_competences, [programme_id])

  const [state_surveys, set_state_surveys] = useState<any[]>([])
  const [state_selected_survey, set_state_selected_survey] = useState<string[]>(['All'])
  const [state_surveys_loading, set_state_surveys_loading] = useState<boolean>(true)
  const get_surveys = () => {
    set_state_surveys_loading(true)
    getSurveyListByProgramme(programme_id)
      .then(surveys => {
        set_state_surveys(surveys)
        set_state_surveys_loading(false)
      })
  }
  useEffect(get_surveys, [programme_id])

  const [state_metrics, set_state_metrics] = useState<Sample[]>([])
  const [state_metrics_loading, set_state_metrics_loading] = useState<boolean>(true)
  const get_metrics = () => {
    set_state_metrics_loading(true)
    getCapabilityMetrics({
      programme: programme_id,
      ...state_selected_competency === 'All' ? {} : { competence: state_selected_competency }
    })
      .then(metrics => {
        set_state_metrics(metrics)
        set_state_metrics_loading(false)
      })
  }
  useEffect(get_metrics, [programme_id, state_selected_competency])

  const select_competency = (e: ChangeEvent<HTMLSelectElement>) => {
    set_state_selected_competency(e.target.value)
  }

  const select_survey = (e: ChangeEvent<HTMLSelectElement>) => {
    const newSelection = e.target.value;
    if (e.target.value === 'All') {
      set_state_selected_survey(['All'])
    } else {
      const selectedSurveys = state_selected_survey.filter(e => e !== 'All')
      if (selectedSurveys.indexOf(newSelection) !== -1) {
        selectedSurveys.splice(selectedSurveys.indexOf(newSelection), 1)
      } else {
        selectedSurveys.push(newSelection)
      }
      
      if (selectedSurveys.length == 0) {
        selectedSurveys.push('All');
      }
      set_state_selected_survey(selectedSurveys)
    }

  }

  const select_plot = (e: ChangeEvent<HTMLSelectElement>) => {
    set_state_selected_plot(e.target.value)
  }

  const DownloadButton = () => {
    const download = () => api({
      url: `spec/raw_answers?${stringify({ programme: programme_id })}`,
      method: 'get',
      responseType: 'blob'
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${programme_id}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    return (
      <div
        style={{
          justifySelf: `center`,
          alignSelf: `center`,
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={download}
        >
          Download
      </Button>
      </div>
    )
  }

  const CompetencySelector = () => (
    <select
      value={state_selected_competency}
      onChange={select_competency}
    >
      <option
        value='All'>
        All
        </option>
      {!state_competences_loading &&
        state_competences.map(c => (
          <option
            key={c.id}
            value={c.id}>
            {c.name}
          </option>))}
    </select>
  )

  const SurveySelector = () => (
    <select
      value={state_selected_survey}
      onChange={select_survey}
      multiple
      size={state_surveys.length === 0 ? 4 : state_surveys.length + 1}
    >
      <option
        value='All'>
        All
        </option>
      {!state_surveys_loading &&
        state_surveys.map(s => (
          <option
            key={s.id}
            value={s.id}>
            {s.name}
          </option>))}
    </select>
  )

  const PlotTypeSelector = () => (
    <select
      value={state_selected_plot}
      onChange={select_plot}
    >
      <option value='Distribution'> Distribution </option>
      <option value='Reasons'> Reasons </option>
      <option value='Raw'> Raw Data </option>
    </select>
  )

  const DifferenceSelector = () => (
    <select 
      name="showChange"
      id="showChange"
      value={state_selected_difference}
      onChange={(e) => set_state_selected_difference(e.target.value)}
    >
      <option value="None">None</option>
      <option value="Difference">Difference</option>
    </select>
  )

  const Selector = ({ label, select }: any) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: `center`,
        alignItems: 'stretch'
      }}
    >
      <div
        style={{
          flexBasis: '45%',
          padding: '1em',
          textAlign: 'right'
        }}
      >
        {label}
      </div>
      <div
        style={{
          flexBasis: '45%',
          padding: '1em'
        }}
      >
        {select}
      </div>
    </div>
  )

  const DistributionGraph = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    > 
      <Plots label='Response Summary'>
        <DistributionPlot label='Response Summary' />
      </Plots>
    </div>
  )

  const ReasonsGraph = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}
    >
      <>
        <Plots label='Self Reviews' >
          <ReasonPlot party='self' label='Self Reviews' />
        </Plots>
        <Plots label='Reviewer Reviews' >
          <ReasonPlot party='reviewer' label='Reviewer Reviews' />
        </Plots>
      </>
    </div>
  )

  const Plots = ({ label, children }: any) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          padding: '1em',
        }}
      >
        {label}
      </div>
      <div
        style={{
          padding: '1em'
        }}
      >
        {children}
      </div>
    </div>
  )

  const DistributionPlot = ({ label }: any) => {
    const data = state_selected_survey.filter(e => e === 'All').length > 0
      ? state_metrics
      : state_metrics.filter((metric: any) => state_selected_survey.filter(e => e === metric.id).length > 0)

    if (state_metrics_loading) {
      return (<div>Loading</div>)
    }

    if (data.length === 0) {
      return (<div>No {label}</div>)
    }
     
    return <DensityPlot data={data.sort((a, b) => {
      const ord = (a.date_sent && b.date_sent) ? a.date_sent.localeCompare(b.date_sent) : a.programme_date_offset_days - b.programme_date_offset_days
      // sort surveys so that review is repeated in intervals
      if (ord === 0) {
        return b.review.localeCompare(a.review)
      }
      return ord
    })} showDifference={state_selected_difference} />
  }

  const ReasonPlot = ({ label, party }: any) => {
    const partydata = state_metrics.filter((m: any) => m.review === party)

    const data = state_selected_survey.filter(e => e === 'All').length > 0
      ? partydata
      : partydata.filter((metric: any) => state_selected_survey.filter(e => e === metric.id).length > 0)

    if (state_metrics_loading) {
      return (<div>Loading</div>)
    }

    if (data.length === 0) {
      return (<div>No {label}</div>)
    }
     
    return <ReasonDots data={data} />
  }

  return (
    <div
      style={{
        display: `flex`,
        flexDirection: 'column',
        justifyContent: `flex-start`,
        alignItems: 'stretch',
        padding: `3em`,
        width: '100%'
      }}
    >
      <Selector label='Change Plot Type' select={<PlotTypeSelector />} />
      { state_selected_plot !== 'Raw' &&
        <>
          <Selector label='Focus on a specific competency' select={<CompetencySelector />} />
          <Selector label='Focus on a specific survey' select={<SurveySelector />} />
        </>
      }
      { state_selected_plot === 'Distribution' &&
        <>
          <Selector label='Show survey differences' select={<DifferenceSelector />} />
        </>
      }

      { state_selected_plot === 'Raw' && <DownloadButton /> }
      { state_selected_plot === 'Distribution' && <DistributionGraph /> }
      { state_selected_plot === 'Reasons' && <ReasonsGraph />}
    </div>
  )
}