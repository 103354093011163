import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';

import { formatDate } from '../../../helpers/date';
import { useStyles } from '../../../theme/styles'
import Icon from '../Icon';

interface Option {
  id: string;
  value: string;
}

interface EditableFieldProps {
  size: 'field_large' | 'field_small';
  isEditing: boolean;
  label: string;
  value: any;
  onToggleEdit: () => void;
  onSave: () => void;
  onDelete?: () => void;
  onCancel: () => void;
  onChange: (value: string) => void;
  type?: 'input' | 'select' | 'date';
  options?: Option[];
  validation?: () => boolean;
  disabled?: boolean;
  hideLabelInViewMode?: boolean;
}

const EditableField = ({
  isEditing,
  label,
  value,
  onChange,
  onCancel,
  onToggleEdit,
  onSave,
  onDelete,
  validation,
  options = [],
  type = 'input',
  size,
  disabled,
  hideLabelInViewMode
}: EditableFieldProps) => {
  const classes = useStyles();


  const getDisplayValue = useCallback(() => {
    if (type === 'input') {
      return value;
    }

    if (type === 'date') {
      return formatDate(value);
    }

    const selected = options.filter(opt => opt.id === value)[0];
    return selected ? selected.value : '';
  }, [options, type, value]);

  const displayValue = getDisplayValue();
  const isValid = !validation || (typeof validation === 'function' && validation() === true);

  return (
    <Grid container>
      <Grid item xs={9}>
        {isEditing && type === 'input' && (
          <TextField
            label={label}
            value={displayValue}
            className={classes.input}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        {isEditing && type === 'date' && (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              className={classes.input}
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              value={value}
              onChange={(date: any) => onChange(date.local().utc(true).startOf('day'))}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk
            />
          </MuiPickersUtilsProvider>
        )}

        {isEditing && type === 'select' && (
          <FormControl className={classes.input}>
            <InputLabel>{label}</InputLabel>
            <Select
              value={value}
              onChange={(e: any) => onChange(e.target.value)}
            >
              {options.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {!isEditing && (
          <>
            <div className={classes[size]}>{displayValue}</div>
            <div className={classes.tag}>{hideLabelInViewMode ? "" : label}</div>
          </>
        )}
      </Grid>
      <Grid item xs={3}>
        <div className={classes.iconContainer}>
          {isEditing && (
            <div>
              <span onClick={onCancel} className={classes.cancelIcon}>
                <Icon icon="times" />
              </span>
              {isValid && (
                <span onClick={onSave}>
                  <Icon icon="save" />
                </span>
              )}
              {!isValid && (
                <span className={classes.failedValidation} onClick={() => alert('validation failed. unable to save')}>
                  <Icon icon="exclamation" />
                </span>
              )}
            </div>
          )}

          {!isEditing && !disabled && (
            <div>
              <span onClick={onToggleEdit}>
                <Icon icon="pencil" />
              </span>
              {onDelete ? <span onClick={onDelete}>
                &nbsp;<Icon icon="ban" />
              </span> : null}

            </div>


          )}
          {!isEditing && disabled && (
            <div>
              <span>

              </span>
            </div>
          )}

        </div>
      </Grid>
    </Grid>
  );
};

export default EditableField;
