import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal';
import { saveClient, deleteClientById, createClient as createNewClient } from '../../../../../api/client';
import { getAllProgrammes } from '../../../../../api/programme';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Icon from '../../../../Common/Icon';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Client } from '../../../../../types';


const customStyles = {
  content: {
    width: '30%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const useStyles = makeStyles(() => ({

  input: {
    width: '100%'
  },
  form_button: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
    marginRight: 10,
    textAlign: 'center',
    width: 100,
    cursor: 'pointer'
  },
  delete_button: {
    background: 'red',
  },
}));

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  onDataChange: () => void;
  client: Client;
}

const EditClient = ({ isModalOpen, closeModal, client, onDataChange }: Props) => {
  const history = useHistory();
  const isValid = (client: any) => {
    if (client === undefined || client.name === undefined || client.name == "") {
      return false;
    }

    return true;
  }
  const [updateClient, setUpdatedClient] = useState<Client>(client)

  useEffect(() => {
    setUpdatedClient(client);

  }, [client])
  const classes = useStyles();
  function afterOpenModal() {
    //noop
  }

  const save = () => {
    if (!isValid(updateClient)) {
      return;
    }

    if (client.id && client.id !== "") {
      return saveClient(updateClient)
        .then(() => onDataChange())
        .then(() => {
          return closeModal();
        })
    } else {
      return createNewClient(updateClient)
        .then(() => onDataChange())
        .then(() => {
          return closeModal();
        })
    }
  }

  const deleteClient = () => {
    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this client?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true
    }).then((choice: any) => {
      if (choice.isDismissed) {
        //noop
      } else if (choice.isConfirmed) {
        getAllProgrammes()
          .then(programmes => {
            if (programmes.filter((p: any) => p.client === client.id).length > 0) {
              Swal.fire({
                title: 'Validation Notice',
                text: "Cannot delete client that's associated with a programme",
                icon: 'info',
                confirmButtonText: 'OK',
                showCancelButton: false
              })
              return;
            } else {
              deleteClientById(updateClient.id)
                .then(() => onDataChange())
                .then(() => {
                  return closeModal();
                })
            }
          })

      }
    })

  }


  return (

    <Modal
      isOpen={isModalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Manage Client"
    >

      <h2>Client</h2>
      <Grid container
        direction="column"
        justify="center"
      >
        <Grid item xs={9}>
          <TextField
            label="name"
            value={updateClient.name}
            className={classes.input}
            onChange={(e) => {
              e.persist();
              if (updateClient) {
                setUpdatedClient({ ...updateClient, name: e.target.value })
              }
            }
            }
          />
        </Grid>


      </Grid>
      <Grid container
        direction="row"
      >

        <div className={`${classes.form_button}`} onClick={save}>
          Save {isValid(updateClient) ? <Icon icon="edit" /> : <Icon icon="exclamation" />}
        </div>
        {updateClient.id && (
          <div className={`${classes.form_button} ${classes.delete_button}`} onClick={deleteClient}>
            Delete <Icon icon="trash-alt" />
          </div>
        )}

        <div className={classes.form_button} onClick={() => {
          closeModal();
        }}>
          Cancel
            </div>
      </Grid>

    </Modal>

  )
};

export default EditClient;
