import { api } from '.';
import { Option } from '../types';
import { query_params } from '../helpers/api';

export const getOptionById = (optionId: string) =>
  api.get(`/option/${optionId}`).then(response => response.data);

export const getOptionByIds = (optionIds: string[]) =>
  Promise.all(optionIds.map(oId => getOptionById(oId)))

export const getOptionByQuestion = (questionId:string) => 
    api.get(`/option/${query_params({question:questionId})}`)
    .then((response : any) => response.data.data.sort((e1:Option,e2:Option) => {
      if(e1.order && e2.order && e1.order!==e2.order){
        return e1.order > e2.order ? 1 : -1    
      }
      return e1.id > e2.id ? 1 : -1 
    }))
  

/** @Todo Add pagination params */
export const getOptionList = () =>
  api.get('/option').then((response : any) => response.data)

export const saveOption = (option: any) =>
  api.put(`/option`, option).then(response => response.data);

export const createOption = (option: any) =>
  api.post(`/option`, option).then(response => response.data);

export const deleteOptionById = (optionId: string) =>
  api.delete(`/option/${optionId}`).then(response => optionId);
