import 'rc-slider/assets/index.css'
import React from 'react';
import Slider from 'rc-slider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Handle = Slider.Handle;

class PreviousAnswersSlider extends React.Component {
    constructor(props) {
        super(props);
    }

    stylePrevAnswersHandle = (props) => {

        const { value, dragging, index, ...restProps } = props;
        const marks = this.props.marks
        const answer = marks.length > 0 ? marks[index] : ''
        const colour = answer.colour
        const theme = createMuiTheme({
            overrides: {
                MuiTooltip: {
                    tooltip:
                    {
                        backgroundColor: colour,
                        fontSize: "0.6em",
                        marginBottom: "0px",
                        marginTop: "0px",
                        paddingBottom: "1px",
                        paddingTop: "1px"
                    }
                }
            }
        })

        return (
            <MuiThemeProvider theme={theme} key={answer.date}>
                <Handle
                    value={answer.level}
                    {...restProps}
                    style={{
                        borderColor: 'transparent',
                        height: 10,
                        width: 10,
                        marginLeft: 0,
                        marginTop: 0,
                        backgroundColor: colour
                    }} />
            </MuiThemeProvider>
        );
    };

    render() {
        const { value, date, self } = this.props;
        return (
            <div>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={4} style={{ fontSize: "12px", wordBreak: "break-word" }}>
                        {(self ? 'Self assessment ' : 'Your assessment ') + date}
                    </Grid>
                    <Grid item xs={8}>
                        <Slider
                            value={value}
                            handle={this.stylePrevAnswersHandle}
                            railStyle={{ backgroundColor: '#E9E9E9', height: 10 }}
                            trackStyle={[{ backgroundColor: '#E9E9E9', height: 10 }]}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default PreviousAnswersSlider;