import { api } from '.';
import { Competence } from '../types';
import { query_params } from '../helpers/api';

export const queryCompetences = (filter?: any, sort: any = { name: 1 }, cursor?: any) : Promise<Competence[]> =>
  api.get(`/competence/${query_params(filter, sort, cursor)}`).then(response => response.data.data)

export const findCompetenceById = (competences: Competence[], id?: string) => {
  if (!id) {
    return null;
  }

  return competences.find(competence => competence.id === id);
};

export const getCompetenceById = (competenceId: string) =>
  api.get(`/competence/${competenceId}`).then(response => response.data);


export const getCompetenceList = () =>
  api.get('/competence').then((response : any) => response.data.data)

export const getCompetenceListByStatus = (status: string) => 
  api.get(`/competence/${query_params({status})}`).then((response : any) => response.data.data)
  
export const saveCompetence = (competence: any) =>
  api.put(`/competence`, competence).then(response => response.data);

export const createCompetence = (competence: any) =>
  api.post(`/competence`, competence).then(response => response.data);

export const deleteCompetenceById = (competenceId: string) => 
  api.delete(`/competence/${competenceId}`).then((response : any) => response.data)
