import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Attendee } from '../../../../../types';
import AttendeeRow from './AttendeeRow';
import EditAttendee from './EditAttendee'
import Icon from '../../../../Common/Icon';
import { useStyles } from '../../../../../theme/styles';
import { findAttendeesByProgrammeId, createNew as createAttendee } from '../../../../../api/attendee';
import { createNew as createReviewer } from '../../../../../api/reviewer';
import _, { filter } from 'lodash';

interface AttendeesProps {
  disabled: boolean;
  programme_id: string
};

const  Attendees = ({ disabled, programme_id }: AttendeesProps) => {
  const [attendees, setAttendees] = useState<Attendee[]>([])
  const loadAttendees = () => {
    findAttendeesByProgrammeId(programme_id).then(setAttendees)
  }
  useEffect(loadAttendees, [programme_id])

  const [editingAttendee, setEditingAttendee] = useState<Attendee | undefined>(undefined);
  const toggleEditAttendee = (attendee: Attendee | undefined) => {
    setEditingAttendee(attendee)
  }

  const [importing, setImporting] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const showError = (error: string) => {
    setError(error)
    setTimeout(() => setError(''), 3000)
  }

  const parseAttendeeReviewers = (text: string, newLine:string, delimeter:string) =>
    text.split(newLine)
      .filter(row => {
      
        if(!row || row.trim() === '' || row.includes('Attendee Email')){
            console.log('Skipping row:', row)
            return false;
        }else{
          return true;
        }


      })
      .map(row => {
        const [name, email, ...reviewer_columns] = row.split(delimeter)
        if (name === '' || email === '') {
          throw new Error(`Attendee data missing - check that you haven't copied any empty cells`)
        }
        return [
          { name, email, programme: programme_id },
          ...reviewer_columns.reduce((reviewers, v, i) => {
            // if (v === '') {
            //   throw new Error(`Reviewer data missing - check that you haven't copied any empty cells`)
            // }
            return i % 2 === 1
              ? [...reviewers,
              ...(v === '' ? [] : [{
                name: reviewer_columns[i - 1],
                email: v
              }])]
              : reviewers
          },
            [] as Array<{ name: string, email: string }>)
        ]
      })

const importAttendeedAndReviewersFromCsv = async (e:any) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target !== null ? (e.target.result) : "Null")
      console.log(text)   
      if(text===null) return;
      if(text instanceof ArrayBuffer){
        importAttendeedAndReviewersFromCsvString(new TextDecoder().decode(text), '\n', ',')
      }else{
        importAttendeedAndReviewersFromCsvString(text, '\n', ',');
      }
      
    };
    reader.readAsText(e.target.files[0])
    
  }

  const importAttendeedAndReviewers = async (e: any) => {
    const text = await navigator.clipboard.readText()
    return importAttendeedAndReviewersFromCsvString(text, '\n', '\t')
  };

const importAttendeedAndReviewersFromCsvString = async (text:string, lineEnd:string, delimiter:string) => {
    if (importing) return
    setImporting(true)
    try {
      const attendee_reviewers_list = parseAttendeeReviewers(text, lineEnd, delimiter)
      await Promise.all(attendee_reviewers_list.map(async ([attendee, ...reviewers]) => {
        console.log('Creating attendee', attendee, reviewers)
        const new_attendee = await createAttendee(attendee)
        return Promise.all(reviewers
              .filter(reviewer => reviewer.email && reviewer.email!=='' && reviewer.email!=='\r')
              .map(reviewer => createReviewer({
            ...reviewer,
            attendee: new_attendee.id
          })))
      }))
      setImporting(false)
      loadAttendees()
    }
    catch (error) {
      console.error('import error', error)
      showError(`Failed to paste attendees from clipboard : ${error}`)
      setImporting(false)
      loadAttendees()
    }
  };

  const classes = useStyles()

  return (
    <>
      <EditAttendee
        isModalOpen={!disabled && editingAttendee !== undefined}
        closeModal={() => {
          setEditingAttendee(undefined);
          loadAttendees();
        }}
        attendee={editingAttendee ? editingAttendee : {}}
      />
      <Grid container>

        {!disabled && <>
          <Grid item xs={2}>
            <div className={classes.addNewAttendeeButton} onClick={() => setEditingAttendee({ programme: programme_id })}>
              <Icon icon="plus" /> Attendee
            </div>
          </Grid>
          <Grid item xs={2}>
            <> 
            <Tooltip title={<div>Select and copy from spreadsheet arranged:<br />
            Attendee Name, Attendee Email, Reviewer Name, Reviewer Email, Reviewer Name, Reviewer Email, ...<br />
            Attendee Name, Attendee Email, Reviewer Name, Reviewer Email, Reviewer Name, Reviewer Email, ...<br />
            ...</div>} >
              <div className={classes.addNewAttendeeButton} onClick={importAttendeedAndReviewers}>
                <Icon icon="angle-double-right" /> Import from clipboard
            </div>
           
            </Tooltip>
          <div className={classes.addNewAttendeeButton}>
                  <input type="file"  accept=".csv" onChange={importAttendeedAndReviewersFromCsv}/>
                 <Icon icon="angle-double-right" /> Import from CSVs
            </div></>
          </Grid>
          {error !== '' &&
            <Grid item xs={6}>
              <Card
                className={classes.error}
                variant='outlined'
                key={error}
              >
                <CardContent
                  className={classes.error}
                >
                  <Typography>
                    {error}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          }
        </>
        }

        {attendees.map(attendee =>
          <AttendeeRow
            attendee={attendee}
            key={attendee.id}
            disabled={disabled}
            toggleEditAttendee={toggleEditAttendee}
          />
        )}
      </Grid>
    </>
  );
};

export default Attendees;
