import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { EmailSchema, SurveySchema } from '../../../../types'
import { useStyles } from '../../../../theme/styles'

interface Props {
  surveys: SurveySchema[]
  emails: EmailSchema[]
}

const no_self = (emails: EmailSchema[]) => {
  return undefined === emails.find(email => email.recipient_group === 'All Attendees')
    ? ['Surveys must be sent to All Attendees']
    : []
}

const no_reviewer = (emails: EmailSchema[]) => {
  return undefined === emails.find(email => email.recipient_group === 'All Reviewers')
    ? ['Surveys must be sent to All Reviewers']
    : []
}

const reviewer_before_self = (emails: EmailSchema[]) => {
  const earliest_self = emails.find(email => email.recipient_group === 'All Attendees')
  const earliest_reviewer = emails.find(email => email.recipient_group === 'All Reviewers')
  return earliest_reviewer !== undefined &&
    earliest_self !== undefined &&
    earliest_reviewer.survey_date_offset_days - earliest_self.survey_date_offset_days < 7
    ? ['Attendee should be given at least 7 days to respond before surveying Reviewer']
    : []
}

const late_before_all = (group: string) => (emails: EmailSchema[]) => {
  const earliest_all = emails.find(email => email.recipient_group === `All ${group}`)
  const earliest_late = emails.find(email => email.recipient_group === `Late ${group}`)
  return earliest_all !== undefined &&
    earliest_late !== undefined &&
    earliest_late.survey_date_offset_days - earliest_all.survey_date_offset_days < 3
    ? [`All ${group} should be given at least 3 days to respond before sending chaser Late ${group}`]
    : []
}
const late_before_all_self = late_before_all('Attendees')
const late_before_all_reviewer = late_before_all('Reviewers')

const summary_before_reviewer = (emails: EmailSchema[]) => {
  const earliest_summary = emails.find(email => email.recipient_group === 'Programme Admin')
  const earliest_reviewer = emails.find(email => email.recipient_group === 'All Reviewers')
  return earliest_reviewer !== undefined &&
    earliest_summary !== undefined &&
    earliest_summary.survey_date_offset_days - earliest_reviewer.survey_date_offset_days < 7
    ? ['Reviewers should be given at least 7 days to respond before sharing a Summary']
    : []
}

const escalation_before_reviewer = (emails: EmailSchema[]) => {
  const earliest_escalation = emails.find(email => email.recipient_group === 'Escalation')
  const earliest_reviewer = emails.find(email => email.recipient_group === 'All Reviewers')
  return earliest_reviewer !== undefined &&
    earliest_escalation !== undefined &&
    earliest_escalation.survey_date_offset_days - earliest_reviewer.survey_date_offset_days < 7
    ? ['Reviewers should be given at least 7 days to respond before escalating to managers']
    : []
}

const survey_overlap = (surveys: SurveySchema[]) => {
  return surveys.find((survey, index) => index > 0 &&
    survey.programme_date_offset_days - surveys[index - 1].programme_date_offset_days < surveys[index - 1].expires_after_days * 2
  ) !== undefined
  ? ['The previous surveys last responses could be closer to this survey, consider either reducing the "Days to respond" for preceeding survey, or increasing the "Days after start of programme" of this survey, otherwise the results of surveys will blur']
  : []
}

const email_after_expiry = (surveys:SurveySchema[], emails:EmailSchema[]) => {
  return surveys.find(survey => 
    emails.find(email =>
      email.survey_date_offset_days > survey.expires_after_days
    ) !== undefined
  ) !== undefined
  ? [`Email scheduled after survey expiry`]
  : []
}

export const Validations: FC<Props> = ({ emails, surveys }: Props) => {
  const classes = useStyles()

  const errors = [
    ...no_self(emails),
    ...no_reviewer(emails),
    ...reviewer_before_self(emails),
    ...late_before_all_self(emails),
    ...late_before_all_reviewer(emails),
    ...survey_overlap(surveys),
    ...summary_before_reviewer(emails),
    ...escalation_before_reviewer(emails),
  ]

  return (
    <Grid item xs={12}>
      {
        errors.map(error =>
          <Card
            className={classes.error}
            variant='outlined'
            key={error}
          >
            <CardContent
              className={classes.error}
            >
              <Typography>
                {error}
              </Typography>
            </CardContent>
          </Card>
        )
      }
    </Grid>
  )
}
