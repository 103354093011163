import { createMuiTheme } from '@material-ui/core/styles';

const defaultThemeConfig = {
  colours: {
    opensideBlue: '#26377B',
    white: '#FFFFFF',
    darkGray: '#828282',
    lightGray: '#EEE',
    gray: '#828282'
  }
}

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      main: defaultThemeConfig.colours.opensideBlue,
    },
    secondary: {
      main: defaultThemeConfig.colours.gray,
    },
  }
});

export {
  defaultThemeConfig as default,
  theme as opensideTheme
}

