import React, { useState } from 'react'

type Props = {
    surveys: any[],
    selectedSurvey?: any,
    surveySelectedCallback: (surveyId: string) => void
}

const SurveySelector:React.FC<Props> = ({ surveys, selectedSurvey, surveySelectedCallback}) => {
    
    const onSurveySelected = (surveyId: string) => {
        surveySelectedCallback(surveyId)
    }
    return (
        <select
            value={selectedSurvey?.id}
            onChange={(e) => onSurveySelected(e.currentTarget.value)}
            size={surveys.length}
        >
        {surveys.map(s => (
            <option
                key={s.id}
                value={s.id}>
                {s.name}
            </option>
        ))}
        </select>

    )
}

export default SurveySelector