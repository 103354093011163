import { api } from '.';
import { Email } from '../types';
import { query_params } from '../helpers/api';

export const getEmailListByProgramme = (programmeId: string) =>
    api.get(`/email/${query_params({programme:programmeId})}`)
    .then((response : any) => response.data.data.sort((e1:Email,e2:Email) => {
      const d1 = e1.date_sent || e1.date_scheduled
      const d2 = e2.date_sent || e2.date_scheduled
      return d1 < d2 ? -1 :
             d2 < d1 ? 1 :
             e1.id < e2.id ? -1 :
             e2.id < e1.id ? -1 :
             0
    }))

export const getShowEmail = (emailId: string) =>
  api.get(`/email/${emailId}`).then(response => response.data)

export const updateEmail = (email: Email) =>
  api.put(`/email`, email).then(response => response.data)

export const createEmail = (email: any) =>
  api.post(`/email`, email).then(response => response.data);

export const deleteEmailById = (emailId: string) =>
  api.delete(`/email/${emailId}`).then(response => response.data)


