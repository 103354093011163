import React, { FC, useMemo } from 'react'
import { SurveyUrl } from "src/types"
import { useTable } from 'react-table';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    table: {
        border: '1px solid black',
        borderSpacing: 0,
    },
    cell: {
        padding: '0.5rem',
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
        wordBreak: 'break-word'
    },
  }
));


type props = {
    data: SurveyUrl[]
}

export const SurveyUrlsTable:FC<props> = ({ data }) => {
    const classes = useStyles();

    const columns: {
        Header: string;
        accessor: 'survey' | 'reviewerEmail' | 'reviewerName' | 'attendeeName' | 'url' | 'status';
      }[] = useMemo(
        () => [
          {
            Header: 'Survey',
            accessor: 'survey',
          },
          {
            Header: 'Attendee Name',
            accessor: 'attendeeName',
          },
          {
            Header: 'Reviewer Email',
            accessor: 'reviewerEmail',
          },
          {
            Header: 'Reviewer Name',
            accessor: 'reviewerName',
          },
          {
            Header: 'URL',
            accessor: 'url',
            Cell: (props: any) => <a href={props.value} target="blank">{props.value}</a> 
          },
          {
            Header: 'Status',
            accessor: 'status',
          }
        ],
        []
      );
    
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
        useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ['id'],
            },
        });
    
    return (
        <table {...getTableProps()} className={classes.table}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getFooterGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} className={classes.cell}>
                                {column.render('Header')}
                            </th>
                        ))}
                        <th className={classes.cell}/>
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <td
                                    {...cell.getCellProps()}
                                    className={classes.cell}
                                >
                                    {cell.render('Cell')}
                                </td>
                            ))}
                            <td className={classes.cell}>
                                <button onClick={() => navigator.clipboard.writeText(row.values['url'])}>
                                    Copy to clipboard
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
    
}
