import React, { useEffect, useState, FC } from 'react'
import Grid from '@material-ui/core/Grid'

import { useStyles } from '../../theme/styles'
import { formatDate } from '../../helpers/date'
import { Client, Email, Programme } from '../../types'
import { useNavigation } from '../../helpers/navigation'
import { getClientById } from '../../api/client'
import { getShowEmail } from '../../api/email'

interface Props {
  programme: Programme;
};

export const DraftProgrammeRow : FC<Props> = ({ programme }: Props) => {
  const classes = useStyles();

  const [client, setClient] = useState<Client | undefined>(undefined)
  const load_client = () => {
    if (programme.client !== undefined) {
      getClientById(programme.client)
        .then(setClient)
    }
  }
  useEffect(load_client, [programme.client])

  const [next_email, setNextEmail] = useState<Email | undefined>(undefined)
  const load_next_email = () => {
    if (programme.next_email !== undefined) {
      getShowEmail(programme.next_email)
        .then(setNextEmail)
    }
  }
  useEffect(load_next_email, [programme.next_email])

  const goToProgrammeDetails = useNavigation(`/programme/${programme.id}`)

  return (
    <>
      <Grid container className={classes.grid} onClick={goToProgrammeDetails}>
        <Grid item xs={4}>
          {programme.title ?? '-'}
          <div className={classes.tag}>Title</div>
        </Grid>

        <Grid item xs={3}>
          {client ? client.name : '-'}
          <div className={classes.tag}>Client</div>
        </Grid>

        <Grid item xs={2}>
          {formatDate(programme.start)}
          <div className={classes.tag}>Start Date</div>
        </Grid>

        <Grid item xs={2}>
          {next_email ? next_email.alias : '-'}
          <div className={classes.tag}>Next Email {next_email?.date_scheduled ? `- ${formatDate(next_email.date_scheduled)}` : ''}</div>
        </Grid>
      </Grid>
    </>
  );
};
