import React, { FC, useState } from 'react'
import * as d3 from 'd3'
import { Sample } from '../../../../types'
interface Props {
  data: Sample[],
  showDifference: string
}

const textSize = 14

const XAxis = ({ xScale, yScale, color, label }: any) => {
  return (
    <>
      <path
        d={`M ${xScale.range()[0]} ${yScale.range()[1]} H ${xScale.range()[1]}`}
        stroke={color} />
      <text
        style={{
          fontSize: `${textSize}px`,
          textAnchor: "middle",
          transform: `translate(${(xScale.range()[1] / 2) + xScale.range()[0]}px,${yScale.range()[1] + 35}px)`
        }}>
        {label}
      </text>
      {xScale.ticks(5).map((tick: number) => (
        <g
          key={tick}
          transform={`translate(${xScale(tick)}, ${yScale.range()[1]})`}
        >
          <line
            y2="6"
            stroke="currentColor"
          />
          <text
            key={tick}
            style={{
              fontSize: `${textSize}px`,
              textAnchor: "middle",
              transform: "translateY(20px)"
            }}>
            {tick}
          </text>
        </g>
      ))}
    </>
  )
}

const YAxis = ({ xScale, yScale, color, label }: any) => {
  return (
    <>
      <path
        d={`M ${xScale.range()[0]} ${yScale.range()[1]} V ${yScale.range()[0]}`}
        stroke={color} />
      <text
        style={{
          fontSize: `${textSize}px`,
          textAnchor: "middle",
          transform: `translate(${xScale.range()[0] - textSize - 20}px,${(yScale.range()[1] / 2) + yScale.range()[0]}px) rotate(270deg)`
        }}>
        {label}
      </text>
      {yScale.ticks(5).map((tick: number) => (
        <g
          key={tick}
          transform={`translate(${xScale.range()[0]}, ${yScale(tick)})`}
        >
          <line
            x2="-6"
            stroke="currentColor"
          />
          <text
            key={tick}
            style={{
              fontSize: `${textSize}px`,
              textAnchor: "middle",
              transform: "translateX(-20px)"
            }}>
            {tick}
          </text>
        </g>
      ))}
    </>
  )
}

export const DensityPlot: FC<Props> = ({ data, showDifference }: Props) => {
  const max_count = d3.max(data.map(survey => d3.max(survey.histogram) as number)) as number
  const survey_count = data.length
  const xdomain = [0, 100] //slider range
  const ydomain = [max_count, 0] // y axis is upsidedown because svg starts from top left

  const [size, margin] = [1110, 50]
  const viewBoxSize = 1350
  const plotRange = [margin, size - margin]

  const xScale = d3.scaleLinear()
    .domain(xdomain)
    .range(plotRange)
  const yScale = d3.scaleLinear()
    .domain(ydomain)
    .range(plotRange)
  const yScale0: any = yScale(0)
  const yScaleMax: any = yScale(max_count)
  const colour = d3.scaleOrdinal()
    .domain(data.map(d => `${d.name} - ${d.review}`))
    .range(d3.schemeCategory10)

  const line = d3.line().curve(d3.curveBundle.beta(1))

  const plot = (histogram: number[]) => {//easy to change mean to median
    const bins = histogram.length
    const step = 100 / bins
    const midbinoffset = step / 2
    // rescal indexes so that [0] => [50] [0,1,2,3,4] => [10,30,50,70,90]
    const points = histogram.map((occurs, i) => [xScale(i * step + midbinoffset), yScale(occurs)])
    return line(points as [number, number][]) as string
  }

  const change = (data: any, index: number) => {
    const changeNumeric = ((data[index + 2].median / data[index].median) * 100 - 100)
    if (changeNumeric > 0) {
      return "+" + changeNumeric.toFixed(2) + "%"
    } else {
      return changeNumeric.toFixed(2) + "%"
    }
  }

  const calculateArrow = (data: any, index: number) => {
    if (data[index].median > data[index + 2].median) {
      return `M ${xScale(data[index].median)} ${yScaleMax + (index * 24) + 30} H ${xScale(data[index + 2].median + 0.5)}`
    }
    return `M ${xScale(data[index].median)} ${yScaleMax + (index * 24) + 30} H ${xScale(data[index + 2].median - 0.5)}`
  }

  const calculateArrowLabelPlacementEarlierSurvey = (data: any, index: number) => {
    if (data[index].median > data[index + 2].median) {
      return `translate(${xScale(data[index].median + 0.5)}px, ${yScaleMax + (index * 24) + 30}px)`
    }
    return `translate(${xScale(data[index].median - 0.5)}px, ${yScaleMax + (index * 24) + 30}px)`
  }

  const calculateArrowLabelPlacementLaterSurvey = (data: any, index: number) => {
    if (data[index].median > data[index + 2].median) {
      return `translate(${xScale(data[index + 2].median - 0.5)}px, ${yScaleMax + (index * 24) + 30}px)`
    }
    return `translate(${xScale(data[index + 2].median + 0.5)}px, ${yScaleMax + (index * 24) + 30}px)`
  }

  const labelAnchorStart = (data: any, index: number) => {
    if (data[index].median > data[index + 2].median) {
      return false
    }
    return true
  }

  return (<div>
    <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`} width={size} height={size}>
      {data.map(({ name, median: ave, histogram, review }, i) => {
        const diff = data.length > i + 2 ? Math.abs(data[i].median - data[i + 2].median) : 0
        const arrowWidth = diff > 2 ? 10 : 5
        return <g key={i}>
          <path
            key={`${i}-distribution`}
            d={plot(histogram)}
            stroke={colour(`${name} - ${review}`) as string}
            fill='none'
          />
          <path
            key={`${i}-average`}
            d={`M ${xScale(ave)} ${yScale(max_count)} V ${yScale(0)}`}
            stroke={colour(`${name} - ${review}`) as string}
            strokeDasharray='5,5'
            fill='none'
          />
          {showDifference === 'None' && (<text
            key={`${i}-label`}
            style={{
              fontSize: `${textSize}px`,
              textAnchor: xScale(ave)! > (size / 2) ? 'end' : 'start',
              transform: `translate(${xScale(ave)}px, ${yScale0 + (i - survey_count) * 24}px)`,
              fill: colour(`${name} - ${review}`) as string
            }}
          >
            {name} - {review}
          </text>)}

          {showDifference !== 'None' && ((i + 2) < data.length) && (
            <>
              {(data[i].median !== data[i + 2].median)
                && (
                  <>
                    <path
                      key={`${i}-link`}
                      d={calculateArrow(data, i)}
                      stroke={colour(`${name} - ${review}`) as string}
                      fill='none'
                      markerEnd={`url(#arrowhead${i})`}
                    />
                    <marker
                      id={`arrowhead${i}`}
                      refX={arrowWidth - 3}
                      refY="3"
                      markerWidth={arrowWidth}
                      markerHeight="6"
                      orient="auto"
                      fill={colour(`${name} - ${review}`) as string}
                    >
                      <polygon points={`0 0, ${arrowWidth} 3, 0 6`} />
                    </marker>
                  </>
                )
              }
              <text
                key={`${i}-label`}
                style={{
                  fontSize: `${textSize}px`,
                  textAnchor: labelAnchorStart(data, i) ? 'end' : 'start',
                  alignmentBaseline: "middle",
                  transform: calculateArrowLabelPlacementEarlierSurvey(data, i),
                  fill: colour(`${name} - ${review}`) as string
                }}
              >
                {name} - {review}
              </text>
              <text
                key={`${i}-label`}
                style={{
                  fontSize: `${textSize}px`,
                  textAnchor: !labelAnchorStart(data, i) ? 'end' : 'start',
                  alignmentBaseline: "middle",
                  transform: calculateArrowLabelPlacementLaterSurvey(data, i),
                  fill: colour(`${data[i + 2].name} - ${data[i + 2].review}`) as string
                }}
              >
                {(`${change(data, i)} ${data[i + 2].name} - ${data[i + 2].review}`)}
              </text>
            </>
          )}
        </g>
      })}
      <XAxis xScale={xScale} yScale={yScale} color='currentColor' label='Assessment score' />
      <YAxis xScale={xScale} yScale={yScale} color='currentColor' label='Responses' />
    </svg>
  </div >
  )
}