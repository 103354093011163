import React, { useEffect, useState, FC } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Setting } from '../../../../types'
import { useStyles } from '../../../../theme/styles'
import { list, update } from '../../../../api/config_setting'

interface Props {
  setting: Setting
}

const SettingRow: FC<Props> = ({ setting }: Props) => {
  const [value, setValue] = useState<string>(setting.value)

  const save = () => {
    update({
      ...setting,
      value
    })
      .then(result => { setValue(result.value) })
  }

  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={1}/>
        <Grid item xs={7}>
          <TextField
            label={setting.name}
            value={value}
            className={classes.input}
            onChange={(e) => setValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={1}>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => setValue(setting.value)}
            disabled={value === setting.value}
          >Undo</Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            color='primary'
            variant='contained'
            onClick={save}
            disabled={value === setting.value}
          >Save</Button>
        </Grid>
        <Grid item xs={1}/>
      </Grid>
    </Grid>
  )
}

export const ConfigSettings: FC = () => {
  const [settings, setSettings] = useState<Setting[]>([])
  const reload = () => {
    list()
      .then(setSettings)
  }
  useEffect(reload, [])

  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          className={classes.description}
        >
          Manage system settings with extreme care!
        </Typography>
      </Grid>
      {
        settings.map(setting => <SettingRow setting={setting} key={setting.id} />)
      }
    </Grid>
  )
}