import { makeStyles } from '@material-ui/core/styles';
import theme from './';

export const useStyles = makeStyles(() => ({
  actionButton: {
    color: theme.colours.opensideBlue,
    border: 'none',
    verticalAlign: 'middle',
    textAlign: 'center',
    padding: '16px 16px',
    justifySelf: 'end',
    width: '100px',
    cursor: 'pointer'
  },
  actionButtonContainer: {
    float: 'right',
    maxWidth: 250,
    display: 'flex'
  },
  actions_container: {
    fontSize: 14,
    fontWeight: 300,
    position: 'absolute',
    padding: 5,
    bottom: 0,
    right: 0,
    paddingRight: 10,
  },
  addNewAttendeeButton: {
    background: '#26377B',
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
    marginRight: 15,
    color: '#FFFFFF',
    cursor: 'pointer'
  },
  area: {
    background: theme.colours.lightGray,
    color: theme.colours.darkGray,
    padding: 10,
    marging: 50
  },
  attendeeTitleSection: {
    marginBottom: 10
  },
  block: {
    background: theme.colours.opensideBlue,
    color: theme.colours.white,
    borderRadius: 10,
    padding: 10,
    textAlign: 'center',
    marginTop: 10,
    width: 200,
    height: 100,
    cursor: 'pointer',
    position: 'relative',
  },
  block_email: {
    background: theme.colours.gray,
    color: theme.colours.opensideBlue,
    borderRadius: 10,
    padding: 10,
    textAlign: 'center',
    marginTop: 10,
    width: 200,
    height: 100,
    cursor: 'pointer',
    position: 'relative',
  },
  cancelIcon: {
    marginRight: 5
  },
  competence: {
    background: theme.colours.lightGray,
    padding: 10
  },
  competence_preview: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  create_new_button: {
    background: theme.colours.opensideBlue,
    color: theme.colours.white,
    borderRadius: 10,
    paddingTop: 10,
    textAlign: 'center',
    marginTop: 40,
    width: 200,
    height: 30,
    cursor: 'pointer'
  },
  description: {
    background: theme.colours.lightGray,
    color: theme.colours.darkGray,
    fontSize: 24,
    overflowWrap: 'break-word',
    textAlign: 'center',
    margin: 40,
  },
  editDetailsButton: {
    background: theme.colours.opensideBlue,
    color: theme.colours.white,
    borderRadius: 10,
    padding: 10,
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  emailTitle: {
    fontSize: 16
  },
  error: {
    color: 'red',
    background: theme.colours.lightGray,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
  failedValidation: {
    color: 'red'
  },
  field: {
    fontSize: 24
  },
  field_large: {
    fontSize: 24,
    overflowWrap: 'break-word'
  },
  field_small: {
    fontSize: 14,
    overflowWrap: 'break-word'
  },
  footer: {
    marginTop: 200,
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "LightGray",
    color: "white",
  },
  formControl: {
    margin: 10,//theme.spacing(1),
    width: '90%'
  },
  grid: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEE',
    },
  },
  h1: { fontSize: '2em' },
  h2: { fontSize: '1.5em' },
  h3: { fontSize: '1.17em' },
  h4: { fontSize: '1.02em' },
  icon: {
    fontSize: 50
  },
  iconContainer: {
    color: theme.colours.opensideBlue,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  input: {
    width: '100%',
    overflowWrap: 'break-word'
  },
  label: {
    color: theme.colours.gray,
    fontSize: 12
  },
  main: {
    marginTop: 20
  },
  navbar: {
    "& button": {
      display: "none"
    }
  },
  progress: {
    margin: 0
  },
  root: {
    flexGrow: 1,
    margin: 20,
  },
  schedule_card_blue: {
    background: theme.colours.opensideBlue,
    borderRadius: 10,
    textAlign: 'center',
    color: theme.colours.white,
    height: 120
  },
  schedule_card_gray: {
    background: theme.colours.opensideBlue,
    borderRadius: 10,
    textAlign: 'center',
    color: theme.colours.white,
    opacity: 0.5,
    height: 120
  },
  section: {
    marginTop: 40
  },
  subHeading: {
    color: theme.colours.gray,
    fontSize: 18
  },
  submitSurveyButton: {
    margin: 10,
    backgroundColor: theme.colours.opensideBlue,
    color: theme.colours.lightGray
  },
  survey_statement: {
    marginTop: "40px",
    marginBottom: "55px",
    width: '100%',
    borderColor: theme.colours.opensideBlue,
  },
  survey_statement_header: {
    background: theme.colours.opensideBlue,
    color: theme.colours.white,
  },
  survey_statement_body: {
    background: theme.colours.lightGray,
    color: theme.colours.darkGray,
    display: 'flex',
    flexDirection: 'column',
    marginTop: "10px"
  },
  tag: {
    color: theme.colours.gray,
    fontSize: 14,
    fontWeight: 300
  },
  title: {
    fontSize: 35
  },
  value: {
    fontWeight: 'bold',
    fontSize: 24
  },
  window: {
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px"
  }
}));