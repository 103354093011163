import 'rc-slider/assets/index.css'
import React from 'react';
import Slider from 'rc-slider';

class NullableSlider extends React.Component {
    constructor(props) {
      super(props);
    }
      render()
    {
        const value = this.props.value === (undefined) ? null : this.props.value === (false) ? null : this.props.value
        return (
                <Slider
                    value={value}
                    onChange={this.props.handleValueChange}
                    aria-labelledby="continuous-slider"
                    trackStyle={{ backgroundColor: '#26377B', height: 10 }}
                    handleStyle={{
                        borderColor: '#26377B',
                        height: 26,
                        width: 26,
                        marginLeft: 0,
                        marginTop: -8,
                        backgroundColor: '#26377B',
                    }}
                    railStyle={{backgroundColor: '#E9E9E9', height : 10 }}/>
        )
    }
}

export default NullableSlider;