import React, { useEffect, useState, FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { StatementPreview } from './statement_preview'
import { Competence, Statement } from '../../../../types'
import { getQuestionByCompetence } from '../../../../api/question'
import { useStyles } from '../../../../theme/styles'
import Icon from '../../../Common/Icon';
import { StatementDialog } from './statement_dialog'

interface Props {
  competence: Competence
  openCompetence: (statements: Statement[]) => void
}

export const CompetencePreview: FC<Props> = ({ competence, openCompetence }: Props) => {
  const classes = useStyles()
  const [statements, setStatements] = useState<Statement[]>([])
  const [statementModal, setStatementModal] = useState<Statement | undefined>(undefined);
  const [updateCounter, setUpdateCounter] = useState(0)
  const update = () => setUpdateCounter(count => count + 1)

  const reload = () => {
    if (competence.id !== 'new') {
      getQuestionByCompetence(competence.id)
        .then(setStatements)
    }
  }
  useEffect(() => { reload() }, [competence.id])

  const closeStatementModal = () => {
    reload()
    setStatementModal(undefined)
  }

  return (
    <div className={classes.root}>{/*Survey*/}
      <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: '30px' }}>{/*Survey*/}
        <Grid xs={11} sm={7} md={7} lg={4} item>{/*Survey*/}
          <Paper className={classes.competence}>
            <div className={classes.competence_preview}
              onClick={() => openCompetence(statements)}
            >
              <Typography
                component="h2"
                className={classes.h3}
              >
                {competence.name}
              </Typography>
              <Chip
                label={competence.status}
              />
            </div>
            {statements.map(statement => (
              <StatementPreview
                key={statement.id}
                statement={statement}
                openStatement={() => setStatementModal(statement)}
                status={competence.status}
                refresh={updateCounter}
              />
            ))/*SurveyQuestion*/}
            {competence.id !== 'new' && competence.status === 'Draft' &&
              <Accordion
                className={classes.survey_statement}
              >
                <AccordionSummary
                  className={classes.survey_statement_header}
                  expandIcon={null}
                  onClick={(event: any) => {
                    event.stopPropagation()
                    setStatementModal({
                      id: 'new',
                      competence: competence.id,
                      self_review_text: ''
                    })
                  }}>
                  <Typography
                    component="h2"
                    className={classes.h3}
                  >
                    <Icon icon="plus" /> Statement
                  </Typography>
                </AccordionSummary>
              </Accordion>
            }
          </Paper>
        </Grid>
      </Grid>
      {statementModal &&
        <StatementDialog
        close={closeStatementModal}
        statement={statementModal}
        update={(statement: Statement) => { 
          setStatementModal(statement)
          update()
        }}
        />
      }
    </div>
  )
}
