import React, { useEffect, useState, FC } from 'react'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import * as EmailValidator from 'email-validator'

import { Client, User } from '../../../../types'
import { useStyles } from '../../../../theme/styles'
import { list, create, reset, remove } from '../../../../api/user'
import { getClientList } from '../../../../api/client';

import { MenuItem, Select } from '@material-ui/core';

export const Users: FC = () => {
  const [users, setUsers] = useState<User[]>([])
  const [clients, setClients] = useState<Client[]>([])
  const roles = [{key:'tc_admin', label:'Platform Admin'}, {key:'org_admin', label:'Org Admin'}]
  const reload = () => {
    getClientList()
    .then(setClients)
    .then(list)
    .then(setUsers)
  }
  useEffect(reload, [])
  const emptyUser = {username:'', roles:[] as string[], id:''}
  const [user, setUser] = useState<User>(emptyUser)
    
  const updateEmail = (event:any) => setUser({...user, username: event.target.value ? event.target.value as string : ''})
  const email_invalid = !EmailValidator.validate(user.username) 
  const [notify_text, set_notify_text] = useState<string>('')
  const close = ():void => set_notify_text('')

  const classes = useStyles()

  const delete_user = (user:User) => () => {
    set_notify_text('Busy')
    remove(user)
    .then(() => {
      set_notify_text(`${user.username} user has been deleted`)
      reload()
    })
    .catch(() => {
      set_notify_text('Oh! something went wrong')
    })
  }

  const reset_password = (user:User) => () => {
    set_notify_text('Busy')
    reset(user)
    .then(() => {
      set_notify_text(`An email with reset link has been sent to ${user.username}`)
    })
    .catch(() => {
      set_notify_text('Oh! something went wrong')
    })
  }

  const create_user = () => {
    set_notify_text('Busy')
    if(user){
      create(user.username, user.roles, user.org)
      .then(() => {
        set_notify_text(`An email with reset link has been sent to ${user.username}`)
        setUser(emptyUser)
        reload()
      })
      .catch(() => {
        set_notify_text('Oh! something went wrong')
      })
    }
  }
  console.log('---users', users)
  return (
    <Grid container spacing={2}>
      {users.map(user => 
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label='email'
                value={user.username}
                className={classes.input}
                disabled
              />
            </Grid>
            <Grid item xs={2}>
            <Select
                  disabled
                  label='Organisation'
                  value={user?.org ? user?.org : 'none'}
                  
                >
                  <MenuItem key={'none'} value={'none'}>{'None'}</MenuItem>
                  {clients.map((client: any) => (
                    <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                  ))}
                </Select>
            </Grid>
            <Grid item xs={2}>
            <Select
                  disabled
                  label='Role'
                  value={user.roles && user.roles.length>0 ? user.roles[0] : 'none'}
                >
                  <MenuItem key={'none'} value={'none'}>{'None'}</MenuItem>
                  {roles.map((role: any) => (
                    <MenuItem key={role.key} value={role.key}>{role.label}</MenuItem>
                  ))}
                </Select>
            </Grid>
            <Grid item xs={2}>
              <Button
                color='primary'
                variant='outlined'
                onClick={delete_user(user)}
              >Delete</Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                color='primary'
                variant='outlined'
                onClick={reset_password(user)}
              >Reset</Button>
            </Grid>
          </Grid>
        </Grid>
      )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label='email'
                value={user.username}
                className={classes.input}
                error={user.username !== '' && email_invalid}
                onChange={updateEmail}
              />
            </Grid>
             <Grid item xs={2}>
            <Select
                  label='Organisation'
                  value={user.org ? user.org : 'none'}

                  onChange={(e: any) => {
                    setUser({...user, org: e.target.value})
                  }
                  }
                >
                  <MenuItem key={'none'} value={'none'}>{'None'}</MenuItem>
                  {clients.map((client: any) => (
                    <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                  ))}
                </Select>
            </Grid>
          <Grid item xs={2}>
            <Select
                  label='Role'
                  value={user.roles && user.roles.length>0 ? user.roles[0] : 'none'}
                  onChange={(e: any) => {
                    setUser({...user, roles:[e.target.value]})
                  }
                  }
                >
                  <MenuItem key={'none'} value={'none'}>{'None'}</MenuItem>
                  {roles.map((role: any) => (
                    <MenuItem key={role.key} value={role.key}>{role.label}</MenuItem>
                  ))}
                </Select>
            </Grid>
            <Grid item xs={4}>
              <Button
                color='primary'
                variant='outlined'
                onClick={create_user}
                disabled={email_invalid}
              >Create User</Button>
            </Grid>
          </Grid>
        </Grid>
      <Dialog
        aria-labelledby="user-notify-dialog"
        open={notify_text !== ''}
      >
        <DialogTitle id="user-notify-dialog">{notify_text}</DialogTitle>
        <DialogActions>
          <Button onClick={close}
            disabled={notify_text === 'Busy'}
          >Dismiss</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}