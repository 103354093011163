import { Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Survey } from 'src/types'
import { makeStyles } from '@material-ui/core/styles'
import { CompetenceData } from '..'
import {sumBy} from 'lodash'

type Props = {
    surveyOne?: Survey,
    surveyTwo?: Survey,
    inputDataAttendees?: CompetenceData[],
    inputDataReviewers?: CompetenceData[],
}
type AverageImprovement = {
    attendeeIncrease: number,
    reviewerIncrease: number
}

const useStyles = makeStyles((theme) => ({
    container: {
        border: '2px solid gray',
        margin: '0.5em',
        padding: '0.25em',
        paddingBottom: '0.25em',
        paddingLeft: '1em',
        paddingRight: '1em',
        display: 'flex',
        flexDirection: 'column'
    },
    boldText: {
        fontWeight: 'bold',
        marginBottom: 0,
    },
    subHeaderText: {
        fontWeight: 'bold',
        marginTop: 0,
    },
    headerPositive: {
        fontWeight: 'bold',
        margin: 0,
        fontSize: 42,
        color: 'green'
    },
    headerNegative: {
        fontWeight: 'bold',
        margin: 0,
        fontSize: 42,
        color: 'red'
    },
    outerCircle: {
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '1em',
    },
    circle: {
        border: '2px solid gray',
        borderRadius: '50%',
        justifyContent: 'center',
        textAlign: 'center',
        width: '200px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column'
    }
}))


const AveragePerformanceImprovement:React.FC<Props> = ({ surveyOne, surveyTwo, inputDataAttendees, inputDataReviewers }) => {
    const classes = useStyles()

    const [data, setData] = useState<AverageImprovement>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        void (async () => {
            if (surveyOne && surveyTwo) {
                setLoading(true)
                const surveyOneAttendeesData = inputDataAttendees?.filter(c=>c.surveyName===surveyOne?.name)
                const surveyOneReviewersData = inputDataReviewers?.filter(c=>c.surveyName===surveyOne?.name)
                const surveyTwoAttendeesData = inputDataAttendees?.filter(c=>c.surveyName===surveyTwo?.name)
                const surveyTwoReviewersData = inputDataReviewers?.filter(c=>c.surveyName===surveyTwo?.name)
                if(surveyOneAttendeesData && surveyTwoAttendeesData && surveyOneReviewersData && surveyTwoReviewersData){

                    const attendeesDiff = (sumBy(surveyTwoAttendeesData, 'avgLevel')-sumBy(surveyOneAttendeesData, 'avgLevel'))/sumBy(surveyOneAttendeesData, 'avgLevel')
                    const reviewersDiff = (sumBy(surveyTwoReviewersData, 'avgLevel')-sumBy(surveyOneReviewersData, 'avgLevel'))/sumBy(surveyOneReviewersData, 'avgLevel')
                    const result = {attendeeIncrease: attendeesDiff*100, reviewerIncrease:reviewersDiff*100}
                    if(attendeesDiff && reviewersDiff && attendeesDiff<100){
                        setData(result)
                    }else{
                        setData(undefined)
                    }
                }
                setLoading(!inputDataAttendees || !inputDataReviewers)
            }
        })()
    },[surveyOne, surveyTwo, inputDataAttendees, inputDataReviewers])

    return (
        <Grid container className={classes.container}>
            <p className={classes.boldText}>Average Performance Improvement:</p>
            {surveyOne && surveyTwo && (
                <p className={classes.subHeaderText}>{surveyOne.name} VS {surveyTwo.name}</p>
            )}

            
            {(loading) && (
                        <p>Loading...</p>
             )}
            {!loading && (!surveyOne || !surveyTwo) && (
                <p>Please select two surveys</p>
            )}
            {(!loading && !data) && (
                <p>Not enough data available.</p>
            )}

            {surveyOne && surveyTwo && (
                <>
                    {!loading && data && (
                        <Grid container direction="row">
                            <Grid item xs={6} className={classes.outerCircle}>
                                <div className={classes.circle}>
                                    <h1 className={data.attendeeIncrease > 0 ? classes.headerPositive : classes.headerNegative}>
                                        {Number.isFinite(data.attendeeIncrease) ? `${Math.round(data.attendeeIncrease)}%` : 'Not enough data'}
                                    </h1>
                                    <h2>Attendee</h2>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.outerCircle}>
                                <div className={classes.circle}>
                                    <h1 className={data.reviewerIncrease > 0 ? classes.headerPositive : classes.headerNegative}>
                                        {Number.isFinite(data.reviewerIncrease) ? `${Math.round(data.reviewerIncrease)}%` : 'Not enough data'}
                                    </h1>
                                    <h2>Reviewer</h2>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Grid> 
    )
}

export default AveragePerformanceImprovement