import { String } from 'lodash';
import { api } from '.';
import { query_params } from '../helpers/api'

export const getSurveyCompetencyScoreAvgRange = ( params: { programmeId: string, table: 'reviewer'|'attendee'|'all', fullyCompletedOnly:boolean }) => 
    api.get(`/dashboard/survey_competency_score_avg_range/${query_params(params)}`).then(response => response.data)

export const getSurveyCompetencyScoreAvgRangeIncludeClient = ( params: { programmeId: string, table: 'reviewer'|'attendee'|'all', fullyCompletedOnly:boolean, clientId?:string, programmesStartedAfter?:string }) => 
    api.get(`/dashboard/survey_competency_score_avg_range/${query_params(params)}`).then(response => response.data)

export const getSurveyCompetencyScoreAvgRangeForAttendee = ( params: { programmeId: string, table: 'reviewer'|'attendee'|'all', fullyCompletedOnly:boolean, attendeeId:string }) => 
    api.get(`/dashboard/survey_competency_score_avg_range/${query_params(params)}`).then(response => response.data)

export const getNoTimeOpportunitySums = (params: { programmeId: string, table: 'reviewer'|'attendee'|'all', fullyCompletedOnly:boolean, clientId?:string, programmesStartedAfter?:string  }) =>
    api.get(`/dashboard/no_time_or_opportunity/${query_params(params)}`).then(response => response.data)