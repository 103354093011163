import React, { useState, useEffect } from 'react'
import { Grid, Select, Input, FormControl, MenuItem, makeStyles} from '@material-ui/core'
import { Add, LibraryAdd, Remove } from '@material-ui/icons'
import { listTags } from 'src/api/tag'
import { Tag } from 'src/types';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: '90%'
	}
}));

type props = {
	selected: string[],
	onChange: (tagNames: string[]) => void
	disabled?: boolean
}

const TagsSelector:React.FC<props> = ({ selected, onChange, disabled }) => {
	const classes = useStyles()
	const [tags, setTags] = useState<Tag[]>([])
	const [selectedTags, setSelectedTags] = useState<Tag[]>([])

	useEffect(() => {
		void (async() => {
			const foundTags = await listTags()
			setTags(foundTags.data)
		})()
	},[])   

	useEffect(() => {
		setSelectedTags(tags.filter((tag) => selected.includes(tag.id)))
	}, [selected])

	const handleChange = (e: any) => {
		const newSelection: string[] = e.target.value
		onChange(newSelection)
	}

	return (
		<Grid container>
			<Grid item xs={3}>
				<FormControl className={classes.formControl}>
					<Select
						multiple
						value={selectedTags.map((tag) => tag.id)}
						input={<Input/>}
						onChange={handleChange}
						displayEmpty
						disabled={disabled}
						renderValue={() => <span>Change Tags</span>}
					>
						{tags.map((tag) => (
							<MenuItem key={tag.id} value={tag.id}>
								{selectedTags.includes(tag) ? <Remove/> : <Add/> } {tag.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={9}>
				<Grid container>
					{selectedTags.map((tag) => (
						<Grid item xs={4} key={tag.id}>
							{tag.name}
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default TagsSelector