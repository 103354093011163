import React, { useState, FC } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Competence, Statement } from '../../../../types'
import { createCompetence, deleteCompetenceById, saveCompetence } from '../../../../api/competence'
import { useStyles } from '../../../../theme/styles'

interface Props {
  competence: Competence & {statements:Statement[]}
  close: () => void
}

const statuses = {
  'Draft': 'Draft Competencies can be edited but cannot be added to Programmes',
  'Active': 'Active Competencies can be added to Programmes, but cannot be edited as doing so would impact the validity of ongoing surveys',
  'Archived': 'Archived Competencies cannot be added to Programmes or Edited, but must be retained for historic and ongoing surveys to function and report correctly'
}

export const CompetenceDialog: FC<Props> = ({ competence, close }: Props) => {
  const [name, setName] = useState<string>(competence.name)
  const [status, setStatus] = useState<string>(competence.status)
  const [busy, setBusy] = useState<boolean>(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false)
  const changed = name !== competence.name || status !== competence.status
  const classes = useStyles()

  const cancel = () => {
    close()
  };

  const save = () => {
    setBusy(true)
    const action = competence.id === 'new'
      ? createCompetence
      : saveCompetence
    action({
      ...competence,
      name,
      status
    })
      .then(success => {
        setBusy(false)
        close()
      })
      .catch(error => {
        setBusy(false)
      })
  }

  const destroy = () => {
    setBusy(true)
    deleteCompetenceById(competence.id)
      .then(success => {
        setBusy(false)
        close()
      })
      .catch(error => {
        setBusy(false)
      })
  }

  return (
    <Dialog
      aria-labelledby="competency-modal"
      open={true}
      fullWidth={true}
      maxWidth={'lg'}>
      <DialogTitle id="competency-modal">{competence.id === 'new' ? 'New competency' : 'Edit competency'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='Competency'
              value={name}
              className={classes.input}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            {Object.keys(statuses).map(s =>
              <Tooltip title={statuses[s as keyof typeof statuses]}>
                <Button
                  onClick={() => setStatus(s)}
                  variant={status === s ? 'contained' : 'outlined'}
                  disabled={competence.statements.length === 0}
                  color='primary'
                >{s}</Button>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.description}
            >
              {statuses[status as keyof typeof statuses]}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {competence.id !== 'new' && competence.status === 'Draft' &&
          <Button
            onClick={() => setOpenConfirmDelete(true)}
            disabled={busy}
          >Delete</Button>
        }
        <Button
          onClick={() =>
            changed
              ? setOpenConfirmCancel(true)
              : cancel()
          }
          disabled={busy}
        >Cancel</Button>
        <Button
          onClick={save}
          disabled={busy || !changed}
        >Save</Button>
      </DialogActions>
      <Dialog
        aria-labelledby="competence-delete-modal"
        open={openConfirmDelete}
      >
        <DialogTitle id="competence-delete-modal">Confirm competency delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
          }}>Cancel</Button>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
            destroy()
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="competence-cancel-modal"
        open={openConfirmCancel}
      >
        <DialogTitle id="competence-delete-modal">You have unsaved changes!</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
          }}>Continue editing</Button>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
            cancel()
          }}>Discard changes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
