import React, { useState, useEffect } from 'react';
import { Tutor } from '../../../../types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../../theme';
import { getTutorList } from '../../../../api/tutor';
import Title from '../../../Common/Title';
import ActionButton from '../../../Common/ActionButton';
import EditTutor from './EditTutor'

const useStyles = makeStyles(() => ({
  grid: {
    cursor: 'default'
  },
  grid_item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEE',
    },
  },
  tag: {
    color: theme.colours.gray,
    fontSize: 12
  },
  editButton: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    textAlign: 'center',
    width: 140,
    cursor: 'pointer'
  },
  actionButtonContainer: {
    float: 'right',
    maxWidth: 250,
    display: 'flex'
  }
}));


interface TutorFields {
  name?: string;
};

const Tutors = () => {
  const classes = useStyles();

  const [tutors, setTutors] = useState<Tutor[]>([]);
  const [editingTutor, setEditingTutor] = useState<Tutor | undefined>(undefined);

  const refreshTutorList = () => {
    getTutorList().then(setTutors);
  }
  useEffect(() => {
    refreshTutorList();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Title>Tutors</Title>
      </Grid>

      <Grid item xs={4}>
        <div className={classes.actionButtonContainer}>
          <ActionButton icon="plus" text="Create new tutor" onClick={() => setEditingTutor({ id: "", name: "" })} />
          <EditTutor
            isModalOpen={editingTutor !== undefined}
            closeModal={() => {
              setEditingTutor(undefined);
            }}
            tutor={editingTutor ? editingTutor : { id: "", name: "" }}
            onDataChange={refreshTutorList}
          />
        </div>
      </Grid>

      {tutors.map((tutor: Tutor) => {
        return <Grid container className={classes.grid} key={tutor.id}>
          <Grid item xs={2} className={classes.grid_item} onClick={() => setEditingTutor(tutor)} >
            {tutor.name ?? '-'}
            <div className={classes.tag}>Tutor Name</div>
          </Grid>

        </Grid>
      })
      }
    </>
  );
};

export default Tutors;
