import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ProgrammeStatus } from '../../../../../types';
import StatusChangeButton from './StatusChangeButton';
import Swal from 'sweetalert2'
import { UserContext, isPlatformAdmin } from 'src/components/user/context';

const useStyles = makeStyles(() => ({
  block: {
    backgroundColor: '#E9E9E9',
    width: '400px',
    padding: '10px',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  description: {
    fontSize: 18
  }
}));

interface StatusButton {
  name: ProgrammeStatus,
  description: string,
  tooltip: string
};

const statusButtons: StatusButton[] = [
  {
    name: "Draft",
    description: "Programme has been created. No emails are sent in this state.",
    tooltip: "If status is updated to Draft, programme will be editable again. No emails will be sent in this state."
  },
  {
    name: "Active",
    description: "Programme is active, it cannot be currently edited as that could cause significant differences in results.",
    tooltip: "Once programme is updated to Active, it cannot be edited again. Emails and other scheduled activities will be started."
  },
  {
    name: "Completed",
    description: "Programme has been completed and can no longer be edited.",
    tooltip: "Once programme is Completed, it cannot be changed again. It will be available to view on the Completed programmes page"
  }
];

interface StatusChangeBlockProps {
  status?: ProgrammeStatus;
  onChange: (newStatus: ProgrammeStatus) => Promise<any>;
};

function getButtonInfo(name?: ProgrammeStatus): StatusButton {
  return statusButtons.filter(button => button.name === name)[0];
}

const StatusChangeBlock = ({ status, onChange }: StatusChangeBlockProps) => {
  const [activeButton, setActiveButton] = useState<StatusButton | undefined>(getButtonInfo(status));
  const [isSaving, setIsSaving] = useState(false);
  const userContext = useContext(UserContext)

  useEffect(() => {
    getButtonInfo(status)
  }, [])
  const handleStatusChange = (programStatus: ProgrammeStatus) => {
    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to update programme status?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true
    }).then((choice: any) => {
      if (choice.isDismissed) {
        //noop
      } else if (choice.isConfirmed) {
        setActiveButton(getButtonInfo(programStatus));
        setIsSaving(true);
        onChange(programStatus).then(() => setIsSaving(false));
      }
    })

  };

  const classes = useStyles();
  const statusUpdateForbidden = (button: any, activeButton: any) => {

    if (button.name !== 'Active' && activeButton.name === 'Draft') {
      return true;
    }

    if (button.name !== 'Draft' && button.name !== 'Completed' && activeButton.name === 'Active') {
      return true;
    }

    if (activeButton.name === 'Completed' && button.name !== 'Active') {
      return true;
    }

    return false;
  }

  return (
    <div className={classes.block}>
      <div className={classes.buttonContainer}>
        {statusButtons.map(button => (
          <StatusChangeButton
            key={button.name}
            name={button.name}
            tooltip={button.tooltip}
            isActive={activeButton ? button.name === activeButton.name : false}
            isDisabled={isSaving || statusUpdateForbidden(button, activeButton) || !isPlatformAdmin(userContext.user)}
            onClick={handleStatusChange}
          />
        ))}
      </div>

      <div className={classes.description}>{activeButton ? activeButton.description : ''}</div>
    </div>
  )
};

export default StatusChangeBlock;
