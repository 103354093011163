import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  submitSurveyButton: {
    margin: 10,
    backgroundColor: "#26377B"
  },
  footer: {
    marginTop: 200,
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "LightGray",
    color: "white",
  },
  progress: {
    margin: 0
  },
  //TODO: apply sitewide using themes/index.js or disable global override to 14px.
  h1: { fontSize: '2em' },
  h2: { fontSize: '1.5em' },
  h3: { fontSize: '1.17em' },
  h4: { fontSize: '1.02em' },
  navbar: {
    "& button": {
      display: "none"
    }
  }
});

class ErrorPage extends React.Component {

  render() {
    const errMessage = this.props.errorMessage | `Unknown error, sorry we couldn't be more specific.`
    return (
      <Grid container direction="row" justify="center" alignItems="center" style={{"height": "100vh"}} item>
        <Grid xs={11} sm={7} md={7} lg={4} item>

          <h1>Something went wrong :(</h1>
          <h2>{errMessage}</h2>
          <Typography>
            Problem persists?
            <br/>
            <a href="mailto: support@openside.group">Email support@openside.group</a>
          </Typography>

        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ErrorPage);
