import React from 'react';

import { ProgrammeStatus } from '../../../../../../types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles(() => ({
  available: {
    backgroundColor: "#26377B",
    color: "#FFFFFF",
    fontSize: "20px",
    lineHeight: "49px",
    width: "30%",
    padding: "5px",
    borderRadius: "10px",
    opacity: 0.6,
    cursor: "pointer"
  },
  disabled: {
    backgroundColor: "#26377B",
    color: "#FFFFFF",
    fontSize: "20px",
    lineHeight: "49px",
    width: "30%",
    padding: "5px",
    borderRadius: "10px",
    opacity: 0.1,
    cursor: "default"
  },
  active: {
    backgroundColor: "#26377B",
    color: "#FFFFFF",
    fontSize: "20px",
    lineHeight: "49px",
    width: "30%",
    padding: "5px",
    borderRadius: "10px",
    opacity: 1,
    cursor: "default"
  },

}));



interface StatusChangeButtonProps {
  name: ProgrammeStatus;
  isActive: boolean;
  onClick: (status: ProgrammeStatus) => void;
  isDisabled: boolean;
  tooltip: string
}

const StatusChangeButton = ({ name, isActive, onClick, isDisabled, tooltip }: StatusChangeButtonProps) => {
  const classes = useStyles();

  const onClickCheck = (name: any) => {
    if (!isDisabled && !isActive) {
      onClick(name)
    }
  }
  return (
    <Tooltip title={tooltip} aria-label={tooltip}>

      <button
        className={isActive ? classes.active : isDisabled ? classes.disabled : classes.available}
        onClick={() => onClickCheck(name)}
      >
        {name}
      </button>

    </Tooltip>

  )

};

export default StatusChangeButton;
