import React, { useState, FC, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { Email } from '../../types'
import { createEmail, updateEmail, deleteEmailById } from '../../api/email'
import { list_email_schema } from '../../api/email_schema'
import CustomEmailEditorComponent from './CustomEmailEditorComponent'
import { formatDate } from '../../helpers/date';

import { useStyles } from '../../theme/styles';

interface Props {
  email: Email
  close: () => void
  disabled: boolean
}

export const EmailDialog: FC<Props> = (props: Props) => {
  const { close, email } = props
  const [emailSchemas, setEmailSchemas] = useState<any[]>([])
  const [alias, setAlias] = useState<string>(email.alias)
  const [subject, setSubject] = useState<string>(email.subject)
  const [survey_date_offset_days, setDaysOffset] = useState<number>(email.survey_date_offset_days)
  const [recipient_group, setRecipients] = useState<string>(email.recipient_group)
  const [text_body_json, setTextBodyJson] = useState<string>(email.text_body_json)
  const [text_body, setTextBodyHtml] = useState<string>(email.text_body || '')
  const [busy, setBusy] = useState<boolean>(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false)
  const changed = alias !== email.alias ||
    subject !== email.subject ||
    survey_date_offset_days !== email.survey_date_offset_days ||
    recipient_group !== email.recipient_group ||
    text_body_json !== email.text_body_json
  const disabled = email.date_sent !== undefined || props.disabled
  const classes = useStyles()

  useEffect(() => {
    list_email_schema().then(res => {
      setEmailSchemas(res)
    })
  }, [])

  const copyFromTemplate = (schema: any) => {
    setTextBodyJson(schema.email_template_json)
    setTextBodyHtml(schema.email_template)
    setRecipients(schema.recipient_group)
    setSubject(schema.email_subject)
    setDaysOffset(schema.survey_date_offset_days)
  };
  const cancel = () => {
    close()
  };

  const save = () => {
    setBusy(true)
    const action = email.id === 'new'
      ? createEmail
      : updateEmail
    action({
      ...email,
      alias,
      subject,
      survey_date_offset_days,
      recipient_group,
      text_body_json,
      text_body
    } as Email)
      .then(success => {
        setBusy(false)
        close()
      })
      .catch(err => {
        setBusy(false)
      })
  }

  const destroy = () => {
    setBusy(true)
    deleteEmailById(email.id!)
      .then(success => {
        setBusy(false)
        close()
      })
      .catch(err => {
        setBusy(false)
      })
  }

  return (
    <Dialog
      aria-labelledby="email-modal"
      open={email !== undefined}
      fullWidth={true}
      maxWidth={'lg'}>
      <DialogTitle id="email-modal">{email.id === undefined ? 'New survey email' : 'Edit survey email'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            <TextField
              label='Alias'
              value={alias}
              className={classes.input}
              required
              disabled={disabled}
              onChange={(e) => setAlias(e.target.value)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label='Subject'
              value={subject}
              className={classes.input}
              required
              disabled={disabled}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Days after start of survey'
              type='number'
              value={survey_date_offset_days}
              className={classes.input}
              required
              disabled={disabled}
              onChange={(e) => setDaysOffset(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Recipents'
              select
              value={recipient_group}
              className={classes.input}
              required
              disabled={disabled}
              onChange={(e) => setRecipients(e.target.value)}
            >
              {["All Attendees", "Late Attendees", "All Reviewers", "Late Reviewers", "Programme Admin", "Escalation"].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {email.date_sent !== undefined &&
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                  <TextField
                    label='Date sent'
                    type='string'
                    value={formatDate(email.date_sent)}
                    className={classes.input}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={10} xs={12}>
                  <TextField
                    label='Status'
                    type='string'
                    value={email.error_status || '-'}
                    className={classes.input}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label='Recipients'
                    type='string'
                    value={email.recipients || '-'}
                    className={classes.input}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item xs={12}>
            <CustomEmailEditorComponent
              text_body_json={text_body_json}
              setTextBodyJson={setTextBodyJson}
              setTextBodyHtml={setTextBodyHtml}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!disabled && email.id !== 'new' &&
          <Button
            onClick={() => setOpenConfirmDelete(true)}
            disabled={busy}
          >Delete</Button>
        }
        <Button
          onClick={() =>
            changed
              ? setOpenConfirmCancel(true)
              : cancel()
          }
          disabled={busy}
        >Close</Button>
        {!disabled &&
          <Button
            onClick={save}
            disabled={busy || !changed}
          >Save</Button>
        }
      </DialogActions>
      <Dialog
        aria-labelledby="email-delete-modal"
        open={openConfirmDelete}
      >
        <DialogTitle id="email-delete-modal">Confirm email delete?</DialogTitle>
        <DialogContent>
          <DialogContentText>Email content can be hard to setup!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
          }}>Cancel</Button>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
            destroy()
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="email-cancel-modal"
        open={openConfirmCancel}
      >
        <DialogTitle id="email-delete-modal">You have unsaved changes!</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
          }}>Continue editing</Button>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
            cancel()
          }}>Discard changes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
