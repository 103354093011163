import React from 'react';
import { useStyles } from '../../../theme/styles'

interface TitleProps {
  children: React.ReactNode;
};

const Title = ({ children } : TitleProps) => {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      {children}
    </div>
  );
}

export default Title;
