import React, { useState, useContext, FC } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import logo_blue from '../../assets/logo_blue.png';
import { login } from '../../api/user'
import { UserContext } from './context'

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    padding: '40',
    background: `url(https://user-images.githubusercontent.com/24764236/53799830-40874680-3f33-11e9-8b09-050791b01b22.jpg) no-repeat center center fixed; `,
    backgroundSize: 'cover',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  login_form: {
    flexGrow: 1,
    padding: 5,
    backgroundColor: 'white',
    width: '50%',
    marginTop: '50px',
    display: 'inline-block'
  },
  grid_item: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  input: {
    marginRight: theme.spacing(2),
    width: `calc(50% - ${theme.spacing(2)}px)`
  },
  button: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    marginTop: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#26377B',
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}));

export const Login: FC = () => {
  const { user, setUser } = useContext(UserContext)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [busy, setBusy] = useState(false);
  const history = useHistory()
  const location: any = useLocation()

  const classes = useStyles();

  const onChangeUsername = (event: any) => setUsername(event.target.value);
  const onChangePassword = (event: any) => setPassword(event.target.value);

  const onLogin = (event: any) => {
    setBusy(true)
    event.preventDefault()
    login({ username, password })
      .then(user => {
        setHasError(false)
        setBusy(false)
        setUsername('')
        setPassword('')
        setUser(user)
        history.push(
          location.state?.referer || {
            pathname: "/"
          }
        )
      })
      .catch(() => {
        setBusy(false)
        setHasError(true)
        setUsername('')
        setPassword('')
      })
  }

  return user !== undefined && !busy
    ? <Redirect to={
      location?.state?.referer ||
      {
        pathname: "/"
      }
    } />
    : (
      <div className={classes.background}>
        <div className={classes.login_form}>

          <Grid container>
            {hasError && !busy && (
              <Grid item xs={8} className={classes.grid_item}>
                <Alert
                  className={classes.alert}
                  variant="filled"
                  severity="error"
                >
                  There was a problem logging in - check if you typed password correctly!
            </Alert>
              </Grid>
            )}

            <Grid item xs={8} className={classes.grid_item}>
              <form noValidate autoComplete="off">
                <TextField
                  className={classes.input}
                  label="email"
                  variant="outlined"
                  value={username}
                  onChange={onChangeUsername}
                />
                <TextField
                  className={classes.input}
                  label="password"
                  variant="outlined"
                  inputProps={{ type: 'password' }}
                  value={password}
                  onChange={onChangePassword}
                />
              </form>
            </Grid>

            <Grid item xs={8} className={classes.grid_item}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={onLogin}
                disabled={username === '' || password === ''}
              >
                Log In
              </Button>
              <div className={classes.logo}><img src={logo_blue} alt="logo" height="30px" /></div>

            </Grid>
          </Grid>
        </div>
      </div>
    );
}

