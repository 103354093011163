import { api } from '.';
import { query_params } from '../helpers/api'

export const createTag = (body: {name: string} ) => 
	api.post('/tag', body).then(response => response.data)

export const getTag = (tagId: string) => 
	api.get(`/tag/${tagId}`).then(response => response.data)

export const listTags = (filter: any = {}) => 
	api.get(`/tag/${query_params(filter)}`).then(response => response.data)

export const updateTag = (id: string, body: { name: string }) =>
	api.put(`/tag/${id}`, body).then(response => response.data)

export const deleteTag = (tagId: string) => 
	api.delete(`/tag/${tagId}`).then(response => response.data)

	