import React, { useEffect, useState, FC, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Page from '../Layout/Page';
import Title from '../Common/Title';
import ActionButton from '../Common/ActionButton';
import { getNextLastList } from '../../api/programme';
import { useStyles } from '../../theme/styles'
import { useNavigation, useIdNavigation } from '../../helpers/navigation'
import { wildcard } from '../../helpers/api';
import { Programme } from '../../types';
import { CreateNewProgrammeModal } from './CreateNewProgrammeModal'
import { ActiveProgrammeRow } from './active_row'
import { DraftProgrammeRow } from './draft_row'
import { UserContext, isPlatformAdmin } from '../user/context';

export const OverviewProgrammes: FC = () => {
  const userContext = useContext(UserContext)
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState("")
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [busy, setBusy] = useState(false)
  const [programmes, setProgrammes] = useState<Programme[]>([]);
  const upcoming_programmes = programmes.filter(programme => programme.programme_status === 'Draft').sort((progrA, progrB) => {
    return Date.parse(progrA.start ?? '') - Date.parse(progrB.start ?? '')
  })
  const active_programmes = programmes.filter(programme => programme.programme_status === 'Active')

  const filter = {
    programme_status: { $in : ['Draft', 'Active'] },
    ...searchQuery === '' ? {} : { title: wildcard(searchQuery) }
  }
  const reload = () => {
    setBusy(true)
    getNextLastList(filter)
    .then(result => {
      setProgrammes(result)
      setBusy(false)
    })
    .catch(error => {
      setBusy(false)
    })
  }

  useEffect(reload, [searchQuery])

  const toggle_create_modal = () => setCreateModalOpen(state => !state)

  const gotoCompletedProgrammes = useNavigation("/programmes/completed")
  const goToProgrammeDetails = useIdNavigation("/programme")

  return (
    <Page>
      <Grid item xs={4}>
        <Title>Programmes - Overview</Title>
      </Grid>

      <Grid item xs={6}>
        <div className={classes.actionButtonContainer}>
          {isPlatformAdmin(userContext.user) && <ActionButton icon="plus" text="Create new programme" onClick={toggle_create_modal} />}
          <ActionButton icon="list-alt" text="Completed Programmes" onClick={gotoCompletedProgrammes} />
        </div>
      </Grid>

      <Grid item xs={6}>
        <TextField
          variant="standard"
          id="search_programme"
          label="Search"
          color="secondary"
          value={searchQuery}
          onChange={(event: any) => {
            setSearchQuery(event.target.value);
          }
          }
        />
      </Grid>

      <Grid className={classes.main} item xs={12}>
        <div className={classes.subHeading}>Upcoming Programmes</div>
        {busy && <div>Loading programmes...</div>}
        {upcoming_programmes.length === 0
          ? <div>{`There are no upcoming programmes${searchQuery === '' ? '' : ' that match your search'}`}</div>
          : upcoming_programmes.map(programme => <DraftProgrammeRow programme={programme} key={programme.id}/> )
        }
      </Grid>

      <Grid className={classes.main} item xs={12}>
        <div className={classes.subHeading}>Active Programmes</div>
        {busy && <div>Loading programmes...</div>}
        {active_programmes.length === 0
          ? <div>{`There are no active programmes${searchQuery === '' ? '' : ' that match your search'}`}</div>
          : active_programmes.map(programme => <ActiveProgrammeRow programme={programme} key={programme.id}/> )
        }
      </Grid>

      {createModalOpen &&
        <CreateNewProgrammeModal
          closeModal={toggle_create_modal}
          onProgrammeCreated={goToProgrammeDetails}
        />
      }
    </Page>
  )
}