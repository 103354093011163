import { stringify } from "qs"
import { Cursor } from "../../types"

export const wildcard = (string:string) => ({ $regex: `.*${string}.*`, $options: 'i'})

export const query_params = (filter?: any, sort?: any, cursor?: Cursor): string => {
  const qs = stringify({
    ...filter ? { filter } : {},
    ...sort ? { sort } : {},
    page: cursor && cursor.skip ? cursor.skip / cursor.limit + 1 : 1,
    limit: cursor && cursor.limit ? cursor.limit : undefined
  })
  return qs ? `?${qs}` : ""
}
