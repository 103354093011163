import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NotificationSnackbar from './NotificationSnackbar';
import SurveyQuestion from "./SurveyQuestion";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

class SurveyElements extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            expanded: null
        }

        this.surveySubmit = this.surveySubmit.bind(this)
        this.handleExpansionOfPanels = this.handleExpansionOfPanels.bind(this)
    }

    handleExpansionOfPanels = (question) => () => {
        const question_id = question.question.id
        this.setState({
            expanded: this.state.expanded === question_id
                ? null
                : question_id
        })
    }

    surveySubmit(e) {
        e.preventDefault()
        this.props.handleSubmit()
    }

    isAnswered(questionId) {
        const q = this.props.questions.filter(qe => {
            return qe.question.id === questionId
        })[0];
        const answered = q && q.value !== undefined && (
            (q.value === null && q.timeXopportunity !== undefined && q.answerText !== undefined)
            ||
            q.value !== null
        );
        return answered;
    }

    render() {
        const numOfAnsweredQuestions = this.props.questions.filter(q => {
            return q.value !== undefined && (
                (q.value === null && q.timeXopportunity !== undefined && q.answerText !== undefined)
                ||
                q.value !== null
            )
        }).length
        const numOfAllQuestions = this.props.questions.length
        const footerStatement = numOfAnsweredQuestions === numOfAllQuestions
            ? 'Finished, submit answers'
            : 'Please answer all the questions'

        return (
            <Grid>
                <Typography component="h1" className={this.props.classes.h1} align="center">
                    Reviewing {this.props.selfReviewing ? 'your' : `${this.props.attendeeName}'s`} skills, behaviours and
                    knowledge
                </Typography>
                <Typography component="h3" className={this.props.classes.h3}>{this.props.introStatement}</Typography>

                {this.props.questions.map(qa =>
                    <SurveyQuestion
                        key={qa.question.id}
                        handleValueChange={this.props.handleValueChange(qa.question.id)}
                        questionText={qa.question.self_review_text}
                        options={qa.question.options}
                        answerText={qa.answerText}
                        value={qa.value}
                        timeXopportunity={qa.timeXopportunity}
                        handleAnswerTextChange={this.props.handleAnswerTextChange(qa.question.id)}
                        handleAddEvidence={this.props.handleAddEvidence(qa.question.id)}
                        newEvidence={qa.evidence}
                        attendee={this.props.attendee}
                        reviewer={this.props.reviewer}
                        survey={this.props.survey}
                        question={qa.question}
                        recentAnswers={qa.recentAnswers}
                        attendeeRecentAnswers={qa.attendeeRecentAnswers}
                        selfReviewing={this.props.selfReviewing}
                        attendeeName={this.props.attendeeName}
                        classes={this.props.classes}
                        handleAnswerTimeXOpportunity={this.props.handleAnswerTimeXOpportunity(qa.question.id)}
                        toggleExpansion={this.handleExpansionOfPanels(qa)}
                        expanded={this.state.expanded === qa.question.id}
                        hideNextButton={this.props.questions.indexOf(qa) + 1 === this.props.questions.length}
                        handleNext={this.handleExpansionOfPanels(this.props.questions[this.props.questions.indexOf(qa) + 1])}
                        handleRemoveEvidence={this.props.handleRemoveEvidence(qa.question.id)}
                        answered={this.isAnswered(qa.question.id)}
                    />
                )}

                <div style={{ "height": 60 }} />

                <Paper className={this.props.classes.footer} style={{ zIndex: 9999999 }}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >

                        <Grid xs={8} item={true}>

                            {this.props.errorMessage !== '' ?
                                <NotificationSnackbar message={this.props.errorMessage} />
                                :
                                <Typography component="h4" className={this.props.classes.h4} style={{
                                    "marginLeft": "40px",
                                    "marginRight": "20px",
                                    "float": "right"
                                }}>{footerStatement}</Typography>
                            }
                        </Grid>
                        <Grid xs={4} item={true}>
                            <Button
                                className={this.props.classes.submitSurveyButton}
                                variant="contained"
                                type="submit"
                                color="inherit"
                                disabled={numOfAnsweredQuestions !== numOfAllQuestions}
                                onClick={this.surveySubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}

export default SurveyElements;