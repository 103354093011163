import React, { useEffect, useState, FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { Email, Survey } from '../../../../../types'
import { useStyles } from '../../../../../theme/styles'
import SurveyRow from './SurveyRow';
import Icon from '../../../../Common/Icon';
import { SurveyModal } from './survey_modal'
import { getSurveyListByProgramme } from '../../../../../api/survey'
import { getEmailListByProgramme } from '../../../../../api/email'

interface Props {
  programme_id:string
  disabled: boolean
}

const Surveys:FC<Props> = ({programme_id, disabled}:Props) => {
  const classes = useStyles();

  const [surveys, setSurveys] = useState<Survey[]>([])
  const loadSurveys = () => {
    getSurveyListByProgramme(programme_id).then(setSurveys)
  }
  useEffect(loadSurveys, [programme_id])

  const [emails, setEmails] = useState<Email[]>([])
  const loadEmails = () => {
    getEmailListByProgramme(programme_id).then(setEmails)
  }
  useEffect(loadEmails, [programme_id])

  const [surveyModal, setSurveyModal] = useState<Survey|undefined>(undefined);
  const openSurvey = (survey:Survey) => setSurveyModal(survey)
  const closeSurvey = () => {
    setSurveyModal(undefined)
    loadSurveys()
  }

  return (
    <>
      <Grid container spacing={4}>
        {surveys.map((survey: any, index: number) =>
          <Grid item xs={12} key={survey.id}>
            <SurveyRow
              previous={index > 0 ? surveys[index-1] : undefined}
              survey={survey}
              disabled={disabled}
              emails={emails}
              reload_emails={loadEmails}
              open_survey={openSurvey}
            />
          </Grid>
        )}
        {!disabled &&
          <Grid item xs={2}>
            <Card
              className={classes.schedule_card_blue}
              variant="outlined"
              onClick={() => openSurvey({
                id: 'new',
                programme: programme_id,
                name: 'Change Me',
                programme_date_offset_days: surveys.length > 0 ? surveys[surveys.length -1].programme_date_offset_days + 60 : 0,
                expires_after_days: surveys.length > 0 ? surveys[surveys.length -1].expires_after_days : 30,
                front_page_attendee: '',
                front_page_reviewer: '',
              })}
            >
              <CardContent>
                <Icon icon="plus" />Survey
              </CardContent>
            </Card>
          </Grid>
        }
      </Grid>
      {surveyModal && <SurveyModal
        survey={surveyModal}
        close={closeSurvey}
      />}
    </>
  );
};

export default Surveys;
