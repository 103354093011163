import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog"
import TableComponent from "./TableComponent"
import Grid from '@material-ui/core/Grid';

import { Attendee, Reviewer, Survey } from '../../../../types'
import { getSurveysWithResponses, getReviewersWithAttendees } from "./controller";
import { getSurveyListByProgramme } from '../../../../api/survey'
import { findAttendeesByProgrammeId } from '../../../../api/attendee'
import { getReviewerList } from '../../../../api/reviewer'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
    },
    sliderStepsField: {
        margin: theme.spacing(),
        width: 150,
    },
    dataField: {
        margin: theme.spacing(),
        width: 180,
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing() * 2,
    },
    button: {
        margin: theme.spacing()
    },
    sliderRoot: {
        margin: theme.spacing(),
        width: 400,
    },
    slider: {
        padding: '22px 0px',
    },
}));

const ResponsesPage: FC = () => {
    const { programme_id } = useParams()

    // const [surveys, setSurveys] = useState<Survey[]>([])
    // const loadSurveys = () => {
    //   getSurveyListByProgramme(programme_id).then(setSurveys)
    // }
    // useEffect(loadSurveys, [programme_id])

    // const [attendees, setAttendees] = useState<Attendee[]>([])
    // const loadAttendees = () => {
    //   findAttendeesByProgrammeId(programme_id).then(setAttendees)
    // }
    // useEffect(loadAttendees, [programme_id])
    // const attendee_ids = attendees.map(attendee => attendee.id) 

    // const [reviewers, setReviewers] = useState<Reviewer[]>([])
    // const loadReviewers = () => {
    //   getReviewerList({
    //       attendee: { $in : [attendee_ids]}
    //   }).then(setReviewers)
    // }
    // useEffect(loadReviewers, [attendee_ids])

    const [surveys, setSurveys] = useState<Survey[]>([])
    const [reviewers, setReviewers] = useState<Reviewer[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const classes = useStyles();

    useEffect(() => {
        Promise.all([
          getSurveysWithResponses(programme_id).then(setSurveys),
          getReviewersWithAttendees(programme_id).then(setReviewers)
        ])
        .then(() => setLoading(false))
    }, [programme_id])

    return (
        <Paper className={classes.paper}>
            <Dialog
                open={loading}
                fullScreen={false}
            >
                <CircularProgress
                    size={50}
                    style={{ 'margin': '50px' }}
                />
            </Dialog>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TableComponent
                        surveys={surveys}
                        reviewers={reviewers}
                    />
                </Grid>
            </Grid>
        </Paper>
    )

}

export default ResponsesPage;