import { api } from "../api"
import { Credentials, User } from "../types"
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const login = (credentials: Credentials) : Promise<User> =>
  api.put(`/auth`, {}, { auth: credentials }).then(response => response.data)

export const check = () : Promise<User> => api.get(`/auth`).then(response => ({
  ...response.data.user,
  id: response.data.user._id
}))

export const logout = () : Promise<boolean> => {
  cookies.remove('access_token', { path: '/' })
  return new Promise((resolve, reject) => resolve(true))
}

export const create = (username:string, roles:string[], org?:string) : Promise<User> => 
  api.post(`/spec/useradmin`, {username, roles, org}).then(response => response.data)

export const list = () : Promise<User[]> => 
  api.get('/spec/useradmin').then(response => response.data.data)

export const reset = (user:User) : Promise<boolean> => 
  api.put('/spec/useradmin/reset', user).then(response => true)

export const remove = (user:User) : Promise<boolean> =>
  api.delete(`/spec/useradmin/${user.id}`).then(response => true)

export const setpass = (pass:{ one_time_token:string, password:string}) : Promise<boolean> =>
  api.put(`/spec/useradmin/setpass`, pass).then(response => true)