import { getSurveyListByProgramme } from '../../../../api/survey'
import { findAttendeesByProgrammeId } from '../../../../api/attendee'
import { getReviewerListForAttendee } from '../../../../api/reviewer'
import { listResponsesForSurvey } from '../../../../api/responses'

export const getReviewersWithAttendees = async (programmeId) => {
    const attendees = await findAttendeesByProgrammeId(programmeId)

    const reviewers = await Promise.all(attendees.map(async attendee => {
        const reviewers = await getReviewerListForAttendee(attendee.id)
        return reviewers.map(reviewer => ({
            ...reviewer,
            self: reviewer.email === attendee.email,
            attendee
        }))
    }))

    return reviewers.flat()
}

export const getSurveysWithResponses = async (programmeId) => {
    const surveys = await getSurveyListByProgramme(programmeId)

    return Promise.all(
        surveys.map(async (survey) => {
            const responses = await listResponsesForSurvey(survey.id)
            return {
                ...survey,
                responses: responses.map(r =>
                    ({
                        reviewer: r.reviewer_id,
                        date: new Date(r.response_date).getDate() + '/'
                            + (new Date(r.response_date).getMonth() + 1) + '/'
                            + new Date(r.response_date).getFullYear().toString().substr(-2),
                        scores: r.score_count,
                        evidence: 0,
                        na_count: r.na_count
                    })
                )
            }
        })
    )
}