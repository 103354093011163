import React, { useCallback } from 'react';
import { formatDate } from '../../../helpers/date';

import { useStyles } from '../../../theme/styles'

interface Option {
  id: string;
  value: string;
}

interface ViewOnlyFieldProps {
  size: 'field_large' | 'field_small';
  label: string;
  value: any;
  type?: 'input' | 'select' | 'date';
  options?: Option[];
  hideLabelInViewMode?: boolean;
}

const ViewOnlyField = ({
  label,
  value,
  options = [],
  type = 'input',
  size,
  hideLabelInViewMode
}: ViewOnlyFieldProps) => {
  const classes = useStyles();


  const getDisplayValue = useCallback(() => {
    if (type === 'input') {
      return value;
    }

    if (type === 'date') {
      return formatDate(value);
    }

    const selected = options.filter(opt => opt.id === value)[0];
    return selected ? selected.value : '';
  }, [options, type, value]);

  const displayValue = getDisplayValue();

  return (
    <>
      <div className={classes[size]}>{displayValue}</div>
      <div className={classes.tag}>{hideLabelInViewMode ? "" : label}</div>
    </>
  );
};

export default ViewOnlyField;
