import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash'

import { useStyles } from '../../../../../../theme/styles';
import { formatDate, formatOffset } from '../../../../../../helpers/format';
import Icon from '../../../../../Common/Icon';
import { Email, Survey } from '../../../../../../types';
import { EmailDialog } from '../../../../../email'
import { SelectEmailTemplate } from '../../../../../email/SelectEmailTemplate'
import { Validations } from './validations'


interface SurveyRowProps {
  previous: Survey | undefined
  survey: Survey
  disabled: boolean
  emails: Email[]
  reload_emails: ()=>void
  open_survey: (survey: Survey) => void
};

const SurveyRow = ({ survey, disabled, previous, open_survey, emails: all_emails, reload_emails }: SurveyRowProps) => {
  const classes = useStyles();
  const [emailModal, setEmailModal] = useState<Email | undefined>(undefined)
  const [emailTemplateModal, setEmailTemplateModal] = useState<boolean>(false)
  const emails = all_emails.filter(e => e.survey === survey.id)

  const open_email = (email: Email) => {
    setEmailModal(email)
  }

  const close_email = () => {
    reload_emails()
    setEmailModal(undefined);
  }

  const close_select_template = () => {
    setEmailTemplateModal(false);
  }

  const createNewEmail = (schema: any) => {
    close_select_template()
    open_email({
      id: 'new',
      recipient_group: schema ? schema.recipient_group : "All Attendees",
      survey_date_offset_days: schema ? schema.survey_date_offset_days : 30,
      subject: "Change me",
      alias: "Change me",
      survey: survey.id,
      programme: survey.programme,
      text_body_json: schema ? schema.email_template_json : "{}",
      text_body: schema ? schema.email_template : "",
      date_scheduled: ''
    })
  }


  const future_survey_card = (survey: Survey) => (
    <Card
      className={classes.schedule_card_blue}
      variant="outlined"
      onClick={disabled ? () => { } : () => open_survey(survey)}
    >
      <CardContent>
        <div className={classes.value}>{survey.name}</div>
        <div className={classes.tag}>{formatOffset(survey.programme_date_offset_days)}</div>
      </CardContent>
    </Card>
  )

  const sent_survey_card = (survey: Survey) => (
    <Card
      className={classes.schedule_card_gray}
      variant="outlined"
    >
      <CardContent>
        <div className={classes.value}>{survey.name}</div>
        <div className={classes.tag}>{formatOffset(survey.programme_date_offset_days)}</div>
      </CardContent>
    </Card>
  )

  const scheduled_email_card = (email: Email) => (
    <Card
      className={classes.schedule_card_blue}
      variant="outlined"
      onClick={disabled ? () => { } : () => open_email(email)}
    >
      <CardContent>
        <div className={classes.emailTitle}>{email.alias}</div>
        <div className={classes.emailTitle}>{email.recipient_group}</div>
        <div className={classes.emailTitle}>{formatOffset(email.survey_date_offset_days)}</div>
        <div className={classes.emailTitle}>{formatDate(email.date_scheduled)}</div>
      </CardContent>
    </Card>
  )

  const sent_email_card = (email: Email) => (
    <Card
      className={classes.schedule_card_gray}
      variant="outlined"
      onClick={disabled ? () => { } : () => open_email(email)}
    >
      <CardContent>
        <div className={classes.emailTitle}>{email.alias}</div>
        <div className={classes.emailTitle}>{email.recipient_group}</div>
        <div className={classes.emailTitle}>{formatOffset(email.survey_date_offset_days)}</div>
        <div className={classes.emailTitle}>{formatDate(email.date_sent)}</div>
      </CardContent>
    </Card>
  )

  const ActionCard = ({ action, children }: any) => (
    <Card
      className={classes.schedule_card_blue}
      variant="outlined"
      onClick={action}
    >
      <CardContent>
        {children}
      </CardContent>
    </Card>

  )

  return (
    <Grid container spacing={4}>
      <Grid item xs={2}>
        {survey.date_sent === undefined ? future_survey_card(survey) : sent_survey_card(survey)}
      </Grid>

      <Grid item xs={8}>
        <Grid container spacing={1}>
          {emails.map(email =>
            <Grid item xs={3} key={email.id}>
              {email.date_sent === undefined ? scheduled_email_card(email) : sent_email_card(email)}
            </Grid>)}
        </Grid>
      </Grid>

      <Grid item xs={2}>
        {!disabled && <ActionCard action={() => setEmailTemplateModal(true)}>
          <div>
            <Icon icon="plus" />Email
          </div>
        </ActionCard>}
      </Grid>
      <Validations previous={previous} survey={survey} emails={emails} />
      {emailModal && <EmailDialog email={emailModal} close={close_email} disabled={disabled} />}
      {emailTemplateModal && <SelectEmailTemplate open={emailTemplateModal} withSchema={createNewEmail} close={close_select_template} disabled={disabled} />}
    </Grid >
  );
};

export default SurveyRow;
