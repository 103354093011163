import { api } from '.';
import { Client } from '../types';

export const findClientById = (clients: Client[], id?: string) => {
  if (!id) {
    return null;
  }

  return clients.find(client => client.id === id);
};

export const getClientById = (clientId: string) =>
  api.get(`/client/${clientId}`).then(response => response.data);


/** @Todo Add pagination params */
export const getClientList = () =>
  api.get('/client').then((response : any) => response.data.data)

export const saveClient = (client: any) =>
  api.put(`/client`, client).then(response => response.data);

export const createClient = (client: any) =>
  api.post(`/client`, client).then(response => response.data);

export const deleteClientById = (clientId: string) =>
  api.delete(`/client/${clientId}`).then(response => clientId);
