import React, { useState, useEffect } from 'react';
import { Client } from '../../../../types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../../theme';
import { getClientList } from '../../../../api/client';
import Title from '../../../Common/Title';
import ActionButton from '../../../Common/ActionButton';
import EditClient from './EditClient'

const useStyles = makeStyles(() => ({
  grid: {
    cursor: 'default'
  },
  grid_item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EEE',
    },
  },
  tag: {
    color: theme.colours.gray,
    fontSize: 12
  },
  editButton: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    textAlign: 'center',
    width: 140,
    cursor: 'pointer'
  },
  actionButtonContainer: {
    float: 'right',
    maxWidth: 250,
    display: 'flex'
  }
}));



const Clients = () => {
  const classes = useStyles();

  const [clients, setClients] = useState<Client[]>([]);
  const [editingClient, setEditingClient] = useState<Client | undefined>(undefined);

  const refreshClientList = () => {
    getClientList().then(setClients)
  }
  useEffect(() => {
    refreshClientList()
  }, []);

  const createNewClient = () => {
    setEditingClient({ id: "", name: "" })
  }

  return (
    <>
      <Grid item xs={12}>
        <Title>Clients</Title>
      </Grid>

      <Grid item xs={4}>
        <div className={classes.actionButtonContainer}>
          <ActionButton icon="plus" text="Create new client" onClick={createNewClient} />
          <EditClient
            isModalOpen={editingClient !== undefined}
            closeModal={() => {
              setEditingClient(undefined);
            }}
            client={editingClient ? editingClient : { id: "", name: "" }}
            onDataChange={refreshClientList}
          />
        </div>
      </Grid>

      {clients.map((client: Client) => {
        return <Grid container className={classes.grid} key={client.id}>
          <Grid item xs={2} className={classes.grid_item} onClick={() => setEditingClient(client)}>
            {client.name ?? '-'}
            <div className={classes.tag}>Client Name</div>
          </Grid>
        </Grid>
      })}

    </>
  );
};

export default Clients;
