import React from 'react';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import validateURL from 'valid-url'

class EvidenceAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUrl: ""
        }
    }

    handleAddEvidence = (event) => {
        const currentUrl = this.state.currentUrl
        if (!validateURL.isUri(currentUrl)) {
            alert('Cannot submit. Copied value does not look like a valid link')
            return
        }
        this.props.handleAddEvidence(currentUrl)
        this.setState({
            currentUrl: ""
        })

    }

    handleTypeEvidenceLink = (event) => {
        const currentUrl = event.target.value
        this.setState({
            currentUrl: currentUrl
        })

    }

    removeEvidence = (evidenceUrl) => {
        this.props.handleRemoveEvidence(evidenceUrl)
    }

    renderEvidence = () => {
        return this.props.newEvidence.map(e =>
            <Grid key={e}
                container spacing={1} direction="row" alignItems="center" style={{ float: "center", marginBottom: '4px' }}>

                <Grid item xs={1}>
                    <Link onClick={() => { this.removeEvidence(e) }}>
                        <DeleteOutlineRoundedIcon styles={{
                            float: "left",
                            width: "24px",
                            display: "inline-block",
                        }} />
                    </Link>
                </Grid>


                <Grid item xs={1}>
                    <AttachmentRoundedIcon
                        style={{
                            color: '#828282'
                        }} />
                </Grid>
                <Grid item xs={10}>
                    <div
                        style={{
                            maxHeight: '2.25em',
                            wordBreak: "break-all",
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            position: "relative",
                            paddingRight: '4px'
                        }}>
                        <a
                            id={e}
                            href={e}
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                            style={{
                                fontSize: "0.75em",
                                padding: "4px 0",
                                display: "inline-block",
                                allign: "right",
                                marginLeft: "4px",
                            }}
                        >
                            {e}
                        </a>
                    </div>
                </Grid>

            </Grid>
        )
    }
    render() {
        const evidenceIntroStatement = "Please copy and paste up to three links as evidence to support your assessment"
        return (
            <div>

                <p>{evidenceIntroStatement}</p>

                {this.props.newEvidence !== undefined && this.props.newEvidence.length > 0
                    ?
                    <div>
                        {this.renderEvidence()}

                    </div>
                    :
                    null
                }

                <TextField
                    key="evidence_entry"
                    onChange={this.handleTypeEvidenceLink}
                    margin="dense"
                    multiline
                    label="Evidence link"
                    fullWidth
                    value={this.state.currentUrl}
                    disabled={this.state.formDisabled}
                />

                <Button disabled={this.state.currentUrl == '' || (this.props.newEvidence !== undefined && this.props.newEvidence >= 3)} key="add_button" variant="contained" onClick={this.handleAddEvidence} >Add</Button>
            </div>
        )
    }
}

export default EvidenceAdd