import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { useStyles } from '../../theme/styles'
import { formatDate } from '../../helpers/date'
import { Client, Programme } from '../../types'
import { useNavigation } from '../../helpers/navigation'
import { getClientById } from '../../api/client'

interface Props {
  programme: Programme;
};

export const CompletedProgrammeRow = ({ programme }: Props) => {
  const classes = useStyles();

  const [client, setClient] = useState<Client | undefined>(undefined)
  const load_client = () => {
    if (programme.client !== undefined) {
      getClientById(programme.client)
        .then(setClient)
    }
  }
  useEffect(load_client, [programme.client])

  const goToProgrammeDetails = useNavigation(`/programme/${programme.id}`)

  return (
    <>
      <Grid container className={classes.grid} onClick={goToProgrammeDetails}>
        <Grid item xs={4}>
          {programme.title ?? '-'}
          <div className={classes.tag}>Title</div>
        </Grid>

        <Grid item xs={3}>
          {client ? client.name : '-'}
          <div className={classes.tag}>Client</div>
        </Grid>

        <Grid item xs={2}>
          {formatDate(programme.start)}
          <div className={classes.tag}>Start Date</div>
        </Grid>

        <Grid item xs={2}>
          {formatDate(programme.end)}
          <div className={classes.tag}>End Date</div>
        </Grid>
      </Grid>
    </>
  );
};
