import React from 'react'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getProgrammeSurveyUrls, getShowProgramme, resendToNonCompleters } from '../../../../../api/programme';

import {listRecordedActions } from '../../../../../api/recorded_action';
import { useParams } from 'react-router-dom'
import { Survey, SurveyUrl } from 'src/types';
import { getSurveyListByProgramme } from 'src/api/survey';
import { SurveyUrlsTable } from './SurveyUrlsTable';
import { Button, Select, MenuItem, Grid } from '@material-ui/core';
import Icon from '../../../../Common/Icon';
import { createObjectCsvStringifier } from 'csv-writer'
import moment from 'moment';


const useStyles = makeStyles(() => ({
    surveyNameSelect: {
        marginBottom: '1em',
    },
    downloadButton: {
        marginBottom: '1em',
    }
  }
));

const downloadLinksCsv = (surveys: SurveyUrl[], programmeTitle: string) => {
    const csv = createObjectCsvStringifier({
        header: [
            { id: 'survey', title: 'Survey' },
            { id: 'reviewerEmail', title: 'Email' },
            { id: 'reviewerName', title: 'Reviewer Name'},
            { id: 'attendeeName', title: 'Attendee Name'},
            { id: 'url', title: 'URL'},
            { id: 'status', title: 'Status'}
        ]
    })

    const csvData = `${csv.getHeaderString()}${csv.stringifyRecords(surveys)}`

    const url = window.URL.createObjectURL(new Blob([csvData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${programmeTitle}-${surveys[0].survey}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export const SurveyUrls = () => {
    const classes = useStyles();
    const { programme_id } = useParams()

    const [loading, setLoading] = useState(true)
    const [sent, setSent] = useState(false)
    const [programmeTitle, setProgrammeTitle] = useState<string>('')
    const [surveyLinks, setSurveyLinks] = useState<SurveyUrl[]>([])
    const [surveys, setSurveys] = useState<Survey[]>([])
    const [selectedSurvey, setSelectedSurvey] = useState<string>()
    const [sendTo, setSendTo] = useState<'attendees'|'reviewers'|'none'>('none')

    const [lastSent, setLastSent] = useState()

    useEffect(()=>{
        listRecordedActions({programme:programme_id, survey: selectedSurvey, context: sendTo, name:'remind_non_completers'}).then(res=>{
                    console.log('listRecordedActions', res)
                    setLastSent(res && res.data && res.data.length > 0 ? res.data[0].dateRecorded : undefined)
                })
    }, [sendTo])
    useEffect(() => {
        Promise.all([
            getSurveyListByProgramme(programme_id).then((response) => {
                setSurveys(response)
                if (response[0]) {
                    setSelectedSurvey(response[0].name)
                }
            }),
            getShowProgramme(programme_id).then((response) => {
              if (response.title) {
                setProgrammeTitle(response.title)
              }
            }),
            listRecordedActions({programme:programme_id, survey: selectedSurvey, context: sendTo, name:'remind_non_completers'}).then(res=>{
                    console.log('listRecordedActions', res)
                    setLastSent(res && res.data && res.data.length > 0 ? res.data[0].dateRecorded : undefined)
                })
            
        ])
    }, [programme_id])

    useEffect(() => {
        void (async () => {
            if (selectedSurvey) {
                const response = await getProgrammeSurveyUrls(programme_id, selectedSurvey)
                setSurveyLinks(response.map((data: SurveyUrl) => ({
                    ...data,
                    url: `${window.location.protocol}//${window.location.host}/survey/${data.url}`
                })))
                listRecordedActions({programme:programme_id, survey: selectedSurvey, context: sendTo, name:'remind_non_completers'}).then(res=>{
                    console.log('listRecordedActions', res)
                    setLastSent(res && res.data && res.data.length > 0 ? res.data[0].dateRecorded : undefined)
                })
            }
        })()
    }, [selectedSurvey])

    const doSendToNonCompleters = () => {
        if(selectedSurvey && sendTo && sendTo !== 'none'){
            resendToNonCompleters(programme_id, selectedSurvey, {sendTo:sendTo})
            .then(x=>listRecordedActions({programme:programme_id, survey: selectedSurvey, context: sendTo, name:'remind_non_completers'}))
            .then(res=>{
                    console.log('listRecordedActions', res)
                    setLastSent(res && res.data && res.data.length > 0 ? res.data[0].dateRecorded : undefined)
                })
            setSent(true)
        }else{
            console.log('not sending')
        }
   
    }
    return (
        <div>

           {selectedSurvey && (
<Grid container>
            <Grid item xs={2}>
                <Select
                    className={classes.surveyNameSelect}
                    label="Survey Name"
                    value={selectedSurvey}
                    onChange={(e) => setSelectedSurvey(e.target.value as string)}
                >   
                    {surveys.map((survey) => (
                        <MenuItem
                            key={survey.name}
                            value={survey.name}
                        >
                            {survey.name}
                        </MenuItem>
                    ))}
                </Select>
                </Grid>
<Grid item xs={2}>
 {surveyLinks.length > 0 && (
                <Button
                    className={classes.downloadButton}
                    variant='contained'
                    color='primary'
                    startIcon={<Icon icon="download" />}
                    onClick={() => downloadLinksCsv(surveyLinks, programmeTitle)}
                >
                    Download URLs 
                </Button> )}
</Grid>
<Grid item xs={2}>
 {surveyLinks.length > 0 && (
<>

<Select
                    className={classes.surveyNameSelect}
                    label="Send To"
                    value={sendTo}
                    onChange={(e) => setSendTo(e.target.value as 'attendees'|'reviewers'|'none')}
                >   
                   
                        <MenuItem
                            key='none'
                            value='none'
                        >
                            Please select
                        </MenuItem>
                        <MenuItem
                            key='attendees'
                            value='attendees'
                        >
                            Attendees
                        </MenuItem>
                         <MenuItem
                            key='reviewers'
                            value='reviewers'
                        >
                            Reviewers
                        </MenuItem>
                    
                </Select>
               </> )}
</Grid>
<Grid item xs={2}>
 {surveyLinks.length > 0 && (
<>

                <Button
                    className={classes.downloadButton}
                    variant='contained'
                    color='primary'
                    disabled={sent || !sendTo || sendTo ==='none'}
                    startIcon={<Icon icon="address-card" />}
                    onClick={() => doSendToNonCompleters()}
                >
                    {sent ? 'Sent to non-completers' : 'Resend to non-completers'} 
                </Button>
<div>Last sent: {lastSent ? moment(lastSent).toLocaleString() : 'No data'}</div>
</> )}
</Grid>
           
           
           </Grid>  )}
           
           <SurveyUrlsTable data={surveyLinks} />
        </div>
    )
}