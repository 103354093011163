import moment from 'moment';

export const formatDate =  (date?: string, placeholder: string = '-') =>
  date ? moment(date).format('DD/MM/YYYY') : placeholder;

export const formatOffset = (offset: any) => {
  if (!Number.isInteger(offset)) return '-'

  const sign = offset > 0
    ? '+ '
    : offset < 0
      ? '- '
      : ''
  return `${sign}${Math.abs(offset)} days${
    offset >= 7 || offset <= -7
    ? ` (${moment.duration(offset, 'days').humanize({ d:7, w:4, m:12 })})`
    : ''
  }`
}