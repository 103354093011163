import { api } from '.';
import { query_params } from '../helpers/api'

export const createRecordedAction = (body: {name: string} ) => 
	api.post('/recorded_action', body).then(response => response.data)

export const getRecordedAction = (actionId: string) => 
	api.get(`/recorded_action/${actionId}`).then(response => response.data)

export const listRecordedActions = (filter: any = {}, sort: any = {dateRecorded:-1}, cursor: any = {limit:5}) => 
	api.get(`/recorded_action/${query_params(filter, sort, cursor)}`).then(response => response.data)

export const updateRecordedAction = (id: string, body: { name: string }) =>
	api.put(`/recorded_action/${id}`, body).then(response => response.data)

export const deleteRecordedAction = (recordedActionId: string) => 
	api.delete(`/recorded_action/${recordedActionId}`).then(response => response.data)

	