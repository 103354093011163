import { api } from '.';
import { Tutor } from '../types';

export const findTutorById = (tutors: Tutor[], id?: string) => {
  if (!id) {
    return null;
  }

  return tutors.find(tutor => tutor.id === id);
}

export const getTutorById = (tutorId: string) =>
  api.get(`/tutor/${tutorId}`).then(response => response.data);

/** @Todo Add pagination params */
export const getTutorList = () =>
  api.get('/tutor').then((response: any) => response.data.data)

export const getTutorListForProgramme = (programmeId:string) =>
  api.get(`/tutor/programme/${programmeId}`).then((response: any) => response.data.data)

export const saveTutor = (tutor: any) =>
  api.put(`/tutor`, tutor).then(response => response.data);

export const createTutor = (tutor: any) =>
  api.post(`/tutor`, tutor).then(response => response.data);

export const deleteTutorById = (tutorId: string) =>
  api.delete(`/tutor/${tutorId}`).then(response => tutorId);
