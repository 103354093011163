import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { useHistory } from 'react-router-dom';
import { difference } from 'lodash'
import { getCompetenceList } from '../../../../../api/competence';
import { Competence } from '../../../../../types'
import { UserContext, isPlatformAdmin } from 'src/components/user/context';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '90%'
  }
}));

interface CompetencesProps {
  selected: string[];
  onChange: (competences: string[]) => void
  disabled?: boolean;
}

const Competences = ({ selected, onChange, disabled }: CompetencesProps) => {
  const [competences, setCompetences] = useState<Competence[]>([])
  const userContext = useContext(UserContext)

  const reload = () => {
    getCompetenceList().then(setCompetences)
  }
  useEffect(reload, [])

  const selected_competencies = competences.filter(competency => selected.includes(competency.id))
  const available_competencies = competences.filter(competency =>
    selected.includes(competency.id) || competency.status === 'Active')
  const selected_ids = selected_competencies.map(competency => competency.id) //using this instead of selected becasue it is unique

  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event: any) => {
    const newSelection: string[] = event.target.value;
    const new_competences = newSelection.filter(s => s !== "-1")
    if (new_competences.length !== selected_ids.length || difference(new_competences, selected_ids).length > 0) {
      onChange(new_competences)
    }
  };

  const redirectToCreateNewCompetence = () => {
    history.push({
      pathname: '/settings/competences'
    });
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <FormControl className={classes.formControl}>
          {isPlatformAdmin(userContext.user) && <Select
            multiple
            value={selected_ids}
            input={<Input />}
            onChange={handleChange}
            displayEmpty
            disabled={disabled}
            renderValue={() => <span>Change Competencies</span>}
          >
            <MenuItem value="-1" onClick={redirectToCreateNewCompetence}>
              <LibraryAddIcon /> Add Competency
            </MenuItem>
            {available_competencies.map(competence =>
              <MenuItem key={competence.id} value={competence.id}>
                {selected_ids.includes(competence.id) ? <RemoveIcon /> : <AddIcon />}{competence.name}
              </MenuItem>
            )}
          </Select>}
        </FormControl>
      </Grid>

      <Grid item xs={9}>
        <Grid container key="competences_dropdown">
          {selected_competencies.map(competence =>
            <Grid item xs={4} key={competence.id}>
              {competence.name}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid >
  );
};

export default Competences;
