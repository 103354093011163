import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import { makeStyles, TextField, Grid, Select, FormControl, InputLabel, MenuItem, Checkbox } from '@material-ui/core'
import { Programme, Client } from '../../../../../types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getClientList } from 'src/api/client';
import { cloneProgramme } from 'src/api/programme';
import { remove } from 'lodash'

type props = { 
	programme: Programme;
	isOpen: boolean;
	closeModal: () => void;
}

const customStyles = {
	content: {
	  width: '30%',
	  top: '50%',
	  left: '50%',
	  right: 'auto',
	  bottom: 'auto',
	  marginRight: '-50%',
	  transform: 'translate(-50%, -50%)'
	}
};

const useStyles = makeStyles(() => ({
	input: {
		width: '100%'
	},
	form_button: {
		background: '#26377B',
		color: '#FFFFFF',
		borderRadius: 10,
		padding: 5,
		marginTop: 10,
		marginRight: 10,
		textAlign: 'center',
		cursor: 'pointer'
	},
	delete_button: {
		background: 'red',
	},
}));

type OptionalInclude = 'competences' | 'tutors' | 'tags' | 'attendees' | 'reviewers'

const DuplicateProgrammeDetails:React.FC<props> = ({ programme, isOpen, closeModal }) => {
	const history = useHistory();
	const classes = useStyles();

	const [loading, setLoading] = useState(true)
	const [duplicateProgramme, setDuplicateProgramme] = useState<Programme>(programme)
	const [clients, setClients] = useState<Client[]>([])

	const [optionalInclude, setOptionalInclude] = useState<OptionalInclude[]>([])

	useEffect(() => {
		void (async () => {
			setLoading(true)
			const foundClients = await getClientList()
			setClients(foundClients)
			const currentTime = new Date().toISOString()
			setDuplicateProgramme({ 
				...programme,
				start: currentTime,
				end: currentTime,
				title: `${programme.title} (copy)`,
				client: foundClients.find(({ id }: { id: string }) => id === programme.client)?.id
			})
			setLoading(false)
		})()
	},[programme])

	const callCloneProgramme = async () => {
		setLoading(true)
		const newProgramme = await cloneProgramme({ 
			programme: {
				...duplicateProgramme,
        programme_status: 'Draft',
				tags: optionalInclude.includes('tags') ? duplicateProgramme.tags : [],
				competences: optionalInclude.includes('competences') ? duplicateProgramme.competences : [],
				tutors: optionalInclude.includes('tutors') ? duplicateProgramme.tutors : [],
			},
			cloneAttendees: optionalInclude.includes('attendees'),
			cloneReviewers: (optionalInclude.includes('attendees') && optionalInclude.includes('reviewers')),
		})
		setLoading(false)
		history.push(`/programme/${newProgramme.id}`)
	}

	const addRemoveOptionalInclude = (param: OptionalInclude) => {
		if (optionalInclude.includes(param)) {
			setOptionalInclude(remove(optionalInclude, (option) => option !== param))
		} else {
			setOptionalInclude([...optionalInclude, param])
		}
	}

	return (
		<div>
			<Modal
				isOpen={isOpen}
				onRequestClose={closeModal}
				contentLabel="Duplicate Programme"
				style={customStyles}
			>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Grid
						container
						direction="column"
						justify="center"
					>
						<Grid item xs={9}>
							<TextField
								label="Title"
								value={duplicateProgramme?.title}
								className={classes.input}
								disabled={loading}
								onChange={(e) => {
									setDuplicateProgramme({ ...duplicateProgramme, title: e.target.value })
								}}
							/>
						</Grid>

						<Grid item xs={9}>
							<FormControl className={classes.input}>
								<InputLabel>Client</InputLabel>
								<Select
									value={duplicateProgramme?.client}
									onChange={(e: any) => {
										setDuplicateProgramme({ ...duplicateProgramme, client: e.target.value })
									}}
									disabled={loading}
								>
								{clients.map((client: any) => (
									<MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
								))}
								</Select>
							</FormControl>
						</Grid>
										
						<Grid item xs={9}>
							<TextField
								label="Programme Admin Email"
								value={duplicateProgramme?.programme_admin_email}
								className={classes.input}
								disabled={loading}
								onChange={(e) => {
									setDuplicateProgramme({ ...duplicateProgramme, programme_admin_email: e.target.value })
								}}
							/>
						</Grid>

						<Grid item xs={9}>
							<KeyboardDatePicker
								label="Start Date"
								disabled={loading}
								className={classes.input}
								disableToolbar
								variant="inline"
								format="DD/MM/YYYY"
								value={duplicateProgramme?.start}
								onChange={(date: any) => {
									setDuplicateProgramme({ ...duplicateProgramme, start: date })
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								autoOk
							/>
						</Grid>

						<Grid item xs={9}>
							<KeyboardDatePicker
								label="End Date"
								className={classes.input}
								disableToolbar
								variant="inline"
								format="DD/MM/YYYY"
								disabled={loading}
								value={duplicateProgramme?.end}
								onChange={(date: any) => {
									setDuplicateProgramme({ ...duplicateProgramme, end: date })
								}}
								KeyboardButtonProps={{
								'aria-label': 'change date',
								}}
								autoOk
							/>
						</Grid>

						<Grid container xs={12}>
							<Grid item onClick={() => addRemoveOptionalInclude('competences')}>
								<Checkbox
									checked={optionalInclude.includes('competences')}
									color={'primary'}
									disabled={loading}
								/>
								Copy Competencies
							</Grid>
							<Grid item onClick={() => addRemoveOptionalInclude('tutors')}>
								<Checkbox
									checked={optionalInclude.includes('tutors')}
									color={'primary'}
									disabled={loading}
								/>
								Copy Tutors
							</Grid>
							<Grid item onClick={() => addRemoveOptionalInclude('tags')}>
								<Checkbox
									checked={optionalInclude.includes('tags')}
									color={'primary'}
									disabled={loading}
								/>
								Copy Tags
							</Grid>

							<Grid container>
								<Grid item onClick={() => addRemoveOptionalInclude('attendees')}>
									<Checkbox
										checked={optionalInclude.includes('attendees')}
										color={'primary'}
										disabled={loading}
									/>
									Copy Attendees
								</Grid>
								{optionalInclude.includes('attendees') && (
									<Grid item onClick={() => addRemoveOptionalInclude('reviewers')}>
										<Checkbox
											checked={optionalInclude.includes('reviewers')}
											color={'primary'}
											disabled={loading}
										/>
										Copy Reviewers
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid container direction="row">
						<Grid item xs={3}>
							<div className={classes.form_button} onClick={(callCloneProgramme)}>
								Create
							</div>
						</Grid>
						<Grid item xs={3}>
							<div className={classes.form_button} onClick={closeModal}>
								Cancel
							</div>
						</Grid>
					</Grid>
				</MuiPickersUtilsProvider>
			</Modal>
		</div>
	)
}

export default DuplicateProgrammeDetails