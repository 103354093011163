import React, { useEffect, useState, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import logo_blue from '../../assets/logo_blue.png';
import { logout, setpass } from '../../api/user'

const useStyles = makeStyles((theme) => ({
  background: {
    height: '100%',
    padding: '40',
    background: `url(https://user-images.githubusercontent.com/24764236/53799830-40874680-3f33-11e9-8b09-050791b01b22.jpg) no-repeat center center fixed; `,
    backgroundSize: 'cover',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  login_form: {
    flexGrow: 1,
    padding: 5,
    backgroundColor: 'white',
    width: '50%',
    marginTop: '50px',
    display: 'inline-block'
  },
  grid_item: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'center'
  },
  input: {
    marginRight: theme.spacing(2),
    width: `calc(50% - ${theme.spacing(2)}px)`
  },
  button: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    marginTop: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#26377B',
  },
  alert: {
    marginBottom: theme.spacing(2)
  }
}));

export const Reset: FC = () => {
  useEffect(() => {logout()}, []) //if someone is logged in and follows a link to reset it can be confusing who they are logged in as
  const { one_time_token } = useParams()
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [busy, setBusy] = useState(false);
  const history = useHistory()

  const classes = useStyles();

  const onChangePassword = (event: any) => setPassword(event.target.value);

  const submit = (event: any) => {
    setBusy(true)
    event.preventDefault()
    setpass({
      one_time_token,
      password
    })
      .then(user => {
        setHasError(false)
        setBusy(false)
        setPassword('')
        history.push(
          {
            pathname: "/login"
          }
        )
      })
      .catch(() => {
        setBusy(false)
        setHasError(true)
        setPassword('')
      })
  }

  return (
      <div className={classes.background}>
        <div className={classes.login_form}>

          <Grid container>
            {hasError && !busy && (
              <Grid item xs={8} className={classes.grid_item}>
                <Alert
                  className={classes.alert}
                  variant="filled"
                  severity="error"
                >
                  There was a problem updating your password. Please call your administrator.
                </Alert>
              </Grid>
            )}

            <Grid item xs={8} className={classes.grid_item}>
              <form noValidate autoComplete="off">
                <TextField
                  className={classes.input}
                  label="password"
                  variant="outlined"
                  inputProps={{ type: 'password' }}
                  value={password}
                  onChange={onChangePassword}
                  error={password.length < 10 && password.length > 0}
                  helperText='10 characters minimum length'
                />
              </form>
            </Grid>

            <Grid item xs={8} className={classes.grid_item}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={submit}
                disabled={password.length < 10}
              >
                Update Password
              </Button>
              <div className={classes.logo}><img src={logo_blue} alt="logo" height="30px" /></div>

            </Grid>
          </Grid>
        </div>
      </div>
    );
}

