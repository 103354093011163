import React, { useState, useEffect, FC } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { Option, Statement } from '../../../../types'
import { createQuestion, saveQuestion, deleteQuestionById } from '../../../../api/question'
import { getOptionByQuestion, createOption, deleteOptionById } from '../../../../api/option'
import { useStyles } from '../../../../theme/styles'

export const option_order = (left: Option, right: Option) => left.order - right.order

interface Props {
  statement: Statement
  update: (statement: Statement) => void
  close: () => void
}

export const StatementDialog: FC<Props> = ({ statement, close, update }: Props) => {
  const [self_review_text, setSelfReviewText] = useState<string>(statement.self_review_text)
  const changed = self_review_text !== statement.self_review_text

  const [busy, setBusy] = useState<boolean>(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false)

  const [options, setOptions] = useState<Option[]>([])
  const reload = () => {
    if (statement.id !== 'new') {
      setBusy(true)
      getOptionByQuestion(statement.id)
        .then(result => {
          setBusy(false)
          setOptions(result)
        })
    }
  }
  useEffect(() => { reload() }, [])
  const [new_option, setNewOption] = useState<string>('')

  const classes = useStyles()

  const cancel = () => {
    close()
  };

  const removeOption = (option: Option) => () => {
    setBusy(true)
    deleteOptionById(option.id)
      .then(success => {
        update(statement)
        reload()
      })
  }

  const addOption = () => {
    setBusy(true)
    const order = options.reduce((acc, val) => acc > val.order ? acc : val.order, 0)
    createOption({
      question: statement.id,
      statement: new_option,
      order: order + 1
    }).then(success => {
      setNewOption('')
      update(statement)
      reload()
    })
  }

  const save = () => {
    setBusy(true)
    const action = statement.id === 'new'
      ? createQuestion
      : saveQuestion
    action({
      ...statement,
      self_review_text,
    })
      .then(success => {
        setBusy(false)
        update(success)
        close()
      })
      .catch(error => {
        setBusy(false)
      })
  }

  const destroy = () => {
    setBusy(true)
    deleteQuestionById(statement.id)
      .then(success => {
        setBusy(false)
        close()
      })
      .catch(error => {
        setBusy(false)
      })
  }

  return (
    <Dialog
      aria-labelledby="statement-modal"
      open={true}
      fullWidth={true}
      maxWidth={'lg'}>
      <DialogTitle id="statement-modal">{statement.id === 'new' ? 'New statement' : 'Edit statement'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.area}>
              <Box display='flex' flexDirection='row' >
                <TextField
                  style={{
                    flexGrow: 5
                  }}
                  label='Statement'
                  value={self_review_text}
                  className={classes.input}
                  required
                  onChange={(e) => setSelfReviewText(e.target.value)}
                />
                <Box p='1rem'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setSelfReviewText(statement.self_review_text)}
                    disabled={busy || !changed}
                  >Undo</Button>
                </Box>
                <Box p='1rem'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={save}
                    disabled={busy || !changed}
                  >Save</Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.area}>
              <FormLabel
                className={classes.tag}>
                Options
              </FormLabel>
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='row' justifyContent='flex-start'>
                  {
                    options.map(option => (
                      <Chip
                        key={option.id}
                        label={option.statement}
                        onDelete={removeOption(option)}
                      />
                    ))
                  }
                </Box>
                <Box display='flex' flexDirection='row'>
                  <TextField
                    label='New option'
                    value={new_option}
                    className={classes.input}
                    onChange={(e) => setNewOption(e.target.value)}
                  />
                  <Box p='1rem'>
                    <Button
                      variant="contained"
                      onClick={addOption}
                      disabled={busy || new_option === ''}
                      color="primary"
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          changed
            ? setOpenConfirmCancel(true)
            : close()
        }}>Done</Button>
        <Button
          onClick={() => setOpenConfirmDelete(true)}
          disabled={busy || statement.id === 'new'}
        >Delete</Button>
      </DialogActions>
      <Dialog
        aria-labelledby="statement-delete-modal"
        open={openConfirmDelete}
      >
        <DialogTitle id="statement-delete-modal">Confirm statement delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
          }}>Cancel</Button>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
            destroy()
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="statement-cancel-modal"
        open={openConfirmCancel}
      >
        <DialogTitle id="statement-delete-modal">You have unsaved changes!</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
          }}>Continue editing</Button>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
            cancel()
          }}>Discard changes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
