import React from 'react'

// Print reviewer's response date and score
const printSurveyData = (surveys, reviewers, display) => {
    return surveys.flatMap((survey, survey_index) => {
        return reviewers.flatMap((reviewer, i) => {
            const isShaded = i % 2 === 0
            const reviewer_response = survey.responses.find(response => response.reviewer === reviewer.id)
            const textColor = reviewer_response ? 'inherit' : 'red'

            return [
                <div
                    key={"response_rate_" + survey.id + "_" + reviewer.id}
                    className='response_rate'
                    style={{
                        gridRowStart: 7 + i,
                        gridColumnStart: 3 + 3 * survey_index,
                        border: '1px solid #dfdfdf',
                        borderTop: reviewer.self ? '1px solid #000' : '1px solid #dfdfdf',
                        ...isShaded ? {backgroundColor: 'lightgray'} : {},
                        color: textColor
                    }}
                >{reviewer_response?.date ?? 'X'}</div>,
                <div
                    key={"score_" + survey.id + "_" + reviewer.id}
                    className='score'
                    style={{
                        gridRowStart: 7 + i,
                        gridColumnStart: 4 + 3 * survey_index,
                        border: '1px solid #dfdfdf',
                        borderTop: reviewer.self ? '1px solid #000' : '1px solid #dfdfdf',
                        ...isShaded ? {backgroundColor: 'lightgray'} : {},
                        color: textColor
                    }}
                >{reviewer_response?.scores ?? '-'}</div>,
                <div
                    key={"na_" + survey.id + "_" + reviewer.id}
                    className='na'
                    style={{
                        gridRowStart: 7 + i,
                        gridColumnStart: 5 + 3 * survey_index,
                        border: '1px solid #dfdfdf',
                        borderTop: reviewer.self ? '1px solid #000' : '1px solid #dfdfdf',
                        ...isShaded ? {backgroundColor: 'lightgray'} : {},
                        color: textColor
                    }}
                >{reviewer_response?.na_count ?? '-'}</div>
            ]

        })
    })
}

// Show added total of survey scores
const addSurveyScores = (survey, reviewers) => {
    var scoreSums = []

    var scoreReviewerSum = 0
    var scoreSelfSum = 0

    survey.responses.map((response) => {
        reviewers.map(reviewer => {
            if (reviewer.id === response.reviewer) {
                if (reviewer.email !== reviewer.attendee.email) {
                    scoreReviewerSum += response.scores
                } else {
                    scoreSelfSum += response.scores
                }
            }
        })
    })

    scoreSums["self"] = scoreSelfSum
    scoreSums["reviewer"] = scoreReviewerSum

    return scoreSums
}

// Show added total of survey scores
const addNAs = (survey, reviewers) => {
    var naSums = []

    var naReviewerSum = 0
    var naSelfSum = 0

    survey.responses.map((response) => {
        reviewers.map(reviewer => {
            if (reviewer.id === response.reviewer) {
                if (reviewer.email !== reviewer.attendee.email) {
                    naReviewerSum += response.na_count
                } else {
                    naSelfSum += response.na_count
                }
            }
        })
    })

    naSums["self"] = naSelfSum
    naSums["reviewer"] = naReviewerSum

    return naSums
}

const calculateResponseRate = (survey, reviewers) => {
    var responseRates = []
    console.log(`[calc=${survey.id}] -- reviewers`, reviewers)
    var countAttendees = 0
    var attendeeResponses = 0
    var reviewerResponses = 0
    survey.responses.forEach(res=>{
        const match = reviewers.filter(reviewer=> reviewer.id===res.reviewer)
        if(!match || match.length===0){
            console.log(`[calc=${survey.id}] -- no reviewers found for response`, res)
        }
    })
    reviewers.flatMap((reviewer) => {
        countAttendees = reviewer.email === reviewer.attendee.email
            ? (countAttendees + 1)
            : (countAttendees)

        survey.responses.flatMap((response) => {
            if(reviewer.id === response.reviewer){
                if(reviewer.email === reviewer.attendee.email){
                    attendeeResponses =  attendeeResponses + 1
                }else{
                    reviewerResponses =  reviewerResponses + 1
                }
            }
        })
    })
    console.log(`[calc=${survey.id}] -- countAttendees`, countAttendees)
    console.log(`[calc=${survey.id}] -- attendeeResponses`, attendeeResponses)
    const noOfReviewers = reviewers.length - countAttendees
    console.log(`[calc=${survey.id}] -- noOfReviewers`, noOfReviewers)
    console.log(`[calc=${survey.id}] -- reviewerResponses`, reviewerResponses)
    responseRates["self"] =
        countAttendees === 0
            ?
            ""
            :
            ((attendeeResponses / countAttendees) * 100).toFixed(2) + "%"

    responseRates["reviewer"] =
        noOfReviewers === 0
            ?
            ""
            :
            ((reviewerResponses / noOfReviewers) * 100).toFixed(2) + "%"

    return responseRates
}

// Print general survey data
export const printSurveyColumns = (reviewers, filtered_reviewers, surveys, display) => {
    return surveys.flatMap((survey, survey_index) => {

        const responseRates = calculateResponseRate(survey, reviewers)

        const scoreSums = addSurveyScores(survey, reviewers)

        const naCount = addNAs(survey, reviewers)

        return [
            <div
                key={"survey_responses_" + survey.id}
                className="survey"
                style={{
                    gridColumn: (3 + 3 * survey_index) + '/ span 3',
                    gridRowStart: '1',
                    border: '1px solid #dfdfdf'
                }}
            ><b>{survey.name}</b></div>,

            <div
                key={"response_rate_tag_" + survey.id}
                className="response_rate_tag"
                style={{
                    gridColumnStart: 3 + 3 * survey_index,
                    gridRowStart: 2,
                    border: '1px solid #dfdfdf'
                }}
            ><b>Response rate</b></div>,

            <div
                key={"scores_tag_" + survey.id}
                className="scores_tag"
                style={{
                    gridColumnStart: 4 + 3 * survey_index,
                    gridRowStart: 2,
                    border: '1px solid #dfdfdf'
                }}
            ><b>Scores</b></div>,

            <div
                key={"na_tag_" + survey.id}
                className="na_tag"
                style={{
                    gridColumnStart: 5 + 3 * survey_index,
                    gridRowStart: 2,
                    border: '1px solid #dfdfdf'
                }}
            ><b>NA</b></div>,

            <div
                key={"response_date_tag_" + survey.id}
                className="response_date_tag"
                style={{
                    gridColumnStart: 3 + 3 * survey_index,
                    gridRowStart: 6,
                    border: '1px solid #dfdfdf'
                }}
            ><b>Response date</b></div>,

            <div
                key={"scores_tag_2_" + survey.id}
                className="scores_tag_2"
                style={{
                    gridColumnStart: 4 + 3 * survey_index,
                    gridRowStart: 6,
                    border: '1px solid #dfdfdf'
                }}
            ><b>Scores</b></div>,

            <div
                key={"na_tag_2_" + survey.id}
                className="na_tag_2"
                style={{
                    gridColumnStart: 5 + 3 * survey_index,
                    gridRowStart: 6,
                    border: '1px solid #dfdfdf'
                }}
            ><b>NA</b></div>,

            <div
                key={"reviewer_response_rate_" + survey.id}
                className="reviewer_response_rate"
                style={{
                    gridColumnStart: 3 + 3 * survey_index,
                    gridRowStart: 4,
                    border: '1px solid #dfdfdf'
                }}
            >
                {responseRates["reviewer"]}
            </div>,

            <div
                key={"self_response_rate_" + survey.id}
                className="self_response_rate"
                style={{
                    gridColumnStart: 3 + 3 * survey_index,
                    gridRowStart: 3,
                    border: '1px solid #dfdfdf'
                }}
            >
                {responseRates["self"]}
            </div>,

            <div
                key={"self_score_" + survey.id}
                className="self_score"
                style={{
                    gridColumnStart: 4 + 3 * survey_index,
                    gridRowStart: '3',
                    border: '1px solid #dfdfdf'
                }}
            >
                {
                    scoreSums["self"]
                }
            </div>,

            <div
                key={"reviewer_score_" + survey.id}
                className="reviewer_score"
                style={{
                    gridColumnStart: 4 + 3 * survey_index,
                    gridRowStart: '4',
                    border: '1px solid #dfdfdf'
                }}
            >
                {
                    scoreSums["reviewer"]
                }
            </div>,

            <div
                key={"self_na_" + survey.id}
                className="self_na"
                style={{
                    gridColumnStart: 5 + 3 * survey_index,
                    gridRowStart: '3',
                    border: '1px solid #dfdfdf'
                }}
            >
                {
                    naCount["self"]
                }
            </div>,

            <div
                key={"reviewer_na_" + survey.id}
                className="reviewer_na"
                style={{
                    gridColumnStart: 5 + 3 * survey_index,
                    gridRowStart: '4',
                    border: '1px solid #dfdfdf'
                }}
            >
                {
                    naCount["reviewer"]
                }
            </div>,

            printSurveyData(surveys, filtered_reviewers, display)
        ]
    })
}