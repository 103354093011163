import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import OpensideTheme from './theme/index';
import SurveyElements from './components/SurveyElements'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  submitSurveyButton: {
    margin: 10,
    backgroundColor: "#26377B"
  },
  footer: {
    marginTop: 200,
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "LightGray",
    color: "white",
  },
  progress: {
    margin: 0
  },
  //TODO: apply sitewide using themes/index.js or disable global override to 14px.
  h1: { fontSize: '2em' },
  h2: { fontSize: '1.5em' },
  h3: { fontSize: '1.17em' },
  h4: { fontSize: '1.02em' },
  navbar: {
    "& button": {
      display: "none"
    }
  }
});

class Survey extends React.Component {

  render() {
    const {
      classes,
      attendee,
      reviewer,
      survey,
      selfReviewing,
      attendeeName,
      questions,
      handleValueChange,
      handleAnswerTimeXOpportunity,
      handleAnswerTextChange,
      handleAddEvidence,
      handleRemoveEvidence,
      errorMessage,
      handleSubmit
    } = this.props;

    return (
      <MuiThemeProvider theme={OpensideTheme}>
        <div className={classes.root}>
          <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: '30px' }}>
            <Grid xs={11} sm={7} md={7} lg={4} item={true}>
              <SurveyElements
                classes={classes}
                selfReviewing={selfReviewing}
                attendeeName={attendeeName}
                questions={questions}
                handleValueChange={handleValueChange}
                handleAnswerTimeXOpportunity={handleAnswerTimeXOpportunity}
                handleAnswerTextChange={handleAnswerTextChange}
                handleAddEvidence={handleAddEvidence}
                handleRemoveEvidence={handleRemoveEvidence}
                errorMessage={errorMessage}
                attendee={attendee}
                reviewer={reviewer}
                survey={survey}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(Survey);
