import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import HeadBar from '../HeadBar';

const useStyles = makeStyles(() => ({
  content: {
    padding: 10
  }
}));

interface PageProps {
  navBar?: React.ReactNode;
  children?: React.ReactNode;
};

const Page = ({ navBar, children } : PageProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadBar />
      </Grid>

      {/* @todo possibly just have items passed in, then render generic navbar here */}
      {navBar && (
        <Grid item xs={12}>
          {navBar}
        </Grid>
      )}

      <Grid item xs={12}>
        <div className={classes.content}>
          <Grid container>
            {children}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default Page;
