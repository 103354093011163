import React, { useState, FC } from 'react';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { EmailSchema } from '../../../../types'
import { create_email_schema, destroy_email_schema, update_email_schema } from '../../../../api/email_schema'
import { useStyles } from '../../../../theme/styles'
import CustomEmailEditorComponent from '../../../../components/email/CustomEmailEditorComponent'

interface Props {
  email: EmailSchema
  close: () => void
}

export const EmailSchemaDialog: FC<Props> = ({ email, close }: Props) => {
  const [email_alias, setEmailAlias] = useState<string>(email.email_alias)
  const [survey_date_offset_days, setOffset] = useState<number>(email.survey_date_offset_days)
  const [recipient_group, setRecipientGroup] = useState<string>(email.recipient_group)
  const [email_template, setEmailTemplate] = useState<string>(email.email_template)
  const [email_subject, setEmailSubject] = useState<string>(email.email_subject)
  const [email_template_json, setEmailTemplateJson] = useState<string>(email.email_template_json)
  const changed = email_alias !== email.email_alias ||
    survey_date_offset_days !== email.survey_date_offset_days ||
    email_subject !== email.email_subject ||
    email_template_json !== email.email_template_json ||
    recipient_group !== email.recipient_group

  const [busy, setBusy] = useState<boolean>(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
  const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false)
  const classes = useStyles()

  const cancel = () => {
    close()
  };

  const save = () => {
    setBusy(true)
    const action = email.id === 'new'
      ? create_email_schema
      : update_email_schema
    action({
      ...email,
      email_alias,
      survey_date_offset_days,
      recipient_group,
      email_template,
      email_subject,
      email_template_json
    })
    .then(success => {
      setBusy(false)
      close()
    })
    .catch(error => {
      setBusy(false)
    })
  }

  const destroy = () => {
    setBusy(true)
    destroy_email_schema(email)
    .then(success => {
      setBusy(false)
      close()
    })
    .catch(error => {
      setBusy(false)
    })
  }

  return (
    <Dialog
      aria-labelledby="email-schema-modal"
      open={true}
      fullWidth={true}
      maxWidth={'lg'}>
      <DialogTitle id="email-schema-modal">{email.id === undefined ? 'New email' : 'Edit email'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='Alias'
              value={email_alias}
              className={classes.input}
              required
              onChange={(e) => setEmailAlias(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Subject'
              value={email_subject}
              className={classes.input}
              required
              onChange={(e) => setEmailSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Days after start of programme'
              type='number'
              value={survey_date_offset_days}
              className={classes.input}
              required
              onChange={(e) => setOffset(parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Recipents'
              select
              value={recipient_group}
              className={classes.input}
              required
              onChange={(e) => setRecipientGroup(e.target.value)}
            >
              {["All Attendees", "Late Attendees", "All Reviewers", "Late Reviewers", "Programme Admin", "Escalation"].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <CustomEmailEditorComponent
              text_body_json={email_template_json}
              setTextBodyJson={setEmailTemplateJson}
              setTextBodyHtml={setEmailTemplate}
              disabled={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {email.id !== 'new' &&
          <Button
            onClick={() => setOpenConfirmDelete(true)}
            disabled={busy}
          >Delete</Button>
        }
        <Button
          onClick={() =>
            changed
              ? setOpenConfirmCancel(true)
              : cancel()
          }
          disabled={busy}
        >Cancel</Button>
        <Button
          onClick={save}
          disabled={busy || !changed}
        >Save</Button>
      </DialogActions>
      <Dialog
        aria-labelledby="email-schema-delete-modal"
        open={openConfirmDelete}
      >
        <DialogTitle id="email-schema-delete-modal">Confirm email delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
          }}>Cancel</Button>
          <Button onClick={() => {
            setOpenConfirmDelete(false)
            destroy()
          }}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="email-schema-cancel-modal"
        open={openConfirmCancel}
      >
        <DialogTitle id="email-schema-delete-modal">You have unsaved changes!</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
          }}>Continue editing</Button>
          <Button onClick={() => {
            setOpenConfirmCancel(false)
            cancel()
          }}>Discard changes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  )
}
