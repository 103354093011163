import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

import { saveAttendee, deleteAttendeeById, createNew as createNewAttendee } from '../../../../../../api/attendee';

import Icon from '../../../../../Common/Icon';
import { Attendee } from '../../../../../../types';

const customStyles = {
  content: {
    width: '30%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const useStyles = makeStyles(() => ({

  input: {
    width: '100%'
  },
  form_button: {
    background: '#26377B',
    color: '#FFFFFF',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
    marginRight: 10,
    textAlign: 'center',
    width: 100,
    cursor: 'pointer'
  },
  delete_button: {
    background: 'red',
  },
}));

interface EditAttendeeProps {
  isModalOpen: boolean;
  closeModal: () => void;
  attendee: Attendee;
}

const EditAttendee = ({ isModalOpen, closeModal, attendee }: EditAttendeeProps) => {
  const isValid = (attendee: any) => {
    if (attendee === undefined || attendee.name === undefined || attendee.name == "") {
      return false;
    }
    if (attendee.email === undefined || attendee.email == "") {
      return false;
    }
    if (attendee.programme === undefined || attendee.programme == "") {
      return false;
    }
    if (attendee.escalation_path_emails !== undefined && attendee.escalation_path_emails !== "") {
      return attendee.escalation_path_emails.match(/[A-Za-z0-9+.-_]+@[A-Za-z0-9+.-_]+/) !== null
    }
    return true;
  }

  const [updatedAttendee, setUpdatedAttendee] = useState<Attendee>(attendee)

  useEffect(() => {
    setUpdatedAttendee(attendee);

  }, [attendee])
  const classes = useStyles();

  const save = () => {
    if (!isValid(updatedAttendee)) {
      return;
    }
    const action = updatedAttendee.id === undefined
      ? createNewAttendee
      : saveAttendee

    action(updatedAttendee)
      .then(closeModal) //TODO? also edit the self review entity
  }

  const deleteAttendee = () => {
    Swal.fire({
      title: 'Please confirm!',
      text: 'Are you sure you want to remove this attendee?',
      icon: 'warning',
      confirmButtonText: 'Confirm',
      showCancelButton: true
    }).then((choice: any) => {
      if (choice.isDismissed) {
        //noop
      } else if (choice.isConfirmed) {
        deleteAttendeeById(updatedAttendee.id)
          .then(closeModal)
      }
    })

  }


  return (

    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Manage Attendee"
    >

      <h2>Attendee</h2>
      <Grid container
        direction="column"
        justify="center"
      >
        <Grid item xs={9}>
          <TextField
            label="name"
            value={updatedAttendee.name}
            className={classes.input}
            onChange={(e) => {
              e.persist();
              if (updatedAttendee) {
                setUpdatedAttendee({ ...updatedAttendee, name: e.target.value })
              }
            }
            }
          />
        </Grid>

        <Grid item xs={9}>
          <TextField
            label="email"
            value={updatedAttendee.email}
            className={classes.input}
            onChange={(e) => {
              e.persist();
              if (updatedAttendee) {
                setUpdatedAttendee({ ...updatedAttendee, email: e.target.value })
              }
            }
            }
          />
        </Grid>

        <Grid item xs={9}>
          <TextField
            label="points of escalation emails"
            value={updatedAttendee.escalation_path_emails}
            className={classes.input}
            onChange={(e) => {
              e.persist();
              if (updatedAttendee) {
                setUpdatedAttendee({ ...updatedAttendee, escalation_path_emails: e.target.value })
              }
            }
            }
          />
        </Grid>
      </Grid>

      <Grid container
        direction="row"
      >

        <div className={`${classes.form_button}`} onClick={save}>
          Save {isValid(updatedAttendee) ? <Icon icon="edit" /> : <Icon icon="exclamation" />}
        </div>
        {updatedAttendee.id && (
          <div className={`${classes.form_button} ${classes.delete_button}`} onClick={deleteAttendee}>
            Delete <Icon icon="trash-alt" />
          </div>
        )}

        <div className={classes.form_button} onClick={() => {
          closeModal();
        }}>
          Cancel
            </div>
      </Grid>

    </Modal>

  )
};

export default EditAttendee;
