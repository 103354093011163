import { api } from "../api"
import { Cursor, EmailSchema } from "../types"
import { query_params } from '../helpers/api'

export const create_email_schema = (email_schema: EmailSchema) =>
  api.post(`/email_schema/`, email_schema).then(response => response.data)

export const destroy_email_schema = (email_schema: EmailSchema) =>
  api.delete(`/email_schema/${email_schema.id}`).then(response => response.data)

export const update_email_schema = (email_schema: EmailSchema) =>
  api.put(`/email_schema/`, email_schema).then(response => response.data)

export const list_email_schema = (filter?: any, sort?: any, cursor?: Cursor) =>
  api.get(`/email_schema/${query_params(filter, sort, cursor)}`).then(response => response.data.data)

export const show_email_schema = (email_schema: EmailSchema) =>
  api.get(`/email_schema/${email_schema.id}`).then(response => response.data)
