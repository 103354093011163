import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import OpensideTheme from './theme/index';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  submitSurveyButton: {
    margin: 10,
    backgroundColor: "#26377B"
  },
  footer: {
    marginTop: 200,
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "LightGray",
    color: "white",
  },
  progress: {
    margin: 0
  },
  //TODO: apply sitewide using themes/index.js or disable global override to 14px.
  h1: { fontSize: '2em' },
  h2: { fontSize: '1.5em' },
  h3: { fontSize: '1.17em' },
  h4: { fontSize: '1.02em' },
  navbar: {
    "& button": {
      display: "none"
    }
  }
});

class SurveyLoading extends React.Component {

  render() {
    const { classes, selfReviewing, attendeeName, dismiss } = this.props;
    return (
      <MuiThemeProvider theme={OpensideTheme}>
        <div className={classes.root}>
          <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: '30px' }}>
            <Grid container direction="row" justify="center" alignItems="center" style={{ "height": "100vh" }}>
              <CircularProgress className={classes.progress} />
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(SurveyLoading);
