import React, { useEffect, useState, FC } from 'react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { SurveySchemaDialog } from './survey'
import { EmailSchemaDialog } from './email'
import { Validations } from './validations'
import { list_email_schema } from '../../../../api/email_schema'
import { list_survey_schema } from '../../../../api/survey_schema'
import { formatOffset } from '../../../../helpers/format'
import { useStyles } from '../../../../theme/styles'
import Icon from '../../../Common/Icon';
import { SurveySchema, EmailSchema } from '../../../../types'

const survey_order = (left: SurveySchema, right: SurveySchema) => left.programme_date_offset_days - right.programme_date_offset_days
const email_order = (left: EmailSchema, right: EmailSchema) => left.survey_date_offset_days - right.survey_date_offset_days

export const Schedule: FC = () => {
  const classes = useStyles();
  const [surveys, setSurveys] = useState<SurveySchema[]>([])
  const [busySurveys, setBusySurveys] = useState(false)
  const reloadSurveys = () => {
    setBusySurveys(true)
    list_survey_schema()
    .then(schemas => {
      setBusySurveys(false)
      setSurveys(schemas.sort(survey_order))
    })
    .catch(() => setBusySurveys(false))
  }
  useEffect(() => { reloadSurveys() }, [])

  const [emails, setEmails] = useState<EmailSchema[]>([])
  const [busyEmails, setBusyEmails] = useState(false)
  const reloadEmails = () => {
    setBusyEmails(true)
    list_email_schema()
    .then(schemas => {
      setBusyEmails(false)
      setEmails(schemas.sort(email_order))
    })
    .catch(() => setBusyEmails(false))
  }
  useEffect(() => { reloadEmails() }, [])

  const [surveyModal, setSurveyModal] = useState<SurveySchema|undefined>(undefined);
  const closeSurveyModal = () => {
    setSurveyModal(undefined)
    reloadSurveys()
  }
  const [emailModal, setEmailModal] = useState<EmailSchema|undefined>(undefined);
  const closeEmailModal = () => {
    setEmailModal(undefined)
    reloadEmails()
  }

  const Row = ({ xs, spacing, children }: any) => (
    <Grid item xs={xs}>
      <Grid container spacing={spacing}>
        {children}
      </Grid>
    </Grid>
  )

  const ActionCard = ({ xs, action, children }: any) => (
    <Grid item xs={xs}>
      <Card
        className={classes.schedule_card_blue}
        variant="outlined"
        onClick={action}
      >
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </Grid>
  )

  const IllustrationCard = ({ xs, children }: any) => (
    <Grid item xs={xs}>
      <Card
        className={classes.schedule_card_gray}
        variant="outlined"
      >
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </Grid>
  )

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card
          className={classes.description}
        >
          <CardContent>
            <div>
              All schedule updates will only apply to Programmes, Surveys and Emails created after the changes.
            </div>
            <div>
              Existing survey emails can only be updated individually in the Programme details page.
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Row xs={12} spacing={4}>
        <Validations surveys={surveys} emails={emails}/>
      </Row>
      <Row xs={12} spacing={4}>
        <Grid item xs={2} />
        <Row xs={8} spacing={2}>
          {emails.map(email => (
            <ActionCard
              key={email.id}
              xs={3}
              action={() => setEmailModal(email)}
            >
              <div className={classes.value}>{email.email_alias}</div>
              <div className={classes.tag}>{formatOffset(email.survey_date_offset_days)}</div>
              <div className={classes.tag}>{email.recipient_group}</div>
            </ActionCard>
          ))}
        </Row>
        <ActionCard xs={2} action={() => setEmailModal({
          id: 'new',
          email_alias: 'New Email',
          email_subject: 'Openside Survey',
          recipient_group: 'All Attendees',
          email_template: '',
          email_template_json: '',
          survey_date_offset_days: (emails.length * 7)
        })}>
          <Icon icon="plus" />Email
        </ActionCard>
      </Row>
      {surveys.map(survey => (
        <Row xs={12} spacing={4} key={survey.id}>
          <ActionCard xs={2} action={() => setSurveyModal(survey)}>
            <div className={classes.value}>{survey.survey_name}</div>
            <div className={classes.tag}>{formatOffset(survey.programme_date_offset_days)}</div>
          </ActionCard>
          <Row xs={8} spacing={2}>
            {emails.map(email => (
              <IllustrationCard xs={3} key={email.id}>
                <div className={classes.emailTitle}>{email.email_alias}</div>
                <div className={classes.emailTitle}>{formatOffset(survey.programme_date_offset_days + email.survey_date_offset_days)}</div>
              </IllustrationCard>
            ))}
          </Row>
          <Grid item xs={2} />
        </Row>
      )
      )}
      <Row xs={12} spacing={4}>
        <ActionCard xs={2} action={() => setSurveyModal({id:'new', survey_name:'New Survey', programme_date_offset_days:(surveys.length * 60), expires_after_days: 30, front_page_template_attendee: '', front_page_template_reviewer: ''})}>
          <Icon icon="plus" />Survey
          </ActionCard>
      </Row>
      {surveyModal && <SurveySchemaDialog close={closeSurveyModal} survey={surveyModal}/>}
      {emailModal && <EmailSchemaDialog close={closeEmailModal} email={emailModal}/>}
    </Grid>
  )
}