import { useHistory } from 'react-router-dom';

export const useNavigation = (pathname: string) => {
  const history = useHistory();
  return () => {
    history.push({
      pathname
    });
  }
}
export const useIdNavigation = (page: string) => {
  const history = useHistory();
  return (id:string) => {
    history.push({
      pathname: `${page}/${id}`
    });
  }
}