import React, { FC } from "react"
import { useLocation } from "react-router-dom"

export const PageNotFound : FC = () => {
  const { pathname } = useLocation()
  return (
  <div>
    <div>404: Page Not Found</div>
    <code>{pathname}</code>
  </div>
  )
}