import React, { useEffect, useState, FC } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Page from '../Layout/Page';
import Title from '../Common/Title';
import ActionButton from '../Common/ActionButton';
import { CompletedProgrammeRow } from './completed_row'
import { getAllProgrammes } from '../../api/programme'
import { useStyles } from '../../theme/styles'
import { useNavigation } from '../../helpers/navigation'
import { wildcard } from '../../helpers/api';
import { Programme } from '../../types';

export const CompletedProgrammes: FC = () => {
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState("");
  const [busy, setBusy] = useState(false)
  const [programmes, setProgrammes] = useState<Programme[]>([]);

  const filter = {
    programme_status: 'Completed',
    ...searchQuery === '' ? {} : { title: wildcard(searchQuery) }
  }
  const reload = () => {
    setBusy(true)
    getAllProgrammes(filter)
    .then(result => {
      setProgrammes(result)
      setBusy(false)
    })
    .catch(error => {
      setBusy(false)
    })
  }
  useEffect(reload, [searchQuery])

  const gotoProgrammeOverview = useNavigation("/programmes/overview")

  return (
    <Page>
      <Grid item xs={4}>
        <Title>Programmes - Archive</Title>
      </Grid>

      <Grid item xs={6}>
        <div className={classes.actionButtonContainer}>
          <ActionButton icon="list-alt" text="Live Programmes" onClick={gotoProgrammeOverview} />
        </div>
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          id="search_programme"
          label="Search"
          color="secondary"
          value={searchQuery}
          onChange={(event: any) => {
            setSearchQuery(event.target.value);
          }
          }
        />
      </Grid>
      <Grid className={classes.main} item xs={12}>
        <div className={classes.subHeading}>Completed Programmes</div>
        {busy && <div>Loading programmes...</div>}
        {programmes.length === 0
          ? <div>{`There are no completed programmes${searchQuery === '' ? '' : ' that match your search'}`}</div>
          : programmes.map(programme => <CompletedProgrammeRow programme={programme} key={programme.id} />)
        }
      </Grid>
    </Page>
  )
}