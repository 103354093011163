import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { Client, Programme, ProgrammeStatus } from '../../../../types';
import Icon from '../../../Common/Icon';
import Competences from './Competences';
import { Tutors } from './Tutors';
import StatusChangeBlock from './StatusChangeBlock';
import Surveys from './Surveys';
import Attendees from './Attendees';
import EditableField from '../../../Common/EditableField';
import EditProgrammeDetails from './EditProgrammeDetails';
import { useStyles } from '../../../../theme/styles';
import { getClientById } from '../../../../api/client';
import { getShowProgramme, saveProgramme } from '../../../../api/programme';
import TagsSelector from './Tags';
import DuplicateProgrammeDetails from './DuplicateProgrammeDetails';
import { UserContext, isOrgAdmin, isPlatformAdmin } from 'src/components/user/context';

export const ProgrammeDetails = () => {
  const userContext = useContext(UserContext)

  const classes = useStyles();
  const { programme_id } = useParams()

  const [programme, setProgramme] = useState<Programme | undefined>(undefined)
  const loadProgramme = () => {
    getShowProgramme(programme_id).then(setProgramme)
  }
  useEffect(loadProgramme, [programme_id])

  const [client, setClient] = useState<Client | undefined>(undefined)
  const load_client = () => {
    if (programme?.client !== undefined) {
      getClientById(programme.client)
        .then(setClient)
    }
  }
  useEffect(load_client, [programme?.client])

  const [editDetailsModalOpen, setEditDetailsModalOpen] = useState<boolean>(false);
  const toggleEditModal = () => setEditDetailsModalOpen(state => !state)

  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false)

  const saveStatusChange = (newStatus: ProgrammeStatus) => {
    return saveProgramme({
      id: programme_id,
      programme_status: newStatus
    })
      .then(loadProgramme)
  };

  const saveTutorsChange = (newTutors: string[]) => {
    saveProgramme({
      id: programme_id,
      tutors: newTutors
    })
      .then(loadProgramme)
  };

  const saveCompetencesChange = (newCompetences: string[]) => {
    saveProgramme({
      id: programme_id,
      competences: newCompetences
    })
      .then(loadProgramme)
  }

  const saveTagsChange = (tagIds: string[]) => {
    saveProgramme({
      id: programme_id,
      tags: tagIds,
    }).then(loadProgramme)
  }

  const disabled = programme?.programme_status !== 'Draft' || !isPlatformAdmin(userContext.user)

  const attendeeEditingEnabled = programme?.programme_status === 'Draft' && (isPlatformAdmin(userContext.user) || isOrgAdmin(userContext.user, programme?.client))
  console.log('-----attendeeEditingEnabled', attendeeEditingEnabled)
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <EditableField
              size="field_large"
              isEditing={false}
              label="Programme Title"
              value={programme?.title ?? '-'}
              onChange={val => { }}
              onToggleEdit={() => { }}
              onSave={() => { }}
              onCancel={() => { }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={3}>
            <EditableField
              size="field_large"
              isEditing={false}
              label="Client"
              value={client?.name ?? ''}
              onChange={val => { }}
              onToggleEdit={() => { }}
              onSave={() => { }}
              onCancel={() => { }}
              disabled={true}
            />
          </Grid>

          <Grid item xs={3}>
            <EditableField
              size="field_large"
              type="input"
              isEditing={false}
              label="Programme Admin Email"
              value={programme?.programme_admin_email ?? '-'}
              onChange={val => { }}
              onToggleEdit={() => { }}
              onSave={() => { }}
              onCancel={() => { }}
              validation={() => true}
              disabled={true}
            />
          </Grid>

          <Grid item xs={1}></Grid>

          <Grid container xs={2} direction="column">
            {isPlatformAdmin(userContext.user) && <Grid item>
              <Button
                variant='contained'
                color='primary'
                disabled={disabled}
                startIcon={<Icon icon="edit" />}
                onClick={toggleEditModal}
                style={{ width: '100%', marginBottom: '0.5em' }}
              >
                Edit Programme Details
              </Button>
            </Grid>}

            {isPlatformAdmin(userContext.user) && <Grid item>
              <Button
                variant='contained'
                color='primary'
                startIcon={<Icon icon="copy" />}
                onClick={() => setDuplicateModalOpen((prevVal) => !prevVal)}
                style={{ width: '100%' }}
              >
                Duplicate Programme
              </Button>
            </Grid>}
          </Grid>

          <Grid item xs={3}>
            <EditableField
              size="field_large"
              type="date"
              isEditing={false}
              label="Start Date"
              value={programme?.start ?? ''}
              onChange={val => { }}
              onToggleEdit={() => { }}
              onSave={() => { }}
              onCancel={() => { }}
              validation={() => true}
              disabled={true}
            />
          </Grid>

          <Grid item xs={3}>
            <EditableField
              size="field_large"
              type="date"
              isEditing={false}
              label="End Date"
              value={programme?.end ?? ''}
              onChange={val => { }}
              onToggleEdit={() => { }}
              onSave={() => { }}
              onCancel={() => { }}
              validation={() => true}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={classes.section}>
          <Grid item xs={12}>
            <div className={classes.tag}>Tutors</div>
          </Grid>
          <Grid item xs={12}>
            <Tutors programmeId={programme_id} selected={programme?.tutors || []} onChange={saveTutorsChange} disabled={disabled}/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={classes.section}>
          <Grid item xs={12}>
            <div className={classes.tag}>Competencies</div>
          </Grid>
          <Grid item xs={12}>
            <Competences selected={programme?.competences || []} onChange={saveCompetencesChange} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>

      {isPlatformAdmin(userContext.user) && <Grid item xs={12} >
        <Grid container className={classes.section}>
          <Grid item xs={12}>
            <div className={classes.tag}>Tags</div>
          </Grid>
          <Grid item xs={12}>
            <TagsSelector selected={programme?.tags || []} onChange={saveTagsChange} />
          </Grid>
        </Grid>
      </Grid>}

      <Grid item xs={12}>
        <Grid container className={classes.section}>
          <Grid item xs={12}>
            <div className={classes.attendeeTitleSection}>
              <Grid container>
                <Grid item xs={9}>
                  <div className={classes.tag}>Attendees</div>
                </Grid>
                <Grid item xs={3}>
                </Grid>
              </Grid>
            </div>

          </Grid>
          <Grid item xs={12}>
            <Attendees programme_id={programme_id} disabled={!attendeeEditingEnabled} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={classes.section}>
          <Grid item xs={12}>
            <div className={classes.tag}>Schedule</div>
          </Grid>
          <Grid item xs={12}>
            <Surveys programme_id={programme_id} disabled={disabled} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          className={classes.section}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {programme && <StatusChangeBlock status={programme?.programme_status} onChange={saveStatusChange} />}
        </Grid>
      </Grid>

      
      {programme && (
        <DuplicateProgrammeDetails
          programme={programme}
          isOpen={duplicateModalOpen}
          closeModal={() => {
            setDuplicateModalOpen(false)
          }}
        />
      )}

      {editDetailsModalOpen && programme &&
        <EditProgrammeDetails
          programme={programme}
          isModalOpen={editDetailsModalOpen}
          closeModal={() => {
            loadProgramme()
            toggleEditModal()
          }}
        />
      }

    </Grid>
  );
};

export default ProgrammeDetails;
