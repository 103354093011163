import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'

import { Email, Survey } from '../../../../../../types'
import { useStyles } from '../../../../../../theme/styles'

interface Props {
  survey: Survey
  previous: Survey | undefined
  emails: Email[]
}

const no_self = (emails: Email[]) => {
  return undefined === emails.find(email => email.recipient_group === 'All Attendees')
    ? ['Surveys must be sent to All Attendees']
    : []
}

const no_reviewer = (emails: Email[]) => {
  return undefined === emails.find(email => email.recipient_group === 'All Reviewers')
    ? ['Surveys must be sent to All Reviewers']
    : []
}

const reviewer_before_self = (emails: Email[]) => {
  const earliest_self = emails.find(email => email.recipient_group === 'All Attendees')
  const earliest_reviewer = emails.find(email => email.recipient_group === 'All Reviewers')
  return earliest_reviewer !== undefined &&
    earliest_self !== undefined &&
    moment(earliest_reviewer.date_scheduled) < moment(earliest_self.date_scheduled).add(7, 'days')
    ? ['Attendee should be given at least 7 days to respond before surveying Reviewer']
    : []
}

const late_before_all = (group: string) => (emails: Email[]) => {
  const earliest_all = emails.find(email => email.recipient_group === `All ${group}`)
  const earliest_late = emails.find(email => email.recipient_group === `Late ${group}`)
  return earliest_all !== undefined &&
    earliest_late !== undefined &&
    moment(earliest_late.date_scheduled) < moment(earliest_all.date_scheduled).add(3, 'days')
    ? [`All ${group} should be given at least 3 days to respond before sending chaser Late ${group}`]
    : []
}
const late_before_all_self = late_before_all('Attendees')
const late_before_all_reviewer = late_before_all('Reviewers')

const summary_before_reviewer = (emails: Email[]) => {
  const earliest_summary = emails.find(email => email.recipient_group === 'Programme Admin')
  const earliest_reviewer = emails.find(email => email.recipient_group === 'All Reviewers')
  return earliest_reviewer !== undefined &&
    earliest_summary !== undefined &&
    moment(earliest_summary.date_scheduled) < moment(earliest_reviewer.date_scheduled).add(7, 'days')
    ? ['Reviewers should be given at least 7 days to respond before sharing a Summary']
    : []
}

const escalation_before_reviewer = (emails: Email[]) => {
  const earliest_escalation = emails.find(email => email.recipient_group === 'Escalation')
  const earliest_reviewer = emails.find(email => email.recipient_group === 'All Reviewers')
  return earliest_reviewer !== undefined &&
    earliest_escalation !== undefined &&
    moment(earliest_escalation.date_scheduled) < moment(earliest_reviewer.date_scheduled).add(7, 'days')
    ? ['Reviewers should be given at least 7 days to respond before escalating to managers']
    : []
}
const scheduled_in_past = (emails:Email[]) => {
  const today = moment().startOf('day')
  return emails.find(email => email.date_sent === undefined && moment(email.date_scheduled) < today) !== undefined
  ? [`Email scheduled to send in the past`]
  : []
}

const email_after_expiry = (survey:Survey, emails:Email[]) => {
  return emails.find(email => email.date_sent === undefined && email.survey_date_offset_days > survey.expires_after_days) !== undefined
  ? [`Email scheduled after survey expiry`]
  : []
}

const survey_overlap = (survey: Survey, previous: Survey|undefined) => {
  return previous !== undefined &&
    survey.programme_date_offset_days - previous.programme_date_offset_days < previous.expires_after_days * 2
  ? ['The previous surveys last responses could be closer to this survey, consider either reducing the "Days to respond" for preceeding survey, or increasing the "Days after start of programme" of this survey, otherwise the results of surveys will blur']
  : []
}

export const Validations: FC<Props> = ({ survey, previous, emails }: Props) => {
  const classes = useStyles()

  const errors = [
    ...no_self(emails),
    ...no_reviewer(emails),
    ...reviewer_before_self(emails),
    ...late_before_all_self(emails),
    ...late_before_all_reviewer(emails),
    ...scheduled_in_past(emails),
    ...email_after_expiry(survey, emails),
    ...survey_overlap(survey, previous),
    ...summary_before_reviewer(emails),
    ...escalation_before_reviewer(emails),
  ]

  return (
    errors.length === 0 ? null :
      <Grid item xs={12}>
        {
          errors.map(error =>
            <Card
              className={classes.error}
              variant='outlined'
              key={error}
            >
              <CardContent
                className={classes.error}
              >
                <Typography>
                  {error}
                </Typography>
              </CardContent>
            </Card>
          )
        }
      </Grid>
  )
}
