import { api } from "../api"
import { SurveySchema, Cursor } from "../types"
import { query_params } from '../helpers/api'

export const update_survey_schema = (survey_schema: SurveySchema) =>
  api.put(`/survey_schema/`, survey_schema).then(response => response.data)

export const destroy_survey_schema = (survey_schema: SurveySchema) =>
  api.delete(`/survey_schema/${survey_schema.id}`).then(response => response.data)

export const list_survey_schema = (filter?: any, sort?: any, cursor?: Cursor) =>
  api.get(`/survey_schema/${query_params(filter, sort, cursor)}`).then(response => response.data.data)

export const create_survey_schema = (survey_schema: SurveySchema) =>
  api.post(`/survey_schema/`, survey_schema).then(response => response.data)

export const show_survey_schema = (survey_schema: SurveySchema) =>
  api.get(`/survey_schema/${survey_schema.id}`).then(response => response.data)
