import { api } from '.';
import { query_params } from '../helpers/api';

export const getQuestionById = (questionId: string) =>
  api.get(`/question/${questionId}`).then(response => response.data);

/** @Todo Add pagination params */
export const getQuestionList = () =>
  api.get('/question').then((response : any) => response.data)

export const getQuestionByCompetence = (competenceId:string) =>
  api.get(`/question/${query_params({competence:competenceId})}`)
  .then((response : any) => response.data.data)

export const saveQuestion = (question: any) =>
  api.put(`/question`, question).then(response => response.data);

export const createQuestion = (question: any) =>
  api.post(`/question`, question).then(response => response.data);

export const deleteQuestionById = (questionId: string) =>
  api.delete(`/question/${questionId}`).then(response => questionId);
