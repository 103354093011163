import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';

class TimeXOpportunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.timeXopportunity
    }
  }


  onChange = (event) => {
    //this is a workaround for the callback, this might need revising
    this.props.handleAnswerTimeXOpportunity(event, event.target.value)
    this.setState({
      selectedValue: event.target.value
    })
  }


  getSalutation() {
    if (this.props.selfReviewing) {
      return 'Have you'
    } else {
      return "Has " + this.props.attendeeName
    }
  }

  render() {
    let NO_TIME_NO_OPP = 'Had neither time nor opportunity'
    let NO_TIME = 'Had no time'
    let NO_OPP = 'Had no opportunity'
    let YES = 'Had both time and opportunity'
    let salutation = this.getSalutation()
    return (
      <div style={{ alignItems: 'center' }}>
        <p>{salutation} been given TIME and OPPORTUNITY to apply this skill?</p>
        <Table aria-label="spanning table" style={{ border: "none", width: '60%', marginLeft: '10%' }}>

          <TableBody style={{ border: "none" }}>
            <TableRow style={{ border: "none" }}>
              <TableCell style={{ border: "none" }} align="right" colSpan={2} />
              <TableCell align="center" style={{ border: "none", textAlign: 'center' }} colSpan={2}>
                Time
              </TableCell>

            </TableRow>

            <TableRow style={{ border: "none" }}>
              <TableCell style={{ border: "none" }} align="right" colSpan={2} />
              <TableCell style={{ border: "none" }} align="center">
                Yes
              </TableCell>
              <TableCell style={{ border: "none" }} align="center">
                No
              </TableCell>
            </TableRow>

            <TableRow style={{ border: "none" }}>
              <TableCell style={{ border: "none" }} rowSpan={2} align="center">Opportunity</TableCell>
              <TableCell style={{ border: "none" }}>Yes</TableCell>
              <TableCell class="MuiTableCell-alignCenter" style={{ border: "none", 'text-align': 'center' }}><Radio disabled={true} checked={this.state.selectedValue == YES} key='YesYes' value={YES} onChange={this.onChange} /></TableCell>
              <TableCell class="MuiTableCell-alignCenter" style={{ border: "none", 'text-align': 'center' }}><Radio checked={this.state.selectedValue == NO_TIME} key='YesNo' value={NO_TIME} onChange={this.onChange} /></TableCell>
            </TableRow>
            <TableRow style={{ border: "none" }}>
              <TableCell style={{ border: "none" }}>No</TableCell>
              <TableCell class="MuiTableCell-alignCenter" style={{ border: "none", 'text-align': 'center' }}><Radio checked={this.state.selectedValue == NO_OPP} key='NoYes' value={NO_OPP} onChange={this.onChange} /></TableCell>
              <TableCell class="MuiTableCell-alignCenter" style={{ border: "none", 'text-align': 'center' }}><Radio checked={this.state.selectedValue == NO_TIME_NO_OPP} key='NoNo' value={NO_TIME_NO_OPP} onChange={this.onChange} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }
}


export default TimeXOpportunity