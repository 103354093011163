import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { groupBy, sortBy } from 'lodash'
import { calculatePercentageDifference, CompetenceData } from '..';

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: '1em',
        width: '100%'
    },
    table: {
        border: '1px solid white',
        borderSpacing: 0,
        width: '100%'
    },
    row: {
        '&:nth-child(even)': {
            backgroundColor: '#e2e0e6'
        },
        '&:nth-child(odd)': {
            backgroundColor: '#c2c5d0'
        },
    },
    cell: {
        alignContent: 'center',
        borderBottom: '1px solid white',
        borderRight: '1px solid white',
        wordBreak: 'break-word',
        padding: '0.2em',
        textAlign: 'center'
    },
    bold: {
        fontWeight: 'bold'
    },
    tableHeader: {
        backgroundColor: '#1b2865',
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        fontSize: 32,
    },
    overallPerformance: {
        fontSize: 18,
        textAlign: 'center'
    },
    positivePerformance: {
        color: 'green'
    },
    negativePerformance: {
        color: 'red'
    }
}));

type Props = {
    type: 'attendee' | 'reviewer' | 'all'
    inputData?: CompetenceData[],
}

const SurveyCompetencyScoreAvgRangeTable:React.FC<Props> = ({ inputData, type }) => {
    const classes = useStyles();
    const [data, setData] = useState<CompetenceData[]>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        void (async () => {
            setLoading(true)
            
            setData(sortBy(inputData, 'survey','competence'))
            setLoading(false)
        })()
    },[inputData, type])   

    const sortedData = sortBy(Object.values(sortBy(groupBy(data, 'statement'), 'statement')), function(o) { return o && o.length>0 ? o[0].statement : undefined })
    return (
        <Grid className={classes.container} key={'grid-'+type}>
            <div key={'grid-div1-'+type} className={classes.tableHeader}>
                {type === 'attendee' ? 'Attendee' : 'Reviewer'}
            </div>
            <table key={'grid-table1-'+type} className={classes.table}>
                <tbody key={'grid-table1-body-'+type}>
                    <tr className={classes.row} key={'row1-'+type}>
                        <th className={classes.cell} key={'th1-'+type} >Competencies by average and range of responses</th>
                        {Object.keys(groupBy(data, 'surveyName')).map((survey) => (
                            <th className={classes.cell} key={survey+type}>{survey}</th>
                        ))}
                        <th className={classes.cell} key={'thop-'+type}>
                            <p key={'thopp-'+type} className={classes.overallPerformance}>Overall Performance</p>
                        </th>
                    </tr>
                    {sortedData.map((statements, index) => (
                        <tr className={classes.row} key={'c'+index+type}>
                            <td className={classes.cell} key={'td'+index+type}>{statements[0].statement}</td>
                            {statements.map((statement, index2) => (
                               
                                    <td className={classes.cell} key={'td2'+index+'-'+index2+type}>
                                        <p key={'td2p1'+index+'-'+index2+type} className={classes.bold}>{Math.round(statement.avgLevel)}</p>
                                        <p key={'td2p2'+index+'-'+index2+type} >{Math.round(statement.minLevel)}-{Math.round(statement.maxLevel)}</p>
                                    </td>        
                               
                            ))}
                            <td className={classes.cell} key={'cell1'+index+type}>
                                <p key={'cell1p1'+index+type} className={classes.overallPerformance}>
                                    <span key={'cell1p1span1'+index+type} className={calculatePercentageDifference(statements[0], statements[statements.length-1]) > 0 
                                        ? classes.positivePerformance 
                                        : classes.negativePerformance}
                                    >
                                        {Math.round(calculatePercentageDifference(statements[0], statements[statements.length-1]))}%
                                    </span>
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
           </table>
        </Grid>
    )
}

export default SurveyCompetencyScoreAvgRangeTable