import React, { useEffect, useState, FC } from 'react'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { CompetencePreview } from './competence_preview'
import { CompetenceDialog } from './competence_dialog'
import { queryCompetences } from '../../../../api/competence'
import { useStyles } from '../../../../theme/styles'
import Icon from '../../../Common/Icon';
import { Competence, Statement } from '../../../../types'
import { wildcard } from '../../../../helpers/api'

const competence_order = (left: Competence, right: Competence) => left.name.localeCompare(right.name)

export const Row = ({ xs, spacing, children }: any) => (
  <Grid item xs={xs}>
    <Grid container spacing={spacing}>
      {children}
    </Grid>
  </Grid>
)

export const CompetencyList: FC = () => {
  const classes = useStyles();
  const [statusFilter, setStatusFilter] = useState('Active')
  const [search, setSearch] = useState('')
  const [competences, setCompetences] = useState<Competence[]>([])
  const [competenceModal, setCompetenceModal] = useState<Competence & {statements:Statement[]} | undefined>(undefined);

  const reload = () => {
    queryCompetences({
      status: statusFilter,
      ...search === '' ? {} : { name: wildcard(search) }
    },
      { name: 1 }
    )
      .then(result => {
        setCompetences(result
          .filter(competence => competence.status === statusFilter)
          .sort(competence_order)
        )
      })
  }
  useEffect(reload, [statusFilter, search])

  const closeCompetenceModal = () => {
    reload()
    if( competenceModal?.id === 'new' ) {
      setStatusFilter('Draft')
    }
    setCompetenceModal(undefined)
  }

  return (
    <Grid container spacing={4}>
      <Row xs={12} spacing={4}>
        <Grid item xs={8}>
          <TextField
            label='Search'
            value={search}
            className={classes.input}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label='Status'
            select
            value={statusFilter}
            className={classes.input}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            {['Active', 'Draft', 'Archived'].map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => setCompetenceModal({
              id: 'new',
              name: '',
              status: 'Draft',
              statements: []
            })}
            color="primary"
            variant='contained'
          >
            <Box m={'0.5rem'}> <Icon icon="plus" /></Box>Competency
          </Button>
        </Grid>
      </Row>
      {competences.length === 0 && <Typography>No matching competencies</Typography>}
      {competences.map(competence => (
        <Row xs={12} spacing={4} key={competence.id}>
          <CompetencePreview
            competence={competence}
            openCompetence={(statements) => setCompetenceModal({...competence, statements})}
          />
        </Row>
      ))}
      {competenceModal && <CompetenceDialog close={closeCompetenceModal} competence={competenceModal} />}
    </Grid>
  )
}