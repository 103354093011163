import React, { FC } from 'react'
import * as d3 from 'd3'
import { Sample } from '../../../../types'

type YN = 'yes' | 'no'

export interface Reason {
  time: YN
  opportunity: YN
  count: number
}

interface Props {
  data: Sample[]
}

export const ReasonDots: FC<Props> = ({ data }: Props) => {
  const reasons = [
    { time: 'yes', opportunity: 'yes', count: data.reduce((sum, d) => sum + d.to_yy, 0) },
    { time: 'yes', opportunity: 'no', count: data.reduce((sum, d) => sum + d.to_yn, 0) },
    { time: 'no', opportunity: 'no', count: data.reduce((sum, d) => sum + d.to_nn, 0) },
    { time: 'no', opportunity: 'yes', count: data.reduce((sum, d) => sum + d.to_ny, 0) },
  ]
  const maxCount = reasons.reduce((max, reason) => max > reason.count ? max : reason.count, 0)

  const [size, margin] = [700, 40]

  const plotArea = size - 2 * margin
  const spacing = plotArea / 4
  const xScale = d3.scaleOrdinal<string, number>()
    .domain(['no', 'yes'])
    .range([margin + spacing, size - margin - spacing])
  const yScale = d3.scaleOrdinal<string, number>()
    .domain(['yes', 'no'])
    .range([margin + spacing, size - margin - spacing])
  const area = (r: number) => Math.pow(r, 2) * Math.PI
  const areaScale = d3.scaleLinear()
    .domain([0, maxCount])
    .range([0, area(spacing) * 0.9])

  const rScale: any = (count: number): any => {
    if (!count) return 0;
    if (areaScale(count) === undefined) return 0;
    const areaScaleCount: any = areaScale(count);
    if (areaScaleCount / Math.PI === undefined) return 0;
    return Math.sqrt(areaScaleCount / Math.PI)
  }
  const colour = d3.scaleOrdinal()
    .domain(['yesyes', 'yesno', 'noyes', 'nono'])
    .range(['#ff8000', '#26377B', 'RGBA(38,55,123,0.5)', 'red'])

  return (<div>
    <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
      {reasons.map(({ time, opportunity, count }, i) => <g
        key={i}
        transform={`translate(${xScale(opportunity) - spacing}, ${yScale(time) - spacing})`}
      >
        <circle
          key={i}
          cx={spacing}
          cy={spacing}
          r={rScale(count)}
          fill={colour(time + opportunity) as any}
        />
        <rect height={2 * spacing} width={2 * spacing} stroke='grey' fill='none' />
        <text
          style={{
            fontSize: "30px",
            textAnchor: "middle",
            transform: `translate(${spacing}px,${spacing + 10}px)`,
            fill: 'white'
          }}>
          {count}
        </text>
      </g>
      )}
      <text style={{
        fontSize: "24",
        textAnchor: "middle",
        transform: `translate(30px,${margin + 2 * spacing}px) rotate(270deg)`
      }}>Time</text>
      <text style={{
        fontSize: "12",
        textAnchor: "end",
        transform: `translate(${margin - 10}px,${margin + spacing}px)`
      }}>Yes</text>
      <text style={{
        fontSize: "12",
        textAnchor: "end",
        transform: `translate(${margin - 10}px,${margin + 3 * spacing}px)`
      }}>No</text>
      <text style={{
        fontSize: "24px",
        textAnchor: "middle",
        transform: `translate(${margin + 2 * spacing}px,${size - 10}px)`
      }}>Opportunity</text>
      <text style={{
        fontSize: "12",
        textAnchor: "end",
        transform: `translate(${margin + 3 * spacing}px,${size - 15}px)`
      }}>Yes</text>
      <text style={{
        fontSize: "12",
        textAnchor: "end",
        transform: `translate(${margin + 1 * spacing}px,${size - 15}px)`
      }}>No</text>
    </svg>
  </div>
  )
}