import React, { FC, useEffect, useState } from 'react'
import { Tag } from 'src/types';
import { createTag, updateTag, deleteTag } from '../../../../api/tag';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { Grid, TextField } from '@material-ui/core';
import Icon from '../../../Common/Icon';

const useStyles = makeStyles(() => ({
    input: {
      width: '100%'
    },
    form_button: {
      background: '#26377B',
      color: '#FFFFFF',
      borderRadius: 10,
      padding: 5,
      marginTop: 10,
      marginRight: 10,
      textAlign: 'center',
      width: 100,
      cursor: 'pointer'
    },
    delete_button: {
      background: 'red',
    },
}));

  
type props = {
    isOpen: boolean;
    closeModal: () => void;
    onDataChange: (tag: Tag) => void;
    onDelete: (tag: Tag) => void;
    tag: Tag;
}

const EditTagModal:FC<props> = ({ isOpen, tag, closeModal, onDataChange, onDelete}) => {
    const classes = useStyles();
    const [updatedTag, setUpdatedTag] = useState(tag)

    useEffect(() => { setUpdatedTag(tag) }, [tag])

    const save = async () => {
        if (tag.id !== "") {
            const result = await updateTag(updatedTag.id, { name: updatedTag.name })
            onDataChange(result)
        } else {
            const result = await createTag(updatedTag)
            onDataChange(result)
        }
        closeModal()
    }

    const destroy = async () => {
        const choice = await Swal.fire({
            title: 'Please confirm!',
            text: 'Are you sure you want to remove this tag?',
            icon: 'warning',
            confirmButtonText: 'Confirm',
            showCancelButton: true
        })
        if(choice.isConfirmed){
            await deleteTag(tag.id)
            onDelete(tag)
            closeModal()
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Manage Tag"
        >
            <h2>Tag</h2>
            <Grid
                container
                direction="column"
                justify="center"
            >
                <TextField
                    label="name"
                    className={classes.input}
                    value={updatedTag.name}
                    onChange={(e) => setUpdatedTag({...updatedTag, name: e.currentTarget.value })}
                />
            </Grid>

            <Grid
                container
                direction="row"
            >
                <div onClick={save} className={classes.form_button}>
                    Save
                </div>
                {tag.id !== "" && (
                    <div className={`${classes.form_button} ${classes.delete_button}`} onClick={destroy}>
                        Delete <Icon icon="trash-alt" />
                    </div>
                )}
                <div onClick={closeModal}  className={classes.form_button}>
                    Cancel
                </div>
            </Grid>
        </Modal>
    )
}

export default EditTagModal