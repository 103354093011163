import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { useHistory } from 'react-router-dom';
import { difference } from 'lodash'

import { getTutorList, getTutorListForProgramme } from '../../../../../api/tutor'
import { Tutor } from '../../../../../types'
import { useStyles } from '../../../../../theme/styles'
import { UserContext, isPlatformAdmin } from 'src/components/user/context';

interface TutorsProps {
  selected: string[];
  onChange: (tutors: string[]) => void
  disabled: boolean;
  programmeId: string
}

export const Tutors = ({ programmeId, selected, onChange, disabled }: TutorsProps) => {
  const [tutors, setTutors] = useState<Tutor[]>([])
  const userContext = useContext(UserContext)

  const reload = () => {
    if(isPlatformAdmin(userContext.user)){
      getTutorList().then(setTutors)
    }else{
      getTutorListForProgramme(programmeId).then(setTutors)
    }
    
  }
  useEffect(reload, [])

  const selected_tutors = tutors.filter(t => selected.includes(t.id))
  const selected_ids = selected_tutors.map(tutor => tutor.id) //using this instead of selected becasue it is unique

  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event: any) => {
    const newSelection: string[] = event.target.value;
    const new_tutors = newSelection.filter(s => s !== "-1")
    if( new_tutors.length !== selected_ids.length || difference(new_tutors, selected_ids).length > 0) {
      onChange(new_tutors)
    }
  };

  const redirectToCreateNewTutor = (event: any) => {
    event.stopPropagation()
    history.push({
      pathname: '/settings/tutors'
    });
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <FormControl className={classes.formControl}>
          {isPlatformAdmin(userContext.user) && <Select
            multiple
            value={selected_ids}
            input={<Input />}
            onChange={handleChange}
            displayEmpty
            renderValue={() => <span>Change Tutors</span>}
            disabled={disabled}
          >
            <MenuItem value="-1" onClick={redirectToCreateNewTutor}>
              <LibraryAddIcon />Add Tutor
            </MenuItem>
            {tutors.map((tutor) =>
              <MenuItem key={tutor.id} value={tutor.id}>
                {selected_ids.includes(tutor.id) ? <RemoveIcon /> : <AddIcon />}{tutor.name}
              </MenuItem>
            )}
          </Select>}
        </FormControl>
      </Grid>

      <Grid item xs={9}>
        <Grid container>
          {selected_tutors.map(tutor =>
            <Grid item xs={4} key={tutor.id}>
              {tutor.name}
            </Grid>
          )}
        </Grid>
      </Grid>   
    </Grid>
  );
};
